import React, { useState } from 'react'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'
import MenuDropdown from '../MenuDropdown'

import { MenuItem, Stack, Typography, IconButton, Collapse, Box } from '@mui/material'
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import WarningIcon from '@mui/icons-material/Warning'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import SensorsTable from '../../organisms/SensorsTable'
import { useNavigate } from 'react-router-dom'
import { useUnlinkValveSensorMutation } from '../ValveForm/GraphQl/gql.generated'

const ValveListRow = ({ buildingId, valve, numberOfColumns }) => {
  const [unlinkSensor] = useUnlinkValveSensorMutation()
  const onClickUnlink = (sensor) => {
    unlinkSensor({
      variables: {
        input: {
          id: valve.id,
          sensorId: sensor.id
        }
      },
      refetchQueries: ['FetchValveUnlinkedSensors', 'FetchSensorsTable']
    })
  }

  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <StyledTableRow hover>
        <StyledTableCell style={{ padding: 0, paddingLeft: 5 }}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align='center'>{valve.make}</StyledTableCell>
        <StyledTableCell align='center'>{valve.model}</StyledTableCell>
        <StyledTableCell align='center'>{valve.location}</StyledTableCell>
        <StyledTableCell align='center'>
          <Stack direction='row' spacing={1} sx={{ justifyContent: 'center', alignItems: 'center' }}>
            {valve.isOpen ? (
              <RadioButtonCheckedIcon
                sx={{ color: 'green', height: '0.9em', width: '0.9em' }}
              ></RadioButtonCheckedIcon>
            ) : (
              <WarningIcon sx={{ color: 'red', height: '0.9em', width: '0.9em' }}></WarningIcon>
            )}
            &nbsp;{valve.isOpen ? 'Open' : 'Closed'}
          </Stack>
        </StyledTableCell>
        <StyledTableCell align='center'>{valve.unit.number}</StyledTableCell>
        <StyledTableCell align='center'>{valve.hardwareId}</StyledTableCell>
        <StyledTableCell align='right' style={{ paddingBottom: 0, paddingTop: 0 }}>
          <MenuDropdown>
            <MenuItem
              onClick={() => {
                navigate(`/buildings/${buildingId}/valves/${valve.id}`)
              }}
            >
              Edit
            </MenuItem>
          </MenuDropdown>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow className='semi-tight-table-row'>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={numberOfColumns}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='subtitle1'>Linked Sensors</Typography>
              <SensorsTable
                valveId={valve.id}
                displayColumns={[
                  'deviceName',
                  'location',
                  'unit',
                  'lastSeen',
                  'battery',
                  'hardwareId',
                  'optionsMenu'
                ]}
                menuOptions={{
                  viewSensor: null
                }}
                emptyMessage='No Sensors Linked'
                addPagination={false}
              />
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}

export default ValveListRow
