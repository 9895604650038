import React from 'react'
import { Grid } from '@mui/material'

import useBuildingEmergencyResponseDetailsCard from './useBuildingEmergencyResponseDetailsCard'
import Card from '../../molecules/Card'

const BuildingEmergencyResponseDetailsCard = ({ buildingId }) => {
  const { waterAlertMessage } = useBuildingEmergencyResponseDetailsCard({ buildingId })

  return (
    <Card title={'Building Emergency Response Details'}>
      <Grid>
        <div className='emergency-response-main'>
          <p>
            If you are the unit occupant and require assistance with flooding in your residence or building,
            emergency contact information is below. Note that if you have not acknowledged any alerts you may
            received, your property management or plumbing company will receive a notice automatically.
          </p>

          <div dangerouslySetInnerHTML={{ __html: waterAlertMessage }} />
        </div>
      </Grid>
    </Card>
  )
}

export default BuildingEmergencyResponseDetailsCard
