import React from 'react'
import { Grid } from '@mui/material'

const SensorShowInformationRow = ({ label, value }) => {
  return (
    <Grid container direction='row'>
      <Grid item xs={12}>
        <label className='text-label'>{label}:</label>
        <span className='text-value'>{value}</span>
      </Grid>
    </Grid>
  )
}

export default SensorShowInformationRow
