import React from 'react'
import { Grid } from '@mui/material'

const CardHeader = ({ title }) => {
  return (
    <Grid>
      <h2 className='general-dashboard-header general-header'>{title}</h2>
    </Grid>
  )
}

export default CardHeader
