export default {
  MuiButton: {
    styleOverrides: {
      root: {
        // borderRadius: 16,
        borderRadius: 4
      }
    }
  }
}
