import React from 'react'
import { IconButton, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

const TooltipIcon = ({ title, fontSize = 'small', textFontSize = 12 }) => {
  return (
    <Tooltip title={<Typography fontSize={textFontSize}>{title}</Typography>}>
      <IconButton>
        <InfoIcon fontSize={fontSize} />
      </IconButton>
    </Tooltip>
  )
}

export default TooltipIcon
