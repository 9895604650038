import React from 'react'
import { alpha, Box, CircularProgress, useTheme } from '@mui/material'
import GaugeChart from 'react-gauge-chart'

// src
import { percentage } from 'common/utils/percentage'
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { WidgetNonData } from '../../WidgetNoData'
import { useFetchAveragePressureGaugesWidgetQuery } from './gql.generated'

const minMaxPressureRange = [600, 1100]

export default function AveragePressureGauges({ token }) {
  const { palette } = useTheme()

  const { data, loading, error } = useFetchAveragePressureGaugesWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: { token }
  })

  const colors = [palette.grey.A400, palette.success.light, palette.warning.light, palette.error.main]
  const average = data?.widgetAveragePressureGauges || 0

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />
  if (!average) return <WidgetNonData />

  const percent = percentage(average, minMaxPressureRange)

  return (
    <GaugeChart
      id='average-pressure-gauges'
      textColor={palette.text.secondary}
      needleColor={alpha(palette.text.secondary, 0.5)}
      percent={percent}
      arcsLength={[0.2, 0.6, 0.1, 0.1]}
      colors={colors}
      formatTextValue={() => average + ' hPa'}
    />
  )
}
