import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchPropertyManagersQueryVariables = Types.Exact<{
  buildingId: Types.Scalars['ID'];
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchPropertyManagersQuery = { __typename?: 'Query', fetchPropertyManagers: { __typename?: 'UserBuildingCollection', collection: Array<{ __typename?: 'UserBuilding', id: string, priority: number, user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string, phoneNumber?: string | null, type: string } }> } };

export type UpdateUserBuildingMutationVariables = Types.Exact<{
  input: Types.UpdateUserBuildingInput;
}>;


export type UpdateUserBuildingMutation = { __typename?: 'Mutation', updateUserBuilding: { __typename?: 'UserBuilding', id: string, priority: number, user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email: string, phoneNumber?: string | null, type: string } } };

export type DeleteUserBuildingMutationVariables = Types.Exact<{
  input: Types.DeleteUserBuildingInput;
}>;


export type DeleteUserBuildingMutation = { __typename?: 'Mutation', deleteUserBuilding: { __typename?: 'UserBuilding', id: string } };


export const FetchPropertyManagersDocument = gql`
    query FetchPropertyManagers($buildingId: ID!, $query: String) {
  fetchPropertyManagers(buildingId: $buildingId, query: $query) {
    collection {
      id
      priority
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        type
      }
    }
  }
}
    `;

/**
 * __useFetchPropertyManagersQuery__
 *
 * To run a query within a React component, call `useFetchPropertyManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPropertyManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPropertyManagersQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFetchPropertyManagersQuery(baseOptions: Apollo.QueryHookOptions<FetchPropertyManagersQuery, FetchPropertyManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPropertyManagersQuery, FetchPropertyManagersQueryVariables>(FetchPropertyManagersDocument, options);
      }
export function useFetchPropertyManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPropertyManagersQuery, FetchPropertyManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPropertyManagersQuery, FetchPropertyManagersQueryVariables>(FetchPropertyManagersDocument, options);
        }
export type FetchPropertyManagersQueryHookResult = ReturnType<typeof useFetchPropertyManagersQuery>;
export type FetchPropertyManagersLazyQueryHookResult = ReturnType<typeof useFetchPropertyManagersLazyQuery>;
export type FetchPropertyManagersQueryResult = Apollo.QueryResult<FetchPropertyManagersQuery, FetchPropertyManagersQueryVariables>;
export const UpdateUserBuildingDocument = gql`
    mutation UpdateUserBuilding($input: UpdateUserBuildingInput!) {
  updateUserBuilding(input: $input) {
    id
    priority
    user {
      id
      firstName
      lastName
      email
      phoneNumber
      type
    }
  }
}
    `;
export type UpdateUserBuildingMutationFn = Apollo.MutationFunction<UpdateUserBuildingMutation, UpdateUserBuildingMutationVariables>;

/**
 * __useUpdateUserBuildingMutation__
 *
 * To run a mutation, you first call `useUpdateUserBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserBuildingMutation, { data, loading, error }] = useUpdateUserBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserBuildingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserBuildingMutation, UpdateUserBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserBuildingMutation, UpdateUserBuildingMutationVariables>(UpdateUserBuildingDocument, options);
      }
export type UpdateUserBuildingMutationHookResult = ReturnType<typeof useUpdateUserBuildingMutation>;
export type UpdateUserBuildingMutationResult = Apollo.MutationResult<UpdateUserBuildingMutation>;
export type UpdateUserBuildingMutationOptions = Apollo.BaseMutationOptions<UpdateUserBuildingMutation, UpdateUserBuildingMutationVariables>;
export const DeleteUserBuildingDocument = gql`
    mutation DeleteUserBuilding($input: DeleteUserBuildingInput!) {
  deleteUserBuilding(input: $input) {
    id
  }
}
    `;
export type DeleteUserBuildingMutationFn = Apollo.MutationFunction<DeleteUserBuildingMutation, DeleteUserBuildingMutationVariables>;

/**
 * __useDeleteUserBuildingMutation__
 *
 * To run a mutation, you first call `useDeleteUserBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBuildingMutation, { data, loading, error }] = useDeleteUserBuildingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserBuildingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserBuildingMutation, DeleteUserBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserBuildingMutation, DeleteUserBuildingMutationVariables>(DeleteUserBuildingDocument, options);
      }
export type DeleteUserBuildingMutationHookResult = ReturnType<typeof useDeleteUserBuildingMutation>;
export type DeleteUserBuildingMutationResult = Apollo.MutationResult<DeleteUserBuildingMutation>;
export type DeleteUserBuildingMutationOptions = Apollo.BaseMutationOptions<DeleteUserBuildingMutation, DeleteUserBuildingMutationVariables>;