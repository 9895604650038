import React from 'react'
import TableCell from '@mui/material/TableCell'

import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import CheckmarkCell from '../../atoms/Cells/CheckmarkCell'
import MenuDropdown from '../MenuDropdown'

import EditGateway from '../../organisms/GatewaysTable/EditGateway'
import useCreateEditGateway from '../../organisms/GatewaysTable/EditGateway/useCreateEditGateway'

import DeleteGateway from '../../organisms/GatewaysTable/DeleteGateway'
import useDeleteGateway from '../../organisms/GatewaysTable/DeleteGateway/useDeleteGateway'

import { MenuItem } from '@mui/material'

const GatewayListRow = ({ gateway, isOccupant, isSuperadmin, isPropertyAdmin }) => {
  const { control, handleSubmit, errors, open, projects, loading, onSubmit, handleClickOpen, handleClose } =
    useCreateEditGateway({
      gateway: gateway,
      context: 'edit'
    })

  const {
    open: deleteOpen,
    handleClickOpen: handleDeleteClickOpen,
    handleClose: handleDeleteClose,
    handleDelete,
    error
  } = useDeleteGateway({ gateway })

  return (
    <React.Fragment>
      <StyledTableRow hover>
        <TableCell align='left'>{gateway.make}</TableCell>
        <TableCell align='left'>{gateway.model}</TableCell>
        <TableCell align='left'>{gateway.location}</TableCell>
        {!isOccupant && <CheckmarkCell align='left' booleanValue={gateway.inUse} />}
        {!isOccupant && <TableCell align='left'>{gateway.serialNumber}</TableCell>}
        {isSuperadmin && <TableCell align='left'>{gateway.hardwareId}</TableCell>}
        <TableCell align='right' style={{ paddingBottom: 0, paddingTop: 0 }}>
          {(isPropertyAdmin || isSuperadmin) && (
            <MenuDropdown>
              {(isPropertyAdmin || isSuperadmin) && <MenuItem onClick={handleClickOpen}>Edit</MenuItem>}
              {isSuperadmin && <MenuItem onClick={handleDeleteClickOpen}>Delete</MenuItem>}
            </MenuDropdown>
          )}
        </TableCell>
      </StyledTableRow>
      <EditGateway
        control={control}
        handleSubmit={handleSubmit}
        errors={errors}
        open={open}
        projects={projects}
        loading={loading}
        onSubmit={onSubmit}
        handleClose={handleClose}
      />
      <DeleteGateway
        error={error}
        open={deleteOpen}
        handleClose={handleDeleteClose}
        handleDelete={handleDelete}
      />
    </React.Fragment>
  )
}

export default GatewayListRow
