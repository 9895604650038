import { useMemo } from 'react'
import { get } from 'lodash'

import { useFetchSensorsTableQuery } from './GraphQl/gql.generated'
import { isTypeOccupant, isTypeSuperadmin } from '../../../common/utils/users'

const useSensorsTable = ({ buildingId, unitId, valveId, page, rowsPerPage, userType }) => {
  const isOccupant = isTypeOccupant(userType)

  const { data, loading } = useFetchSensorsTableQuery({
    variables: {
      buildingId,
      unitId,
      ...(valveId && { valveId }), // Conditionally include valveId
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    }
  })

  const sensors = get(data, ['fetchSensors', 'collection'], [])
  const metadata = get(data, ['fetchSensors', 'metadata'], [])

  const availableDataColumns = useMemo(() => {
    const sensorRecentEventColumnKeys = [
      'temperature',
      'humidity',
      'co2',
      'pressure',
      'lightLevel',
      'tvocMgM3',
      'pm10',
      'pm25',
      'battery'
    ]
    const sensorRecentEventColumnsWithValues = sensorRecentEventColumnKeys.filter((key) =>
      sensors.some((sensor) => sensor.sensorRecentEvent && sensor.sensorRecentEvent[key])
    )

    const sensorColumnKeys = ['isMuted']
    const sensorColumnsWithValues = sensorColumnKeys.filter((key) => sensors.some((sensor) => sensor[key]))

    return {
      // sensorRecentEvents columns
      temperature: sensorRecentEventColumnsWithValues.includes('temperature'),
      humidity: sensorRecentEventColumnsWithValues.includes('humidity'),
      co2: sensorRecentEventColumnsWithValues.includes('co2'),
      pressure: sensorRecentEventColumnsWithValues.includes('pressure'),
      lightLevel: sensorRecentEventColumnsWithValues.includes('lightLevel'),
      tvocMgM3: sensorRecentEventColumnsWithValues.includes('tvocMgM3'),
      pm10: sensorRecentEventColumnsWithValues.includes('pm10'),
      pm25: sensorRecentEventColumnsWithValues.includes('pm25'),
      // sensor columns
      isMuted: sensorColumnsWithValues.includes('isMuted')
    }
  }, [sensors])

  return {
    isOccupant,
    loading,
    sensors,
    metadata,
    availableDataColumns
  }
}

export default useSensorsTable
