import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchCo2ActivityLinesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchCo2ActivityLinesWidgetQuery = { __typename?: 'Query', widgetCo2ActivityLines: { __typename?: 'Lines', labels: Array<{ __typename?: 'LineLabel', from: unknown, to: unknown }>, datasets: Array<{ __typename?: 'LineDataset', label: string, data: Array<unknown> }> } };


export const FetchCo2ActivityLinesWidgetDocument = gql`
    query FetchCo2ActivityLinesWidget($token: String!) {
  widgetCo2ActivityLines(token: $token) {
    labels {
      from
      to
    }
    datasets {
      label
      data
    }
  }
}
    `;

/**
 * __useFetchCo2ActivityLinesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchCo2ActivityLinesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCo2ActivityLinesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCo2ActivityLinesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchCo2ActivityLinesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchCo2ActivityLinesWidgetQuery, FetchCo2ActivityLinesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCo2ActivityLinesWidgetQuery, FetchCo2ActivityLinesWidgetQueryVariables>(FetchCo2ActivityLinesWidgetDocument, options);
      }
export function useFetchCo2ActivityLinesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCo2ActivityLinesWidgetQuery, FetchCo2ActivityLinesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCo2ActivityLinesWidgetQuery, FetchCo2ActivityLinesWidgetQueryVariables>(FetchCo2ActivityLinesWidgetDocument, options);
        }
export type FetchCo2ActivityLinesWidgetQueryHookResult = ReturnType<typeof useFetchCo2ActivityLinesWidgetQuery>;
export type FetchCo2ActivityLinesWidgetLazyQueryHookResult = ReturnType<typeof useFetchCo2ActivityLinesWidgetLazyQuery>;
export type FetchCo2ActivityLinesWidgetQueryResult = Apollo.QueryResult<FetchCo2ActivityLinesWidgetQuery, FetchCo2ActivityLinesWidgetQueryVariables>;