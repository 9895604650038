import React, { Fragment } from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, Grid } from '@mui/material'
import { isEmpty } from 'lodash'

import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'

import SearchBar from '../../atoms/SearchBar/SearchBar'
import GatewayListRow from '../../molecules/GatewayListRow/GatewayListRow'

import useGatewaysTable from './useGatewaysTable'

const GatewaysTable = ({ building, userType, numRowsPerPage, showSearchBar = false }) => {
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage })

  const { loading, gateways, metadata, isOccupant, isSuperadmin, isPropertyAdmin, query, setQuery } =
    useGatewaysTable({
      buildingId: building?.id,
      page,
      setPage,
      rowsPerPage,
      userType
    })

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <TableContainer>
      <Grid container alignItems='center' spacing={3}>
        {showSearchBar && <SearchBar placeholder={'Search Gateways'} query={query} setQuery={setQuery} />}
        <Grid item xs={8} />
      </Grid>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Gateway Name</StyledTableCell>
            <StyledTableCell>Model</StyledTableCell>
            <StyledTableCell>Location</StyledTableCell>
            {!isOccupant && <StyledTableCell>In Use?</StyledTableCell>}
            {!isOccupant && <StyledTableCell>Serial Number</StyledTableCell>}
            {isSuperadmin && <StyledTableCell>Hardware ID</StyledTableCell>}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {gateways.map((gateway) => (
            <Fragment key={gateway.id}>
              <GatewayListRow
                gateway={gateway}
                isOccupant={isOccupant}
                isSuperadmin={isSuperadmin}
                isPropertyAdmin={isPropertyAdmin}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={4}
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={metadata?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={isEmpty(metadata) ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default GatewaysTable
