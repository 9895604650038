import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAveragePressureGaugesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchAveragePressureGaugesWidgetQuery = { __typename?: 'Query', widgetAveragePressureGauges: number };


export const FetchAveragePressureGaugesWidgetDocument = gql`
    query FetchAveragePressureGaugesWidget($token: String!) {
  widgetAveragePressureGauges(token: $token)
}
    `;

/**
 * __useFetchAveragePressureGaugesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchAveragePressureGaugesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAveragePressureGaugesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAveragePressureGaugesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchAveragePressureGaugesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchAveragePressureGaugesWidgetQuery, FetchAveragePressureGaugesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAveragePressureGaugesWidgetQuery, FetchAveragePressureGaugesWidgetQueryVariables>(FetchAveragePressureGaugesWidgetDocument, options);
      }
export function useFetchAveragePressureGaugesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAveragePressureGaugesWidgetQuery, FetchAveragePressureGaugesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAveragePressureGaugesWidgetQuery, FetchAveragePressureGaugesWidgetQueryVariables>(FetchAveragePressureGaugesWidgetDocument, options);
        }
export type FetchAveragePressureGaugesWidgetQueryHookResult = ReturnType<typeof useFetchAveragePressureGaugesWidgetQuery>;
export type FetchAveragePressureGaugesWidgetLazyQueryHookResult = ReturnType<typeof useFetchAveragePressureGaugesWidgetLazyQuery>;
export type FetchAveragePressureGaugesWidgetQueryResult = Apollo.QueryResult<FetchAveragePressureGaugesWidgetQuery, FetchAveragePressureGaugesWidgetQueryVariables>;