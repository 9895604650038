import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useCreateSensorMutation } from './GraphQl/gql.generated'
import { get } from 'lodash'
import { isMobile } from '../../../common/utils/display'
import { format } from 'date-fns'

const useSensorCreate = () => {
  const [tempRange, setTempRange] = useState([5, 45])
  const [humidityRange, setHumidityRange] = useState([0, 100])
  const [waterSensitivity, setWaterSensitivity] = useState(0)
  const [createSensor, { loading: savingSensor }] = useCreateSensorMutation()
  const displayIsMobile = isMobile()

  const schema = yup
    .object({
      hardwareId: yup.string().required('Hardware ID is a required field'),
      deviceName: yup.string().required('Make/Model is a required field'),
      location: yup.string().required('Location is a required field'),
      unitId: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Zone is required')
    })
    .required()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hardwareId: '',
      deviceName: '',
      location: '',
      unitId: '',
      inUse: true,
      enableAlerts: false,
      monitorWater: false,
      monitorTemperature: false,
      monitorHumidity: false,
      monitorDoor: false,
      monitorPower: false,
      waterSensitivity: null,
      lowTemperatureThreshold: null,
      highTemperatureThreshold: null,
      lowHumidityThreshold: null,
      highHumidityThreshold: null,
      probeType: '',
      image: null
    }
  })

  const handleSensorCreateSubmit = (
    input,
    setSubmitError,
    buildingId,
    waterEnabled,
    tempEnabled,
    humidityEnabled,
    doorEnabled,
    hoursValues
  ) => {
    setSubmitError('')
    let submitInput = { ...input, ...{ buildingId: buildingId } }

    if (waterEnabled) {
      submitInput.waterSensitivity = waterSensitivity
    }

    if (tempEnabled) {
      submitInput.lowTemperatureThreshold = tempRange[0]
      submitInput.highTemperatureThreshold = tempRange[1]
    }

    if (humidityEnabled) {
      submitInput.lowHumidityThreshold = humidityRange[0]
      submitInput.highHumidityThreshold = humidityRange[1]
    }

    if (doorEnabled && hoursValues.doorAlertHoursEnabled) {
      submitInput = { ...submitInput, ...hoursValues }
      submitInput.doorAlertHoursStart = format(
        hoursValues.doorAlertHoursStart,
        "yyyy-MM-dd'T'HH:mm:00.000'Z'"
      )
      submitInput.doorAlertHoursEnd = format(hoursValues.doorAlertHoursEnd, "yyyy-MM-dd'T'HH:mm:00.000'Z'")
    }

    createSensor({
      variables: {
        input: { input: submitInput }
      },
      onCompleted: (data) => {
        const createdSensor = get(data, ['createSensor'], {})
        window.location.href = `/sensors/${
          createdSensor.id
        }?collapse_details=${displayIsMobile}&building_id=${buildingId.toString()}`
      },
      onError: (error) => setSubmitError(error.message)
    })
  }

  return {
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    handleSensorCreateSubmit,
    tempRange,
    humidityRange,
    waterSensitivity,
    setTempRange,
    setHumidityRange,
    setWaterSensitivity,
    savingSensor
  }
}
export default useSensorCreate
