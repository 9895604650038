import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchPressureActivityLinesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchPressureActivityLinesWidgetQuery = { __typename?: 'Query', widgetPressureActivityLines: { __typename?: 'Lines', labels: Array<{ __typename?: 'LineLabel', from: unknown, to: unknown }>, datasets: Array<{ __typename?: 'LineDataset', label: string, data: Array<unknown> }> } };


export const FetchPressureActivityLinesWidgetDocument = gql`
    query FetchPressureActivityLinesWidget($token: String!) {
  widgetPressureActivityLines(token: $token) {
    labels {
      from
      to
    }
    datasets {
      label
      data
    }
  }
}
    `;

/**
 * __useFetchPressureActivityLinesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchPressureActivityLinesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPressureActivityLinesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPressureActivityLinesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchPressureActivityLinesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchPressureActivityLinesWidgetQuery, FetchPressureActivityLinesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPressureActivityLinesWidgetQuery, FetchPressureActivityLinesWidgetQueryVariables>(FetchPressureActivityLinesWidgetDocument, options);
      }
export function useFetchPressureActivityLinesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPressureActivityLinesWidgetQuery, FetchPressureActivityLinesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPressureActivityLinesWidgetQuery, FetchPressureActivityLinesWidgetQueryVariables>(FetchPressureActivityLinesWidgetDocument, options);
        }
export type FetchPressureActivityLinesWidgetQueryHookResult = ReturnType<typeof useFetchPressureActivityLinesWidgetQuery>;
export type FetchPressureActivityLinesWidgetLazyQueryHookResult = ReturnType<typeof useFetchPressureActivityLinesWidgetLazyQuery>;
export type FetchPressureActivityLinesWidgetQueryResult = Apollo.QueryResult<FetchPressureActivityLinesWidgetQuery, FetchPressureActivityLinesWidgetQueryVariables>;