import { useMemo } from 'react'
import { get } from 'lodash'

import { useFetchOccupantUnitInformationQuery } from './GraphQl/gql.generated'

import {
  formatBuildingLocationValue,
  formatUnitActiveAlertsValue,
  formatUnitLastAlertValue,
  formatUnitFactorsBeingMonitoredValue,
  formatBuildingAverageTemperatureValue,
  formatBuildingAverageHumidityValue
} from './OccupantUnitInformationCardHelpers'

const useOccupantUnitInformationCard = ({ occupantId, unitId }) => {
  // Get temperature/humidity average for the last 24 hours
  const buildingTemperatureAverageTimestamp = useMemo(() => new Date(new Date() - 24 * 60 * 60 * 1000), [])
  const buildingHumidityAverageTimestamp = useMemo(() => new Date(new Date() - 24 * 60 * 60 * 1000), [])

  const { data: occupantUnitInformation } = useFetchOccupantUnitInformationQuery({
    variables: {
      userId: occupantId,
      filters: { buildingTemperatureAverageTimestamp, buildingHumidityAverageTimestamp, unitId }
    }
  })

  // Occupants should only have 1 building so we assume it is the first in the collection
  const occupantBuildingData = useMemo(
    () => get(occupantUnitInformation, ['fetchUserBuildings', 0], {}),
    [occupantUnitInformation]
  )

  const occupantUnitInformationMapping = useMemo(() => {
    const buildingData = get(occupantBuildingData, ['building'], {})
    const unitData = get(occupantBuildingData, ['unit'], {})

    return [
      { label: 'Your Unit', value: unitData.number },
      {
        label: 'Location',
        value: formatBuildingLocationValue({
          address: buildingData.address,
          city: buildingData.city,
          stateProvince: buildingData.stateProvince
        })
      },
      {
        label: 'Average Temperature (last 24 hrs)',
        value: formatBuildingAverageTemperatureValue({ averageTemperature: buildingData.temperatureAverage })
      },
      {
        label: 'Average Humidity (last 24 hrs)',
        value: formatBuildingAverageHumidityValue({ averageHumidity: buildingData.humidityAverage })
      },
      { label: 'Active Alerts', value: formatUnitActiveAlertsValue({ activeAlerts: unitData.activeAlerts }) },
      { label: 'Last Alert', value: formatUnitLastAlertValue({ lastAlert: unitData.lastAlert }) },
      { label: 'Sensors In Unit', value: unitData.sensorsCount },
      {
        label: 'Factors Being Monitored',
        value: formatUnitFactorsBeingMonitoredValue({ sensors: unitData.sensors })
      }
    ]
  }, [occupantBuildingData])

  return {
    buildingName: get(occupantBuildingData, ['building', 'name'], ''),
    occupantUnitInformationMapping
  }
}

export default useOccupantUnitInformationCard
