import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import QrCodeIcon from '@mui/icons-material/QrCode'
import { IconButton } from '@mui/material'
import QRCode from 'react-qr-code'

export default function QrCodeDialog({ link }: { link: string }) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <QrCodeIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <QRCode value={link} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
