import { Box, Chip, Grid } from '@mui/material'
import { isEmpty } from 'lodash'
import { getMetricsList } from '../../../common/utils/reports'
import { dateFormat } from '../../../common/utils/dates'
import { buildingDisplay, sensorDisplay, unitDisplay } from '../../../common/utils/display'
import React from 'react'

const CriteriaDisplay = ({ previewMode, savedReportDetails, filterCriteria, criteriaDisplay }) => {
  return (
    <>
      {previewMode ? (
        <>
          <Grid item xs='auto'>
            <b className={'text-label'}>Buildings:</b>
            {isEmpty(criteriaDisplay?.buildings || []) ? (
              <Chip label='All Buildings' color='primary' />
            ) : (
              criteriaDisplay?.buildings.map((building) => (
                <Chip
                  key={building.id}
                  label={buildingDisplay(building)}
                  color='primary'
                  variant='outlined'
                  sx={{ marginLeft: 0.5 }}
                />
              ))
            )}
          </Grid>
          <Grid item xs='auto'>
            <b className={'text-label'}>Zones:</b>
            {isEmpty(criteriaDisplay?.units || []) ? (
              <Chip label='All Zones' color='primary' />
            ) : (
              criteriaDisplay?.units.map((unit) => (
                <Chip
                  key={unit.id}
                  label={unitDisplay(unit)}
                  color='primary'
                  variant='outlined'
                  sx={{ marginLeft: 0.5 }}
                />
              ))
            )}
          </Grid>
          <Grid item xs='auto'>
            <Box sx={{ overflow: 'auto' }}>
              <b className={'text-label'}>Sensors:</b>
              {isEmpty(criteriaDisplay?.sensors || []) ? (
                <Chip label='All Sensors' color='primary' />
              ) : (
                criteriaDisplay?.sensors.map((sensor) => (
                  <Chip
                    key={sensor.id}
                    label={sensorDisplay(sensor)}
                    color='primary'
                    variant='outlined'
                    sx={{ marginLeft: 0.5 }}
                  />
                ))
              )}
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs='auto'>
            <b className={'text-label'}>Buildings:</b>
            {isEmpty(savedReportDetails?.selectedBuildings || []) ? (
              <Chip label='All Buildings' color='primary' />
            ) : (
              savedReportDetails?.selectedBuildings.map((buildingName) => (
                <Chip
                  key={buildingName}
                  label={buildingName}
                  color='primary'
                  variant='outlined'
                  sx={{ marginLeft: 0.5 }}
                />
              ))
            )}
          </Grid>
          <Grid item xs='auto'>
            <b className={'text-label'}>Zones:</b>
            {isEmpty(savedReportDetails?.selectedUnits || []) ? (
              <Chip label='All Zones' color='primary' />
            ) : (
              savedReportDetails?.selectedUnits.map((unitName) => (
                <Chip
                  key={unitName}
                  label={unitName}
                  color='primary'
                  variant='outlined'
                  sx={{ marginLeft: 0.5 }}
                />
              ))
            )}
          </Grid>
          <Grid item xs='auto'>
            <Box sx={{ overflow: 'auto' }}>
              <b className={'text-label'}>Sensors:</b>
              {isEmpty(savedReportDetails?.selectedSensors || []) ? (
                <Chip label='All Sensors' color='primary' />
              ) : (
                savedReportDetails?.selectedSensors.map((sensorName) => (
                  <Chip
                    key={sensorName}
                    label={sensorName}
                    color='primary'
                    variant='outlined'
                    sx={{ marginLeft: 0.5 }}
                  />
                ))
              )}
            </Box>
          </Grid>
        </>
      )}

      <Grid item xs='auto'>
        <b className={'text-label'}>Start Date:</b>
        <Chip label={dateFormat(filterCriteria?.startDate)} color='primary' variant='outlined' />
      </Grid>
      <Grid item xs='auto'>
        <b className={'text-label'}>End Date:</b>
        <Chip label={dateFormat(filterCriteria?.endDate)} color='primary' variant='outlined' />
      </Grid>
      <Grid item xs='auto'>
        <b className={'text-label'}>Selected Insights Metrics:</b>
        {filterCriteria !== undefined &&
          getMetricsList(filterCriteria).map((metric) => (
            <Chip key={metric} label={metric} color='primary' variant='outlined' sx={{ mr: 1 }} />
          ))}
      </Grid>
    </>
  )
}

export default CriteriaDisplay
