import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchPowerStatusesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
  sorting?: Types.InputMaybe<Types.SortingType>;
}>;


export type FetchPowerStatusesWidgetQuery = { __typename?: 'Query', widgetPowerStatuses: { __typename?: 'BuildingCollection', collection: Array<{ __typename?: 'Building', id: string, powerSource?: string | null, name?: string | null }>, metadata: { __typename?: 'CollectionMetadata', totalCount: number } } };


export const FetchPowerStatusesWidgetDocument = gql`
    query FetchPowerStatusesWidget($token: String!, $pagination: PaginationType, $sorting: SortingType) {
  widgetPowerStatuses(token: $token, pagination: $pagination, sorting: $sorting) {
    collection {
      id
      powerSource
      name
    }
    metadata {
      totalCount
    }
  }
}
    `;

/**
 * __useFetchPowerStatusesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchPowerStatusesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPowerStatusesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPowerStatusesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchPowerStatusesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchPowerStatusesWidgetQuery, FetchPowerStatusesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPowerStatusesWidgetQuery, FetchPowerStatusesWidgetQueryVariables>(FetchPowerStatusesWidgetDocument, options);
      }
export function useFetchPowerStatusesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPowerStatusesWidgetQuery, FetchPowerStatusesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPowerStatusesWidgetQuery, FetchPowerStatusesWidgetQueryVariables>(FetchPowerStatusesWidgetDocument, options);
        }
export type FetchPowerStatusesWidgetQueryHookResult = ReturnType<typeof useFetchPowerStatusesWidgetQuery>;
export type FetchPowerStatusesWidgetLazyQueryHookResult = ReturnType<typeof useFetchPowerStatusesWidgetLazyQuery>;
export type FetchPowerStatusesWidgetQueryResult = Apollo.QueryResult<FetchPowerStatusesWidgetQuery, FetchPowerStatusesWidgetQueryVariables>;