import { useGetReportQuery } from './GraphQl/gql.generated'

import { useMemo } from 'react'

const useReportsPage = ({ setFilterCriteria, location, setLoading }) => {
  const token = location.pathname.split('/').pop()
  const {
    data,
    loading: reportLoading,
    error
  } = useGetReportQuery({
    variables: { token },
    skip: !token // Add a skip condition if there's no token
  })

  useMemo(() => {
    if (data?.getReport) {
      setFilterCriteria(data?.getReport.criteria)
      setLoading(false)
    }
  }, [data])

  return data?.getReport
}

export default useReportsPage
