import React from 'react'
import { Paper, Grid, LinearProgress, Box } from '@mui/material'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { map, split, last, indexOf, sortBy } from 'lodash'
// src
import { useFetchDashboardWidgetsQuery } from './gql.generated'
import WidgetCard from '../WidgetCard'
import { useUpdatePositionDashboardWidgetMutation } from '../WidgetCard/gql.generated'

export default function WidgetsContainer({ isPublic = false }) {
  const { token } = useParams()

  const { data: widgetsData, loading: widgetsLoading } = useFetchDashboardWidgetsQuery({
    variables: { token, pagination: { per: 50 } }
  })

  const widgets = widgetsData?.fetchDashboardWidgets?.collection || []

  const widgetIds = map(widgets, 'id')

  const [update] = useUpdatePositionDashboardWidgetMutation()

  const onDragEnd = ({ destination, source }) => {
    if (!source || !destination) return

    const sourceId = last(split(source.droppableId, '-'))
    const destinationId = last(split(destination.droppableId, '-'))

    const dashboardWidgetsIds = widgetIds

    const sourceIndex = indexOf(dashboardWidgetsIds, sourceId)
    const destinationIndex = indexOf(dashboardWidgetsIds, destinationId)

    dashboardWidgetsIds[sourceIndex] = destinationId
    dashboardWidgetsIds[destinationIndex] = sourceId

    update({
      variables: {
        input: {
          dashboardWidgetsIds
        }
      },
      refetchQueries: ['FetchDashboardWidgets']
    })
  }

  if (widgetsLoading) return <LinearProgress />

  return (
    <Box component={Paper} sx={{ p: 2, mt: 2, height: '100%', width: '100%' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container gap={2}>
          {sortBy(widgets, 'position').map((widget, index) => {
            return (
              <Droppable droppableId={`drop-widget-${widget.id}`} type='WIDGET' key={widget.id}>
                {(provided, snapshot) => (
                  <Grid
                    item
                    sx={{
                      width: 600,
                      border: snapshot.isDraggingOver ? '1px dashed' : null,
                      borderColor: ({ palette }) => palette.primary.main,
                      borderRadius: '4px'
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <WidgetCard isPublic={isPublic} token={token} widget={widget} index={index} />
                    <Box display='none'>{provided.placeholder}</Box>
                  </Grid>
                )}
              </Droppable>
            )
          })}
        </Grid>
      </DragDropContext>
    </Box>
  )
}
