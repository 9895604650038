import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchWaterAlertsWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
  sorting?: Types.InputMaybe<Types.SortingType>;
}>;


export type FetchWaterAlertsWidgetQuery = { __typename?: 'Query', widgetWaterAlerts: { __typename?: 'SensorEventCollection', collection: Array<{ __typename?: 'SensorEvent', id: string, eventTime: unknown, sensor: { __typename?: 'Sensor', id: string, deviceName?: string | null, location?: string | null, unit?: { __typename?: 'Unit', id: string, number?: string | null, floor: number } | null, building?: { __typename?: 'Building', name?: string | null } | null } }>, metadata: { __typename?: 'CollectionMetadata', totalCount: number } } };


export const FetchWaterAlertsWidgetDocument = gql`
    query FetchWaterAlertsWidget($token: String!, $pagination: PaginationType, $sorting: SortingType) {
  widgetWaterAlerts(token: $token, pagination: $pagination, sorting: $sorting) {
    collection {
      id
      sensor {
        id
        deviceName
        location
        unit {
          id
          number
          floor
        }
        building {
          name
        }
      }
      eventTime
    }
    metadata {
      totalCount
    }
  }
}
    `;

/**
 * __useFetchWaterAlertsWidgetQuery__
 *
 * To run a query within a React component, call `useFetchWaterAlertsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWaterAlertsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWaterAlertsWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchWaterAlertsWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchWaterAlertsWidgetQuery, FetchWaterAlertsWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchWaterAlertsWidgetQuery, FetchWaterAlertsWidgetQueryVariables>(FetchWaterAlertsWidgetDocument, options);
      }
export function useFetchWaterAlertsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchWaterAlertsWidgetQuery, FetchWaterAlertsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchWaterAlertsWidgetQuery, FetchWaterAlertsWidgetQueryVariables>(FetchWaterAlertsWidgetDocument, options);
        }
export type FetchWaterAlertsWidgetQueryHookResult = ReturnType<typeof useFetchWaterAlertsWidgetQuery>;
export type FetchWaterAlertsWidgetLazyQueryHookResult = ReturnType<typeof useFetchWaterAlertsWidgetLazyQuery>;
export type FetchWaterAlertsWidgetQueryResult = Apollo.QueryResult<FetchWaterAlertsWidgetQuery, FetchWaterAlertsWidgetQueryVariables>;