import { FormControl, Grid, Slider, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import FormCheckbox from '../../atoms/CheckmarkCell'
import TooltipIcon from '../../atoms/TooltopIcon'
import React from 'react'

const ConfigSlider = ({
  control,
  sliderEnabled,
  sliderValue,
  setSliderValue,
  setValue,
  min,
  max,
  marks,
  title,
  fieldId,
  tooltip = null
}) => {
  return (
    <Grid container spacing={1} sx={{ pt: sliderEnabled ? 2 : 0 }}>
      <Grid item xs={12} sm={5} md={5} lg={5}>
        <FormControl fullWidth>
          <Controller
            name={fieldId}
            control={control}
            render={({ field }) => (
              <FormCheckbox
                label={
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <Typography>{title}</Typography>
                    </Grid>
                    {tooltip && (
                      <Grid item>
                        <TooltipIcon title={tooltip} textFontSize={14} />
                      </Grid>
                    )}
                  </Grid>
                }
                checked={field.value}
                onChange={(e) => setValue(fieldId, e.target.checked)}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={9} sm={7} md={7} lg={7} sx={{ m: 'auto' }}>
        {sliderEnabled && (
          <Slider
            sx={{ mt: 0.8 }}
            value={sliderValue}
            onChange={(event) => setSliderValue(event.target.value)}
            valueLabelDisplay='auto'
            min={min}
            max={max}
            disableSwap
            marks={marks}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default ConfigSlider
