import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Autocomplete,
  TextField,
  CircularProgress,
  DialogActions,
  Button,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useFetchValveUnlinkedSensorsLazyQuery, useLinkValveSensorsMutation } from './GraphQl/gql.generated'
import { sensorDisplay } from '../../../common/utils/display'

const LinkSensorDialog = ({ open, setOpen, valveId, buildingId, columnDisplay }) => {
  const [sensorIds, setSensorIds] = useState([])
  const [error, setError] = useState(false)

  const handleSensorsSelection = (event, sensors) => {
    setSensorIds(sensors.map((sensor) => sensor.id))
    setError(false)
  }

  const [getSensors, { data: sensorsData, loading: sensorsLoading }] = useFetchValveUnlinkedSensorsLazyQuery({
    variables: {
      valveId: valveId,
      buildingId: buildingId,
      pagination: {
        per: 500
      }
    }
  })

  const [linkSensorsToValve] = useLinkValveSensorsMutation()
  const onCLickLink = () => {
    if (sensorIds.length) {
      setError(false)
      linkSensorsToValve({
        variables: {
          input: {
            id: valveId,
            sensorIds: sensorIds
          }
        },
        onCompleted: () => {
          setOpen(false)
        },
        refetchQueries: ['FetchValveUnlinkedSensors', 'FetchSensorsTable']
      })
      handleClose()
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    // Fetch sensors initially
    if (open) getSensors()
  }, [open])

  const sensors = sensorsData?.fetchValveUnlinkedSensors?.collection || []

  const selectedSensors = sensors.filter((sensor) => sensorIds.includes(sensor.id))

  const handleClose = () => {
    setSensorIds([])
    setOpen(false)
    setError(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      aria-labelledby='form-dialog-title'
      aria-describedby='form-dialog-description'
    >
      <Box>
        {/** Inspired by BuildingFilters from DashboardFiltersPanel */}
        <DialogTitle>Link Sensors</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Autocomplete
            style={{ paddingTop: 5 }}
            disabled={sensorsLoading}
            multiple
            limitTags={columnDisplay ? 5 : 1}
            size='small'
            value={selectedSensors}
            onChange={handleSensorsSelection}
            options={sensors}
            onFocus={() => getSensors()}
            getOptionLabel={(option) => sensorDisplay(option)}
            loading={sensorsLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                error={error}
                helperText={error ? 'Please select a sensor to link.' : ''}
                label={sensorIds.length ? 'Sensors' : ''}
                placeholder={sensorIds.length ? '' : 'No Sensors Selected'}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {sensorsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={onCLickLink} variant='contained'>
            Link
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default LinkSensorDialog
