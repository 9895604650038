import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem
} from '@mui/material'

const EditGateway = ({ control, handleSubmit, errors, open, projects, loading, onSubmit, handleClose }) => {
  if (loading) return <React.Fragment></React.Fragment>
  return (
    <>
      <Dialog
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        aria-describedby='form-dialog-description'
      >
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <DialogTitle>Edit Gateway</DialogTitle>
          <DialogContent>
            <Controller
              name='make'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Gateway Name'
                  type='text'
                  fullWidth
                  error={!!errors.make}
                  helperText={errors.make?.message}
                />
              )}
            />
            <Controller
              name='model'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Model'
                  type='text'
                  fullWidth
                  error={!!errors.model}
                  helperText={errors.model?.message}
                />
              )}
            />
            <Controller
              name='location'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Location'
                  type='text'
                  fullWidth
                  error={!!errors.location}
                  helperText={errors.location?.message}
                />
              )}
            />
            <Controller
              name='serialNumber'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Serial Number'
                  type='text'
                  fullWidth
                  error={!!errors.serialNumber}
                  helperText={errors.serialNumber?.message}
                />
              )}
            />
            <Controller
              name='hardwareId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Hardware ID'
                  type='text'
                  fullWidth
                  error={!!errors.hardwareId}
                  helperText={errors.hardwareId?.message}
                />
              )}
            />
            <Controller
              name='projectId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Project'
                  fullWidth
                  select
                  error={!!errors.projectId}
                  helperText={errors.projectId?.message}
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name='inUse'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} onChange={field.onChange} />}
                  label='In Use'
                  error={!!errors.inUse}
                  helperText={errors.inUse?.message}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='contained' type='submit'>
              Save
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default EditGateway
