import React from 'react'
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import useEventTypeFilter from './useEventTypeFilter'

import { alertTypes } from '../../../common/utils/alerts'

const EventTypeFilter = ({
  loading,
  eventFilter,
  setEventFilter,
  gridSx = { minWidth: '270px', mt: '5px' }
}) => {
  const { handleEventTypeChange } = useEventTypeFilter({ setEventFilter })

  return (
    <Grid item xs={2} sx={gridSx}>
      <FormControl fullWidth>
        <InputLabel id='since-label'>Event Type</InputLabel>
        <Select
          disabled={loading}
          size='small'
          labelId='event-type-label'
          value={eventFilter || 'all'}
          label='Event Type'
          onChange={handleEventTypeChange}
        >
          {alertTypes.map(({ value, title }, key) => (
            <MenuItem value={value} key={key}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default EventTypeFilter
