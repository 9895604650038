import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchValvesQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type FetchValvesQuery = { __typename?: 'Query', fetchValves: { __typename?: 'ValveCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Valve', id: string, make: string, model: string, location: string, isOpen: boolean, hardwareId: string, unit: { __typename?: 'Unit', id: string, number?: string | null }, activeProject?: { __typename?: 'Project', id: string } | null }> } };

export type GetValveQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetValveQuery = { __typename?: 'Query', getValve: { __typename?: 'Valve', id: string, make: string, model: string, location: string, isOpen: boolean, hardwareId: string, buildingId: string, unit: { __typename?: 'Unit', id: string, number?: string | null }, activeProject?: { __typename?: 'Project', id: string } | null } };

export type CreateValveMutationVariables = Types.Exact<{
  input: Types.CreateValveInput;
}>;


export type CreateValveMutation = { __typename?: 'Mutation', createValve?: { __typename?: 'CreateValvePayload', error?: string | null, valve?: { __typename?: 'Valve', id: string, buildingId: string, hardwareId: string, location: string, make: string, model: string, isOpen: boolean, unit: { __typename?: 'Unit', id: string }, activeProject?: { __typename?: 'Project', id: string } | null } | null } | null };

export type UpdateValveMutationVariables = Types.Exact<{
  input: Types.UpdateValveInput;
}>;


export type UpdateValveMutation = { __typename?: 'Mutation', updateValve: { __typename?: 'Valve', id: string, buildingId: string, hardwareId: string, location: string, make: string, model: string, isOpen: boolean, unit: { __typename?: 'Unit', id: string }, activeProject?: { __typename?: 'Project', id: string } | null } };


export const FetchValvesDocument = gql`
    query FetchValves($query: String, $pagination: PaginationType, $buildingId: ID) {
  fetchValves(query: $query, pagination: $pagination, buildingId: $buildingId) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      id
      make
      model
      location
      isOpen
      hardwareId
      unit {
        id
        number
      }
      activeProject {
        id
      }
    }
  }
}
    `;

/**
 * __useFetchValvesQuery__
 *
 * To run a query within a React component, call `useFetchValvesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchValvesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchValvesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useFetchValvesQuery(baseOptions?: Apollo.QueryHookOptions<FetchValvesQuery, FetchValvesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchValvesQuery, FetchValvesQueryVariables>(FetchValvesDocument, options);
      }
export function useFetchValvesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchValvesQuery, FetchValvesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchValvesQuery, FetchValvesQueryVariables>(FetchValvesDocument, options);
        }
export type FetchValvesQueryHookResult = ReturnType<typeof useFetchValvesQuery>;
export type FetchValvesLazyQueryHookResult = ReturnType<typeof useFetchValvesLazyQuery>;
export type FetchValvesQueryResult = Apollo.QueryResult<FetchValvesQuery, FetchValvesQueryVariables>;
export const GetValveDocument = gql`
    query GetValve($id: ID!) {
  getValve(id: $id) {
    id
    make
    model
    location
    isOpen
    hardwareId
    buildingId
    unit {
      id
      number
    }
    activeProject {
      id
    }
  }
}
    `;

/**
 * __useGetValveQuery__
 *
 * To run a query within a React component, call `useGetValveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetValveQuery(baseOptions: Apollo.QueryHookOptions<GetValveQuery, GetValveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetValveQuery, GetValveQueryVariables>(GetValveDocument, options);
      }
export function useGetValveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetValveQuery, GetValveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetValveQuery, GetValveQueryVariables>(GetValveDocument, options);
        }
export type GetValveQueryHookResult = ReturnType<typeof useGetValveQuery>;
export type GetValveLazyQueryHookResult = ReturnType<typeof useGetValveLazyQuery>;
export type GetValveQueryResult = Apollo.QueryResult<GetValveQuery, GetValveQueryVariables>;
export const CreateValveDocument = gql`
    mutation CreateValve($input: CreateValveInput!) {
  createValve(input: $input) {
    valve {
      id
      buildingId
      hardwareId
      location
      make
      model
      isOpen
      unit {
        id
      }
      activeProject {
        id
      }
    }
    error
  }
}
    `;
export type CreateValveMutationFn = Apollo.MutationFunction<CreateValveMutation, CreateValveMutationVariables>;

/**
 * __useCreateValveMutation__
 *
 * To run a mutation, you first call `useCreateValveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateValveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createValveMutation, { data, loading, error }] = useCreateValveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateValveMutation(baseOptions?: Apollo.MutationHookOptions<CreateValveMutation, CreateValveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateValveMutation, CreateValveMutationVariables>(CreateValveDocument, options);
      }
export type CreateValveMutationHookResult = ReturnType<typeof useCreateValveMutation>;
export type CreateValveMutationResult = Apollo.MutationResult<CreateValveMutation>;
export type CreateValveMutationOptions = Apollo.BaseMutationOptions<CreateValveMutation, CreateValveMutationVariables>;
export const UpdateValveDocument = gql`
    mutation UpdateValve($input: UpdateValveInput!) {
  updateValve(input: $input) {
    id
    buildingId
    hardwareId
    location
    make
    model
    isOpen
    unit {
      id
    }
    activeProject {
      id
    }
  }
}
    `;
export type UpdateValveMutationFn = Apollo.MutationFunction<UpdateValveMutation, UpdateValveMutationVariables>;

/**
 * __useUpdateValveMutation__
 *
 * To run a mutation, you first call `useUpdateValveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateValveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateValveMutation, { data, loading, error }] = useUpdateValveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateValveMutation(baseOptions?: Apollo.MutationHookOptions<UpdateValveMutation, UpdateValveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateValveMutation, UpdateValveMutationVariables>(UpdateValveDocument, options);
      }
export type UpdateValveMutationHookResult = ReturnType<typeof useUpdateValveMutation>;
export type UpdateValveMutationResult = Apollo.MutationResult<UpdateValveMutation>;
export type UpdateValveMutationOptions = Apollo.BaseMutationOptions<UpdateValveMutation, UpdateValveMutationVariables>;