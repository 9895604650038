export const DEFAULT_INITIAL_PAGE = 0

export const NUMBER_OF_ROWS_PER_PAGE_FIVE = 5
export const NUMBER_OF_ROWS_PER_PAGE_TEN = 10
export const NUMBER_OF_ROWS_PER_PAGE_TWENTY_FIVE = 25
export const DEFAULT_NUMBER_OF_ROWS_PER_PAGE = NUMBER_OF_ROWS_PER_PAGE_TEN
export const DEFAULT_NUMBER_OF_ROWS_PER_PAGE_OPTIONS = [
  NUMBER_OF_ROWS_PER_PAGE_FIVE,
  NUMBER_OF_ROWS_PER_PAGE_TEN,
  NUMBER_OF_ROWS_PER_PAGE_TWENTY_FIVE
]
