import React from 'react'
import { Card, CardHeader, CardContent, Avatar, IconButton } from '@mui/material'
import { Close as CloseIcon, DragIndicator as DragIndicatorIcon } from '@mui/icons-material'
import { ErrorBoundary } from 'react-error-boundary'
import { Draggable } from 'react-beautiful-dnd'

// src
import { WidgetContentBuilder } from './widget-content-builder'
import { useDeleteDashboardWidgetMutation, useUpdatePositionDashboardWidgetMutation } from './gql.generated'
import WidgetErrorFallback from './WidgetErrorFallback'

interface Props {
  token: string
  widget: any
  updatePosition?: Function
  index?: number
  isPublic: boolean
}

export default function WidgetCard({ token, widget, index, isPublic = false }: Props) {
  const builder = WidgetContentBuilder[widget.type]

  const [deleteWidget] = useDeleteDashboardWidgetMutation()
  const handleDeleteWidget = () => {
    deleteWidget({
      variables: { input: { id: widget.id } },
      refetchQueries: ['FetchDashboardWidgets']
    })
  }

  return (
    <Card>
      <CardHeader
        sx={{
          height: 50,
          textAlign: 'center'
        }}
        avatar={
          <Avatar aria-label='widget-icon' sx={{ background: ({ palette }) => palette.text.secondary }}>
            <builder.icon />
          </Avatar>
        }
        action={
          widget.id && !isPublic ? (
            <>
              <Draggable draggableId={`widget-${widget.id}`} index={index}>
                {(provided) => (
                  <IconButton
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicatorIcon color='primary' />
                  </IconButton>
                )}
              </Draggable>

              <IconButton aria-label='widget-delete' onClick={handleDeleteWidget}>
                <CloseIcon />
              </IconButton>
            </>
          ) : null
        }
        title={builder.title}
        titleTypographyProps={{ fontSize: '18px', color: ({ palette }) => palette.text.secondary }}
      />
      <CardContent sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ErrorBoundary FallbackComponent={WidgetErrorFallback}>
          <builder.content token={token} />
        </ErrorBoundary>
      </CardContent>
    </Card>
  )
}
