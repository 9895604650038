import { useEffect, useState } from 'react'
import { get, isEmpty, intersection, map } from 'lodash'

import { useFetchAlertsTableQuery } from './GraphQl/gql.generated'

const useAlertsTable = ({
  buildingId,
  sensorId,
  reportFilters,
  reportToken,
  page,
  setPage,
  rowsPerPage,
  isPrinting
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const [query, setQuery] = useState(urlParams.get('query')) // If query in search params, set that as initial query
  // (mainly needed for printing)
  const [eventFilter, setEventFilter] = useState(urlParams.get('eventFilter')) // If eventFilter in search params, set that as initial eventFilter
  // (mainly needed for printing)

  useEffect(() => {
    setPage(0) // Reset page number if query/eventFilter changed
  }, [query, eventFilter])

  const { data, loading } = useFetchAlertsTableQuery({
    variables: {
      buildingId,
      sensorId,
      reportFilters,
      reportToken,
      query,
      eventFilter,
      pagination: !isPrinting
        ? {
            page: page + 1, // TablePagination starts count pages from zero / 0
            per: rowsPerPage
          }
        : {
            page: 1,
            per: 1000
          }
    }
  })

  const alerts = get(data, ['fetchAlerts', 'collection'], [])
  const metadata = get(data, ['fetchAlerts', 'metadata'], {})

  const hasTemperature = !isEmpty(
    intersection(map(alerts, 'alertType'), ['high_temperature', 'low_temperature'])
  )
  const hasHumidity = !isEmpty(intersection(map(alerts, 'alertType'), ['high_humidity', 'low_humidity']))

  const hasSound = !isEmpty(intersection(map(alerts, 'alertType'), ['sound']))

  return {
    loading,
    alerts,
    metadata,
    hasTemperature,
    hasHumidity,
    hasSound,
    query,
    setQuery,
    eventFilter,
    setEventFilter
  }
}

export default useAlertsTable
