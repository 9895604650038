import React from 'react'
import { Box, Paper } from '@mui/material'

import useGatewaysPage from './useGatewaysPage'
import CreateGateway from '../../organisms/GatewaysTable/CreateGateway'
import GatewaysTable from '../../organisms/GatewaysTable'

const GatewaysPage = () => {
  const { building, loading, userType } = useGatewaysPage()

  if (loading) return <></>
  return (
    <Box sx={{ mt: 1 }}>
      <CreateGateway buildingId={building.id} userType={userType} />
      <Paper sx={{ mt: 2, p: 4 }}>
        <GatewaysTable building={building} showSearchBar={true} userType={userType} numRowsPerPage={15} />
      </Paper>
    </Box>
  )
}

export default GatewaysPage
