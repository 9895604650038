import React, { Fragment, useState } from 'react'
import { useFetchIaqChartDataQuery } from '../GraphQl/gql.generated'
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { Line } from 'react-chartjs-2'
import { humanizeLineLabels } from '../../../pages/dashboards/components/WidgetCard/utils'
import { useTheme } from '@mui/material/styles'
import {
  CO2_LABEL,
  HUMIDITY_LABEL,
  LIGHT_LEVEL_LABEL,
  PM_10_LABEL,
  PM_2_5_LABEL,
  PRESSURE_LABEL,
  TEMPERATURE_LABEL,
  TVOC_LABEL
} from '../../../../common/constants/reports'
import { fetchCriteria } from '../../../../common/utils/reports'

const IAQChartsCard = ({ filterCriteria, reportToken }) => {
  const [displayCoolingTemp, setDisplayCoolingTemp] = useState(true)
  const [displayHeatingTemp, setDisplayHeatingTemp] = useState(true)
  const [displayCo2Label, setDisplayCo2Label] = useState(true)
  const [displayPm25AnnualLabel, setDisplayPm25AnnualLabel] = useState(true)
  const [displayPm2524hrLabel, setDisplayPm2524hrLabel] = useState(true)
  const [displayPm10AnnualLabel, setDisplayPm10AnnualLabel] = useState(true)
  const theme = useTheme()

  const { data: resultChartData, loading: chartsLoading } = useFetchIaqChartDataQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken
    }
  })

  const winterRangeLabel = 'ASHRAE Winter Range (20°C - 23.3°C)'
  const summerRangeLabel = 'ASHRAE Summer Range (22.2°C - 26.6°C)'
  const co2LimitLabel = 'ASHRAE Max Indoor Level'
  const pm25AnnualLabel = 'ASHRAE Annual Mean Max'
  const pm2524hrLabel = 'ASHRAE 24hr Mean Max'
  const pm10AnnualLabel = 'WHO Annual Mean Max'

  const coolingTempAnnotation = {
    type: 'box',
    yScaleID: 'y',
    yMin: 20,
    yMax: 23.3,
    borderWidth: 0,
    backgroundColor: 'rgba(99, 255, 208, 0.25)',
    label: {
      display: true,
      content: winterRangeLabel,
      position: 'end',
      color: theme.palette.primary.main
    },
    display: displayCoolingTemp
  }

  const heatingTempAnnotation = {
    type: 'box',
    yScaleID: 'y',
    yMin: 22.2,
    yMax: 26.6,
    borderWidth: 0,
    backgroundColor: 'rgba(255, 161, 122, 0.15)',
    label: {
      display: true,
      content: summerRangeLabel,
      position: 'end',
      color: theme.palette.primary.main
    },
    display: displayHeatingTemp
  }

  const co2Annotation = {
    type: 'line',
    yScaleID: 'y',
    yMin: 1100,
    yMax: 1100,
    borderWidth: 2,
    borderColor: 'rgba(255,169,99,0.81)',
    label: {
      display: true,
      content: co2LimitLabel,
      position: 'end',
      backgroundColor: 'rgba(255,169,99,0.81)',
      color: theme.palette.primary.main
    },
    display: displayCo2Label
  }

  const pm25AnnualAnnotation = {
    type: 'line',
    yScaleID: 'y',
    yMin: 12,
    yMax: 12,
    borderWidth: 2,
    borderColor: 'rgba(255,169,99,0.81)',
    label: {
      display: true,
      content: pm25AnnualLabel,
      position: 'end',
      backgroundColor: 'rgba(255,169,99,0.81)',
      color: theme.palette.primary.main
    },
    display: displayPm25AnnualLabel
  }

  const pm2524hrAnnotation = {
    type: 'line',
    yScaleID: 'y',
    yMin: 35,
    yMax: 35,
    borderWidth: 2,
    borderColor: 'rgba(255,145,99,0.87)',
    label: {
      display: true,
      content: pm2524hrLabel,
      position: 'end',
      backgroundColor: 'rgba(255,145,99,0.87)',
      color: theme.palette.primary.main
    },
    display: displayPm2524hrLabel
  }

  const pm10AnnualAnnotation = {
    type: 'line',
    yScaleID: 'y',
    yMin: 15,
    yMax: 15,
    borderWidth: 2,
    borderColor: 'rgba(255,169,99,0.81)',
    label: {
      display: true,
      content: pm10AnnualLabel,
      position: 'end',
      backgroundColor: 'rgba(255,169,99,0.81)',
      color: theme.palette.primary.main
    },
    display: displayPm10AnnualLabel
  }

  const chartTypes = {
    temperature_chart: TEMPERATURE_LABEL,
    humidity_chart: HUMIDITY_LABEL,
    co2_chart: CO2_LABEL,
    tvoc_mg_m3_chart: TVOC_LABEL,
    pm_2_5_chart: PM_2_5_LABEL,
    pm_10_chart: PM_10_LABEL,
    pressure_chart: PRESSURE_LABEL,
    light_level_chart: LIGHT_LEVEL_LABEL
  }

  const defaultChartDataset = {}
  Object.keys(chartTypes).forEach((value) => (defaultChartDataset[value] = { labels: [], datasets: [] }))
  const chartResult = resultChartData?.fetchIaqChartData ?? defaultChartDataset

  return (
    <>
      {chartsLoading && (
        <Paper sx={{ mt: 2, p: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '20vh'
            }}
          >
            <Typography variant='h5' component='h5'>
              Loading Charts...
            </Typography>
            <CircularProgress />
          </Box>
        </Paper>
      )}
      {!chartsLoading &&
        Object.keys(chartTypes).map((chartType) => (
          <Fragment key={chartType}>
            <Paper sx={{ mt: 2, p: 4 }}>
              <h5 align={'center'} style={{ paddingBottom: 10 }}>
                {chartTypes[chartType]}
              </h5>
              <Line
                style={{ maxHeight: 600, paddingBottom: 60 }}
                data={{
                  labels: humanizeLineLabels(chartResult[chartType].labels),
                  datasets: chartResult[chartType].datasets.map(({ label, data }, key) => ({
                    label,
                    data, // borderColor: randomColor(key),
                    tension: 0.1
                  }))
                }}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      labels: {
                        boxWidth: 15,
                        boxHeight: 15,
                        font: {
                          size: 14
                        }
                      }
                    },
                    annotation: {
                      annotations: [
                        ...[chartType === 'temperature_chart' ? coolingTempAnnotation : { display: false }],
                        ...[chartType === 'temperature_chart' ? heatingTempAnnotation : { display: false }],
                        ...[chartType === 'co2_chart' ? co2Annotation : { display: false }],
                        ...[chartType === 'pm_2_5_chart' ? pm25AnnualAnnotation : { display: false }],
                        ...[chartType === 'pm_2_5_chart' ? pm2524hrAnnotation : { display: false }],
                        ...[chartType === 'pm_10_chart' ? pm10AnnualAnnotation : { display: false }]
                      ]
                    }
                  }
                }}
              />
              {chartType === 'temperature_chart' && (
                <Grid container style={{ height: 25, marginTop: -35, paddingBottom: 70 }}>
                  <Grid item style={{ margin: '0 auto' }}>
                    <Box className='label-container'>
                      <Box
                        onClick={() => setDisplayCoolingTemp(!displayCoolingTemp)}
                        className='label-container'
                      >
                        <Box className='colorbox winter'></Box>
                        <Typography
                          variant='body2'
                          style={{ textDecoration: !displayCoolingTemp ? 'line-through' : 'none' }}
                        >
                          {winterRangeLabel}
                        </Typography>
                      </Box>
                      <Box
                        className='label-container'
                        onClick={() => setDisplayHeatingTemp(!displayHeatingTemp)}
                      >
                        <Box className='colorbox summer'></Box>
                        <Typography
                          variant='body2'
                          style={{ textDecoration: !displayHeatingTemp ? 'line-through' : 'none' }}
                        >
                          {summerRangeLabel}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {chartType === 'co2_chart' && (
                <Grid container style={{ height: 25, marginTop: -35, paddingBottom: 70 }}>
                  <Grid item style={{ margin: '0 auto' }}>
                    <Box className='label-container'>
                      <Box onClick={() => setDisplayCo2Label(!displayCo2Label)} className='label-container'>
                        <Box className='colorbox co2Label'></Box>
                        <Typography
                          variant='body2'
                          style={{ textDecoration: !displayCo2Label ? 'line-through' : 'none' }}
                        >
                          {co2LimitLabel}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {chartType === 'pm_2_5_chart' && (
                <Grid container style={{ height: 25, marginTop: -35, paddingBottom: 70 }}>
                  <Grid item style={{ margin: '0 auto' }}>
                    <Box className='label-container'>
                      <Box
                        onClick={() => setDisplayPm25AnnualLabel(!displayPm25AnnualLabel)}
                        className='label-container'
                      >
                        <Box className='colorbox co2Label'></Box>
                        <Typography
                          variant='body2'
                          style={{ textDecoration: !displayPm25AnnualLabel ? 'line-through' : 'none' }}
                        >
                          {pm25AnnualLabel}
                        </Typography>
                      </Box>
                      <Box
                        onClick={() => setDisplayPm2524hrLabel(!displayPm2524hrLabel)}
                        className='label-container'
                      >
                        <Box className='colorbox pm2524hrLabel'></Box>
                        <Typography
                          variant='body2'
                          style={{ textDecoration: !displayPm2524hrLabel ? 'line-through' : 'none' }}
                        >
                          {pm2524hrLabel}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {chartType === 'pm_10_chart' && (
                <Grid container style={{ height: 25, marginTop: -35, paddingBottom: 70 }}>
                  <Grid item style={{ margin: '0 auto' }}>
                    <Box className='label-container' style={{ paddingBottom: 5 }}>
                      <Box
                        onClick={() => setDisplayPm10AnnualLabel(!displayPm10AnnualLabel)}
                        className='label-container'
                      >
                        <Box className='colorbox co2Label'></Box>
                        <Typography
                          variant='body2'
                          style={{ textDecoration: !displayPm10AnnualLabel ? 'line-through' : 'none' }}
                        >
                          {pm10AnnualLabel}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography style={{ marginLeft: -50 }} variant='caption'>
                      Note: ASHRAE 24hr Mean Max for PM10 is 150 µg/m³
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Fragment>
        ))}
    </>
  )
}

export default IAQChartsCard
