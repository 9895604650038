import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

/**
 * A dialog box component to confirm that the user wants to do something.
 *   - dialogOpen: specifies the state of the dialog box, whether it is open or not (should be declared in parent namespace)
 *   - setDialogOpen: state function to alter the state of the dialogBox (should be declared in parent namespace)
 *   - title: title of the dialog box
 *   - msg: the message to display to the user, should probably ask the if they're sure they want to do said thing
 *   - leftBtnLbl: label of the left button (ex: Cancel)
 *   - leftBtnProps: object that properties of the left button (ex: color, onClick)
 *   - rightBtnLbl: label of the right button
 *   - rightBtnProps: object that properties of the right button (ex: color, onClick)
 **/
export const ConfirmDialog = ({
  dialogOpen,
  setDialogOpen,
  title,
  msg,
  leftBtnLbl,
  leftBtnProps = {},
  rightBtnLbl,
  rightBtnProps = {}
}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false)
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button {...leftBtnProps}>{leftBtnLbl}</Button>
        <Button {...rightBtnProps}>{rightBtnLbl}</Button>
      </DialogActions>
    </Dialog>
  )
}
