import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPublicDashboardQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type GetPublicDashboardQuery = { __typename?: 'Query', getDashboard: { __typename?: 'Dashboard', id: string, name: string, token: string, filters?: unknown | null, selectedBuildings: Array<string>, selectedUnits: Array<string>, selectedSensors: Array<string> } };


export const GetPublicDashboardDocument = gql`
    query GetPublicDashboard($token: String!) {
  getDashboard(token: $token) {
    id
    name
    token
    filters
    selectedBuildings
    selectedUnits
    selectedSensors
  }
}
    `;

/**
 * __useGetPublicDashboardQuery__
 *
 * To run a query within a React component, call `useGetPublicDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicDashboardQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetPublicDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetPublicDashboardQuery, GetPublicDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicDashboardQuery, GetPublicDashboardQueryVariables>(GetPublicDashboardDocument, options);
      }
export function useGetPublicDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicDashboardQuery, GetPublicDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicDashboardQuery, GetPublicDashboardQueryVariables>(GetPublicDashboardDocument, options);
        }
export type GetPublicDashboardQueryHookResult = ReturnType<typeof useGetPublicDashboardQuery>;
export type GetPublicDashboardLazyQueryHookResult = ReturnType<typeof useGetPublicDashboardLazyQuery>;
export type GetPublicDashboardQueryResult = Apollo.QueryResult<GetPublicDashboardQuery, GetPublicDashboardQueryVariables>;