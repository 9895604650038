import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TableCell from '@mui/material/TableCell'

//src
import { getDashboardUrl } from 'common/utils/dashboards'
import QrCodeDialog from 'components/molecules/QrCodeDialog'
import ActionsDropdown from 'components/molecules/ActionsDropdown'
import ClipboardCopy from 'components/molecules/ClipboardCopy'
import { useDeleteDashboardMutation } from '../gql.generated'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}))

interface Props {
  isUserSuperAdmin: boolean
  // TODO: Fragmentation typing
  dashboard: any
}

const DashboardListRow = ({ dashboard, isUserSuperAdmin }: Props) => {
  const navigate = useNavigate()
  const link = getDashboardUrl(dashboard.token)

  const moveToLink = (url) => () => navigate(url)

  const [deleteMutation] = useDeleteDashboardMutation()

  const options = [
    {
      title: 'Edit',
      action: moveToLink(`/dashboards/${dashboard.token}/edit`)
    },
    {
      title: 'View',
      action: moveToLink(`/dashboards/${dashboard.token}`)
    },
    {
      title: 'Delete',
      action: () => {
        deleteMutation({
          variables: { input: { id: dashboard.id } },
          refetchQueries: ['FetchDashboards']
        })
      }
    }
  ]

  return (
    <Fragment>
      <StyledTableRow hover>
        <TableCell
          sx={{
            cursor: 'pointer'
          }}
          component='th'
          onClick={moveToLink(`/dashboards/${dashboard.token}/edit`)}
          scope='row'
        >
          {dashboard.name}
        </TableCell>
        {isUserSuperAdmin && (
          <TableCell component='th' scope='row'>
            {dashboard.company.name}
          </TableCell>
        )}
        <TableCell align='left'>
          {link}
          <ClipboardCopy text={link} />
        </TableCell>
        <TableCell align='center'>
          <QrCodeDialog link={link} />
        </TableCell>
        <TableCell align='center' sx={{ width: 170 }}>
          <ActionsDropdown options={options} />
        </TableCell>
      </StyledTableRow>
    </Fragment>
  )
}

export default DashboardListRow
