import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBuildingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBuildingQuery = { __typename?: 'Query', getBuilding: { __typename?: 'Building', id: string, buildingType: string } };

export type GetCurrentUserTypeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentUserTypeQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', id: string, type: string } };


export const GetBuildingDocument = gql`
    query GetBuilding($id: ID!) {
  getBuilding(id: $id) {
    id
    buildingType
  }
}
    `;

/**
 * __useGetBuildingQuery__
 *
 * To run a query within a React component, call `useGetBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingQuery, GetBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingQuery, GetBuildingQueryVariables>(GetBuildingDocument, options);
      }
export function useGetBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingQuery, GetBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingQuery, GetBuildingQueryVariables>(GetBuildingDocument, options);
        }
export type GetBuildingQueryHookResult = ReturnType<typeof useGetBuildingQuery>;
export type GetBuildingLazyQueryHookResult = ReturnType<typeof useGetBuildingLazyQuery>;
export type GetBuildingQueryResult = Apollo.QueryResult<GetBuildingQuery, GetBuildingQueryVariables>;
export const GetCurrentUserTypeDocument = gql`
    query GetCurrentUserType {
  getCurrentUser {
    id
    type
  }
}
    `;

/**
 * __useGetCurrentUserTypeQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserTypeQuery, GetCurrentUserTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserTypeQuery, GetCurrentUserTypeQueryVariables>(GetCurrentUserTypeDocument, options);
      }
export function useGetCurrentUserTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserTypeQuery, GetCurrentUserTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserTypeQuery, GetCurrentUserTypeQueryVariables>(GetCurrentUserTypeDocument, options);
        }
export type GetCurrentUserTypeQueryHookResult = ReturnType<typeof useGetCurrentUserTypeQuery>;
export type GetCurrentUserTypeLazyQueryHookResult = ReturnType<typeof useGetCurrentUserTypeLazyQuery>;
export type GetCurrentUserTypeQueryResult = Apollo.QueryResult<GetCurrentUserTypeQuery, GetCurrentUserTypeQueryVariables>;