import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDoorActivityBarsWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchDoorActivityBarsWidgetQuery = { __typename?: 'Query', widgetDoorActivityBars?: { __typename?: 'Bar', datasets: Array<{ __typename?: 'BarDataset', label: string, data: unknown }> } | null };


export const FetchDoorActivityBarsWidgetDocument = gql`
    query FetchDoorActivityBarsWidget($token: String!) {
  widgetDoorActivityBars(token: $token) {
    datasets {
      label
      data
    }
  }
}
    `;

/**
 * __useFetchDoorActivityBarsWidgetQuery__
 *
 * To run a query within a React component, call `useFetchDoorActivityBarsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDoorActivityBarsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDoorActivityBarsWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchDoorActivityBarsWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchDoorActivityBarsWidgetQuery, FetchDoorActivityBarsWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDoorActivityBarsWidgetQuery, FetchDoorActivityBarsWidgetQueryVariables>(FetchDoorActivityBarsWidgetDocument, options);
      }
export function useFetchDoorActivityBarsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDoorActivityBarsWidgetQuery, FetchDoorActivityBarsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDoorActivityBarsWidgetQuery, FetchDoorActivityBarsWidgetQueryVariables>(FetchDoorActivityBarsWidgetDocument, options);
        }
export type FetchDoorActivityBarsWidgetQueryHookResult = ReturnType<typeof useFetchDoorActivityBarsWidgetQuery>;
export type FetchDoorActivityBarsWidgetLazyQueryHookResult = ReturnType<typeof useFetchDoorActivityBarsWidgetLazyQuery>;
export type FetchDoorActivityBarsWidgetQueryResult = Apollo.QueryResult<FetchDoorActivityBarsWidgetQuery, FetchDoorActivityBarsWidgetQueryVariables>;