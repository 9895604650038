import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, LinearProgress } from '@mui/material'
// src
import DashboardFiltersPanel from '../components/DashboardFiltersPanel'
import WidgetsContainer from '../components/WidgetsContainer'
import DashboardInfo from '../components/DashboardInfo'
import { useGetEditDashboardQuery } from './gql.generated'
import AddWidgetDialog from '../components/AddWidgetDialog'

export default function EditDashboard() {
  const { token } = useParams()

  const { data: dashboardData, loading: dashboardLoading } = useGetEditDashboardQuery({
    variables: { token }
  })

  const dashboard = dashboardData?.getDashboard

  if (dashboardLoading) return <LinearProgress />

  return (
    <Box>
      <DashboardInfo dashboard={dashboard} />
      <DashboardFiltersPanel dashboard={dashboard} />
      {dashboard.id && (
        <Box mt={1} display='flex' justifyContent={{ xs: 'center', lg: 'flex-start' }}>
          <AddWidgetDialog token={token} dashboardId={dashboard.id} />
        </Box>
      )}
      <WidgetsContainer />
    </Box>
  )
}
