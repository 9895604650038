import ReactOnRails from 'react-on-rails'
import Chartkick from 'chartkick'
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import 'chartjs-adapter-date-fns'
Chart.register(annotationPlugin)
Chartkick.addAdapter(Chart)

import App from 'App'

ReactOnRails.register({ App })
