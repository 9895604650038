import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Paper } from '@mui/material'
import ValvesTable from '../../../organisms/ValvesTable'
import { useNavigate } from 'react-router-dom'
const ValvesPage = () => {
  const { buildingId } = useParams()
  const navigate = useNavigate()

  return (
    <Box sx={{ mt: 1 }}>
      <Button onClick={() => navigate(`/buildings/${buildingId}/valves/new`)} variant='contained'>
        Add Valve&nbsp;&nbsp;＋
      </Button>
      <Paper sx={{ mt: 2, p: 4 }}>
        <ValvesTable buildingId={buildingId} showSearchBar={true} userType={{}} numRowsPerPage={25} />
      </Paper>
    </Box>
  )
}

export default ValvesPage
