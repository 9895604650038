import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

// Note that in package.json we are forcing to use resolutions  "barcode-detector": "^2.3.1".
// Previous versions of this dependent library where causing QR scanning to be erratic
import { Scanner, outline } from '@yudiel/react-qr-scanner'

const ScanQrCodeDialog = ({ qrModalOpen, setQrModalOpen, handleCloseScanQr }) => (
  <Dialog open={qrModalOpen} onClose={handleCloseScanQr}>
    <DialogTitle>Sensor QR Code Scan</DialogTitle>
    <DialogContent>
      <Scanner
        onScan={(scanResult) => handleCloseScanQr({ scanResult })}
        components={{
          audio: true,
          onOff: true,
          torch: true,
          zoom: true,
          finder: true,
          tracker: outline
        }}
        allowMultiple={true}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setQrModalOpen(false)} variant='outlined' color='primary'>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
)

export default ScanQrCodeDialog
