import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTemperaturePolarsWidgetsQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchTemperaturePolarsWidgetsQuery = { __typename?: 'Query', widgetTemperaturePolars?: { __typename?: 'Bar', datasets: Array<{ __typename?: 'BarDataset', label: string, data: unknown }> } | null };


export const FetchTemperaturePolarsWidgetsDocument = gql`
    query FetchTemperaturePolarsWidgets($token: String!) {
  widgetTemperaturePolars(token: $token) {
    datasets {
      label
      data
    }
  }
}
    `;

/**
 * __useFetchTemperaturePolarsWidgetsQuery__
 *
 * To run a query within a React component, call `useFetchTemperaturePolarsWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTemperaturePolarsWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTemperaturePolarsWidgetsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchTemperaturePolarsWidgetsQuery(baseOptions: Apollo.QueryHookOptions<FetchTemperaturePolarsWidgetsQuery, FetchTemperaturePolarsWidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchTemperaturePolarsWidgetsQuery, FetchTemperaturePolarsWidgetsQueryVariables>(FetchTemperaturePolarsWidgetsDocument, options);
      }
export function useFetchTemperaturePolarsWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchTemperaturePolarsWidgetsQuery, FetchTemperaturePolarsWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchTemperaturePolarsWidgetsQuery, FetchTemperaturePolarsWidgetsQueryVariables>(FetchTemperaturePolarsWidgetsDocument, options);
        }
export type FetchTemperaturePolarsWidgetsQueryHookResult = ReturnType<typeof useFetchTemperaturePolarsWidgetsQuery>;
export type FetchTemperaturePolarsWidgetsLazyQueryHookResult = ReturnType<typeof useFetchTemperaturePolarsWidgetsLazyQuery>;
export type FetchTemperaturePolarsWidgetsQueryResult = Apollo.QueryResult<FetchTemperaturePolarsWidgetsQuery, FetchTemperaturePolarsWidgetsQueryVariables>;