import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchCleaningRequestsWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
  sorting?: Types.InputMaybe<Types.SortingType>;
}>;


export type FetchCleaningRequestsWidgetQuery = { __typename?: 'Query', widgetCleaningRequests: { __typename?: 'CleaningRequestCollection', collection: Array<{ __typename?: 'CleaningRequest', id: string, createdAt: unknown, unit: { __typename?: 'Unit', id: string, number?: string | null } }>, metadata: { __typename?: 'CollectionMetadata', totalCount: number } } };


export const FetchCleaningRequestsWidgetDocument = gql`
    query FetchCleaningRequestsWidget($token: String!, $pagination: PaginationType, $sorting: SortingType) {
  widgetCleaningRequests(
    token: $token
    pagination: $pagination
    sorting: $sorting
  ) {
    collection {
      id
      createdAt
      unit {
        id
        number
      }
    }
    metadata {
      totalCount
    }
  }
}
    `;

/**
 * __useFetchCleaningRequestsWidgetQuery__
 *
 * To run a query within a React component, call `useFetchCleaningRequestsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCleaningRequestsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCleaningRequestsWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchCleaningRequestsWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchCleaningRequestsWidgetQuery, FetchCleaningRequestsWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCleaningRequestsWidgetQuery, FetchCleaningRequestsWidgetQueryVariables>(FetchCleaningRequestsWidgetDocument, options);
      }
export function useFetchCleaningRequestsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCleaningRequestsWidgetQuery, FetchCleaningRequestsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCleaningRequestsWidgetQuery, FetchCleaningRequestsWidgetQueryVariables>(FetchCleaningRequestsWidgetDocument, options);
        }
export type FetchCleaningRequestsWidgetQueryHookResult = ReturnType<typeof useFetchCleaningRequestsWidgetQuery>;
export type FetchCleaningRequestsWidgetLazyQueryHookResult = ReturnType<typeof useFetchCleaningRequestsWidgetLazyQuery>;
export type FetchCleaningRequestsWidgetQueryResult = Apollo.QueryResult<FetchCleaningRequestsWidgetQuery, FetchCleaningRequestsWidgetQueryVariables>;