import React from 'react'
import { useState } from 'react'
import FeedbackDialog from './components/FeedbackDialog'

import EmergencyResponse from './components/EmergencyResponse'
import {
  EVENT_TYPE_WATER,
  EVENT_TYPE_LOW_TEMPERATURE,
  EVENT_TYPE_HIGH_TEMPERATURE,
  EVENT_TYPE_LOW_HUMIDITY,
  EVENT_TYPE_HIGH_HUMIDITY,
  EVENT_TYPE_POWER,
  EVENT_TYPE_SOUND
} from '../../../common/constants/alerts'

import { useParams } from 'react-router-dom'
import { useGetUserAlertQuery, useUpdateAlertMutation } from './GraphQl/gql.generated'

export default function PublicAlert() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { shortcode } = useParams()
  const { data: alertData, loading: alertLoading } = useGetUserAlertQuery({
    variables: { shortcode: shortcode }
  })

  const occupants = alertData?.getUserAlert?.alert?.unit?.users || []
  var occupantsList = []
  occupants.forEach((occupant) => {
    const mappedData = {
      email: occupant.email,
      name: occupant.firstName + ' ' + occupant.lastName,
      phone: occupant.phoneNumber
    }
    occupantsList.push(mappedData)
  })

  const [updateAlert] = useUpdateAlertMutation()
  const onSubmit = (severity, feedback) => {
    const input = {
      id: alertData.getUserAlert?.alert?.id,
      severity: severity,
      acknowledgedUserId: alertData.getUserAlert?.userId,
      feedback: feedback
    }
    updateAlert({
      variables: {
        input: input
      },
      onCompleted: () => {
        setIsDialogOpen(true)
      }
    })
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
    window.location.reload()
  }

  const defaultMsg = 'No emergency response message set, please contact your system administrator to set one.'
  if (alertLoading) {
    return null
  }

  if (!alertData.getUserAlert.alert) {
    return (
      <>
        <EmergencyResponse
          Acknowledge={true}
          IsWaterTempHumPwrAlert={false}
          buildingName={''}
          waterEmergencyResponse={defaultMsg}
          doorEmergencyResponse={defaultMsg}
          acknowledgedUserName={null}
          severity={null}
          onSubmit={onSubmit}
          feedback={null}
          PropMgmtData={null}
        />
        <FeedbackDialog isOpen={isDialogOpen} onClose={closeDialog} />
      </>
    )
  }

  const building = alertData.getUserAlert.alert.unit.building
  const waterAlertMessage = building.waterAlertMessage || defaultMsg
  const doorAlertMessage = building.doorAlertMessage || defaultMsg

  const isWaterTempHumPwrAlert = [
    EVENT_TYPE_WATER,
    EVENT_TYPE_LOW_TEMPERATURE,
    EVENT_TYPE_HIGH_TEMPERATURE,
    EVENT_TYPE_LOW_HUMIDITY,
    EVENT_TYPE_HIGH_HUMIDITY,
    EVENT_TYPE_POWER,
    EVENT_TYPE_SOUND
  ].includes(alertData.getUserAlert.alert.alertType)

  return (
    <>
      <EmergencyResponse
        Acknowledge={true}
        IsWaterTempHumPwrAlert={isWaterTempHumPwrAlert}
        buildingName={building.name}
        waterEmergencyResponse={waterAlertMessage}
        doorEmergencyResponse={doorAlertMessage}
        acknowledgedUserName={alertData?.getUserAlert?.alert?.acknowledgedUser}
        severity={alertData.getUserAlert.alert.severity}
        feedback={alertData.getUserAlert.alert.feedback}
        onSubmit={onSubmit}
        PropMgmtData={occupantsList}
      />
      <FeedbackDialog isOpen={isDialogOpen} onClose={closeDialog} />
    </>
  )
}
