import {
  FormControl,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  Chip,
  TextField
} from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Controller } from 'react-hook-form'
import FormCheckbox from '../atoms/CheckmarkCell'
import TooltipIcon from '../atoms/TooltopIcon'
import React, { useEffect } from 'react'
import timezones from 'timezones-list'
import { isMobile } from '../../common/utils/display'

// This file can be refactored to also support configuration for user alerting hours and not just for doors
const TimeConfig = ({
  control,
  doorEnabled,
  hoursValues,
  setHoursValues,
  setValue,
  title,
  fieldId,
  tooltip = null
}) => {
  const daysOfWeek = [
    { label: 'Monday', value: 0 },
    { label: 'Tuesday', value: 1 },
    { label: 'Wednesday', value: 2 },
    { label: 'Thursday', value: 3 },
    { label: 'Friday', value: 4 },
    { label: 'Saturday', value: 5 },
    { label: 'Sunday', value: 6 }
  ]

  useEffect(() => {
    hoursValues['doorAlertHoursEnabled'] ||= false
    hoursValues['doorAlertDaysArr'] ||= []
    hoursValues['doorAlertHoursStart'] ||= new Date()
    hoursValues['doorAlertHoursEnd'] ||= new Date()
    hoursValues['doorAlertTimezone'] ||= 'America/Edmonton'
    hoursValues['doorAlertWeekendHolidays'] ||= false
  }, [])

  const displayIsMobile = isMobile()

  return (
    <>
      <Grid container spacing={0} sx={{ pt: 0 }}>
        <Grid item xs={3} sm={3} md={3} lg={3} sx={{ mr: displayIsMobile ? 0 : -3.2 }}>
          <FormControl fullWidth>
            <Controller
              name={fieldId}
              control={control}
              render={({ field }) => (
                <FormCheckbox
                  label={
                    <Grid container direction='row' alignItems='center'>
                      <Grid item>
                        <Typography>{title}</Typography>
                      </Grid>
                    </Grid>
                  }
                  checked={field.value}
                  onChange={(e) => setValue(fieldId, e.target.checked)}
                />
              )}
            />
          </FormControl>
        </Grid>
        {doorEnabled && (
          <Grid item xs={9} sm={7} md={7} lg={7} sx={{ mt: 0.3 }}>
            <Grid container>
              <Grid item sx={{ mr: -2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) =>
                        setHoursValues({ ...hoursValues, doorAlertHoursEnabled: e.target.checked })
                      }
                    />
                  }
                  label='Set Door Alert Hours'
                />
              </Grid>
              {tooltip && (
                <Grid item>
                  <TooltipIcon title={tooltip} textFontSize={14} />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {hoursValues['doorAlertHoursEnabled'] && (
        <Box
          sx={{
            border: '1px solid lightgrey',
            padding: 0.5,
            pt: 1.5,
            pr: 4,
            borderRadius: 1,
            mb: 1
          }}
        >
          <Grid container spacing={0} sx={{ pt: 1, pl: 2, pb: 2 }}>
            <FormControl>
              <InputLabel id='days-of-week-label'>Select Days</InputLabel>
              <Select
                sx={{ minWidth: 200 }}
                labelId='days-of-week-label'
                multiple
                placeholder='Select Days'
                value={hoursValues['doorAlertDaysArr']}
                onChange={(e) => setHoursValues({ ...hoursValues, doorAlertDaysArr: e.target.value })}
                input={<OutlinedInput label='Select Days' />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected
                      .sort((a, b) => a - b)
                      .map((value) => (
                        <Chip
                          key={value}
                          label={daysOfWeek.find((day) => day.value === value).label}
                          onDelete={() => {}}
                        />
                      ))}
                  </Box>
                )}
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    <Checkbox checked={hoursValues['doorAlertDaysArr'].indexOf(day.value) > -1} />
                    <ListItemText primary={day.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 1, pl: 2, pb: 2 }}>
            <Grid item sx={{ maxWidth: 180 }}>
              <TimePicker
                label='Start Time'
                value={hoursValues['doorAlertHoursStart']}
                onChange={(e) => setHoursValues({ ...hoursValues, doorAlertHoursStart: e })}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={7} sm={2} md={2} lg={2}>
              <Typography sx={{ mt: displayIsMobile ? 0 : 2, ml: displayIsMobile ? -6 : 0 }}>
                until
              </Typography>
            </Grid>
            <Grid item sx={{ maxWidth: 180 }}>
              <TimePicker
                label='End Time'
                value={hoursValues['doorAlertHoursEnd']}
                onChange={(e) => {
                  setHoursValues({ ...hoursValues, doorAlertHoursEnd: e })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pt: 2, pl: 4.5, pb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) =>
                    setHoursValues({ ...hoursValues, doorAlertWeekendHolidays: e.target.checked })
                  }
                />
              }
              label='Alert on Weekends & Holidays (24hrs)'
            />
          </Grid>
          <Grid container spacing={2} sx={{ pt: 2, pl: 4, pb: 2 }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id='timezone-select-label'>Select Timezone</InputLabel>
              <Select
                labelId='timezone-select-label'
                value={hoursValues['doorAlertTimezone']}
                onChange={(e) => setHoursValues({ ...hoursValues, doorAlertTimezone: e.target.value })}
                input={<OutlinedInput label='Select Timezone' />}
              >
                {timezones.map((timezone) => (
                  <MenuItem key={timezone.name} value={timezone.tzCode}>
                    {timezone.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default TimeConfig
