import React, { useState } from 'react'
import { format } from 'date-fns'
import {
  Grid,
  Chip,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  List,
  ListItemText,
  ListItem,
  Button
} from '@mui/material'

// TODO: Fragmentation typing
interface Props {
  dashboard: any | null
}

const formatDate = (date: string) => format(Date.parse(date), 'PP')

export default function DashboardFiltersInfo({ dashboard }: Props) {
  const [open, setOpen] = useState(false)
  const [dialogTitle, setDialogTitle] = useState('')
  const [dialogList, setDialogList] = useState([])
  const { filters } = dashboard

  const hasFilterRecords =
    dashboard.selectedBuildings.length !== 0 ||
    dashboard.selectedUnits.length !== 0 ||
    dashboard.selectedSensors.length !== 0

  if (!filters) return <></>

  const handleClickOpen = (title: string, list: any[]) => {
    setDialogTitle(title)
    setDialogList(list)
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <Grid container direction={{ xs: 'column', lg: 'row' }}>
      {hasFilterRecords && (
        <Grid item mt={1} mr={2}>
          <Grid item>
            <Grid item>
              <Typography variant='subtitle1'>Asset Filters</Typography>
            </Grid>

            <Grid item container direction='column' alignItems='center' mt={1} style={{ minWidth: '270px' }}>
              {dashboard.selectedBuildings.length !== 0 && (
                <Grid item container alignItems='center' gap={1}>
                  <Grid item>Buildings:</Grid>
                  <Grid item key={dashboard.selectedBuildings[0]}>
                    <Chip label={`${dashboard.selectedBuildings[0]}`} />
                  </Grid>
                  {dashboard.selectedBuildings?.length > 1 && (
                    <Chip
                      color='primary'
                      label={`+ ${dashboard.selectedBuildings.length - 1} more`}
                      onClick={() => handleClickOpen('Buildings', dashboard.selectedBuildings)}
                    />
                  )}
                </Grid>
              )}

              {dashboard.selectedUnits.length !== 0 && (
                <Grid item container alignItems='center' gap={1} mt={1}>
                  <Grid item>Zones:</Grid>
                  <Grid item key={dashboard.selectedUnits[0]}>
                    <Chip label={`${dashboard.selectedUnits[0]}`} />
                  </Grid>
                  {dashboard.selectedUnits?.length > 1 && (
                    <Chip
                      color='primary'
                      label={`+ ${dashboard.selectedUnits.length - 1} more`}
                      onClick={() => handleClickOpen('Zones', dashboard.selectedUnits)}
                    />
                  )}
                  <Dialog
                    sx={{ textAlign: 'center' }}
                    open={open}
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                      <List>
                        {dialogList.map((item) => (
                          <ListItem dense>
                            <ListItemText primary={item} />
                          </ListItem>
                        ))}
                      </List>
                      <br />
                      <Button variant='outlined' onClick={handleClose}>
                        Done
                      </Button>
                    </DialogContent>
                  </Dialog>
                </Grid>
              )}

              {dashboard.selectedSensors.length !== 0 && (
                <Grid item container alignItems='center' gap={1} mt={1}>
                  <Grid item>Sensors:</Grid>
                  <Grid item key={dashboard.selectedSensors[0]}>
                    <Chip label={`${dashboard.selectedSensors[0]}`} />
                  </Grid>
                  {dashboard.selectedSensors?.length > 1 && (
                    <Chip
                      color='primary'
                      label={`+ ${dashboard.selectedSensors.length - 1} more`}
                      onClick={() => handleClickOpen('Sensors', dashboard.selectedSensors)}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item mt={1}>
        <Grid item>
          <Typography variant='subtitle1'>Date & Time Filters</Typography>

          {filters.dateFilterType === 'since_date' && (
            <Grid item container mt={1} alignItems='center' gap={1} style={{ minWidth: '270px' }}>
              <Grid item>Events Since:</Grid>
              <Grid item>
                <Chip label={`${filters.timeValue} ${filters.timeUnit} ago`} />
              </Grid>
            </Grid>
          )}

          {filters.dateFilterType === 'date_range' && (
            <Grid item container alignItems='center' mt={1} gap={1} style={{ minWidth: '270px' }}>
              <Grid item>Date Range:</Grid>
              <Grid item>
                <Chip label={`${formatDate(filters.startDate)} - ${formatDate(filters.endDate)}`} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
