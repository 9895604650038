import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDashboardWidgetsQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchDashboardWidgetsQuery = { __typename?: 'Query', fetchDashboardWidgets: { __typename?: 'DashboardWidgetCollection', collection: Array<{ __typename?: 'DashboardWidget', id: string, type: Types.DashboardWidgetType, position: number, createdAt: unknown }> } };


export const FetchDashboardWidgetsDocument = gql`
    query FetchDashboardWidgets($token: String!, $pagination: PaginationType) {
  fetchDashboardWidgets(token: $token, pagination: $pagination) {
    collection {
      id
      type
      position
      createdAt
    }
  }
}
    `;

/**
 * __useFetchDashboardWidgetsQuery__
 *
 * To run a query within a React component, call `useFetchDashboardWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDashboardWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDashboardWidgetsQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchDashboardWidgetsQuery(baseOptions: Apollo.QueryHookOptions<FetchDashboardWidgetsQuery, FetchDashboardWidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDashboardWidgetsQuery, FetchDashboardWidgetsQueryVariables>(FetchDashboardWidgetsDocument, options);
      }
export function useFetchDashboardWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDashboardWidgetsQuery, FetchDashboardWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDashboardWidgetsQuery, FetchDashboardWidgetsQueryVariables>(FetchDashboardWidgetsDocument, options);
        }
export type FetchDashboardWidgetsQueryHookResult = ReturnType<typeof useFetchDashboardWidgetsQuery>;
export type FetchDashboardWidgetsLazyQueryHookResult = ReturnType<typeof useFetchDashboardWidgetsLazyQuery>;
export type FetchDashboardWidgetsQueryResult = Apollo.QueryResult<FetchDashboardWidgetsQuery, FetchDashboardWidgetsQueryVariables>;