import React from 'react'
import { Box, Paper } from '@mui/material'
import { useQueryParam, StringParam } from 'use-query-params'
import { useGetBuildingQuery, useGetCurrentUserTypeQuery } from './GraphQl/gql.generated'
import { get } from 'lodash'

import PropertyManagersTable from '../../organisms/PropertyManagersTable'

const PropertyManagersPage = () => {
  const [buildingId] = useQueryParam('building_id', StringParam)

  // Get current user
  const { data: userData } = useGetCurrentUserTypeQuery()
  const currentUser = get(userData, ['getCurrentUser'], {})

  // Get selected building
  const { data: buildingData, loading } = useGetBuildingQuery({
    variables: { id: buildingId }
  })
  const building = buildingData?.getBuilding

  // Render PropertyManagersPage
  if (loading) return <></>
  return (
    <Box sx={{ mt: 1 }}>
      <Paper sx={{ mt: 2, p: 4 }}>
        <PropertyManagersTable building={building} currentUser={currentUser} />
      </Paper>
    </Box>
  )
}

export default PropertyManagersPage
