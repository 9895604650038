import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchExteriorTemperaturesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
  sorting?: Types.InputMaybe<Types.SortingType>;
}>;


export type FetchExteriorTemperaturesWidgetQuery = { __typename?: 'Query', widgetExteriorTemperatures: { __typename?: 'BuildingCollection', collection: Array<{ __typename?: 'Building', id: string, name?: string | null, location: { __typename?: 'Location', actualWeather?: { __typename?: 'LocationWeather', temperature: number } | null } }>, metadata: { __typename?: 'CollectionMetadata', totalCount: number } } };


export const FetchExteriorTemperaturesWidgetDocument = gql`
    query FetchExteriorTemperaturesWidget($token: String!, $pagination: PaginationType, $sorting: SortingType) {
  widgetExteriorTemperatures(
    token: $token
    pagination: $pagination
    sorting: $sorting
  ) {
    collection {
      id
      name
      location {
        actualWeather {
          temperature
        }
      }
    }
    metadata {
      totalCount
    }
  }
}
    `;

/**
 * __useFetchExteriorTemperaturesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchExteriorTemperaturesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExteriorTemperaturesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExteriorTemperaturesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchExteriorTemperaturesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchExteriorTemperaturesWidgetQuery, FetchExteriorTemperaturesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchExteriorTemperaturesWidgetQuery, FetchExteriorTemperaturesWidgetQueryVariables>(FetchExteriorTemperaturesWidgetDocument, options);
      }
export function useFetchExteriorTemperaturesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchExteriorTemperaturesWidgetQuery, FetchExteriorTemperaturesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchExteriorTemperaturesWidgetQuery, FetchExteriorTemperaturesWidgetQueryVariables>(FetchExteriorTemperaturesWidgetDocument, options);
        }
export type FetchExteriorTemperaturesWidgetQueryHookResult = ReturnType<typeof useFetchExteriorTemperaturesWidgetQuery>;
export type FetchExteriorTemperaturesWidgetLazyQueryHookResult = ReturnType<typeof useFetchExteriorTemperaturesWidgetLazyQuery>;
export type FetchExteriorTemperaturesWidgetQueryResult = Apollo.QueryResult<FetchExteriorTemperaturesWidgetQuery, FetchExteriorTemperaturesWidgetQueryVariables>;