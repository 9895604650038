import { get } from 'lodash'

import { useGetCurrentOccupantUserQuery } from './GraphQl/gql.generated'

export const useOccupantDashboard = () => {
  const { data: occupantData, loading } = useGetCurrentOccupantUserQuery()

  const occupantsUnitId = get(occupantData, ['getCurrentUser', 'userBuildings', 0, 'unitId'])
  const buildingData = get(occupantData, ['getCurrentUser', 'userBuildings', 0, 'building'])

  return { occupantData, occupantsUnitId, buildingData, loading }
}
