import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSensorEventsTableQueryVariables = Types.Exact<{
  sensorId?: Types.InputMaybe<Types.Scalars['ID']>;
  eventTimeAfter?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchSensorEventsTableQuery = { __typename?: 'Query', fetchSensorEvents: { __typename?: 'SensorEventCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'SensorEvent', id: string, eventTime: unknown, displayEventType?: string | null, temperature?: number | null, humidity?: number | null, powerSource?: string | null, sensorMuted: boolean, messageId: string, generatedAlert?: boolean | null, sensor: { __typename?: 'Sensor', id: string, location?: string | null, hardwareId: string, unit?: { __typename?: 'Unit', number?: string | null } | null } }> } };


export const FetchSensorEventsTableDocument = gql`
    query FetchSensorEventsTable($sensorId: ID, $eventTimeAfter: ISO8601DateTime, $pagination: PaginationType) {
  fetchSensorEvents(
    sensorId: $sensorId
    eventTimeAfter: $eventTimeAfter
    pagination: $pagination
  ) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      id
      eventTime
      displayEventType
      temperature
      humidity
      powerSource
      sensorMuted
      messageId
      generatedAlert
      sensor {
        id
        location
        hardwareId
        unit {
          number
        }
      }
    }
  }
}
    `;

/**
 * __useFetchSensorEventsTableQuery__
 *
 * To run a query within a React component, call `useFetchSensorEventsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSensorEventsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSensorEventsTableQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      eventTimeAfter: // value for 'eventTimeAfter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchSensorEventsTableQuery(baseOptions?: Apollo.QueryHookOptions<FetchSensorEventsTableQuery, FetchSensorEventsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSensorEventsTableQuery, FetchSensorEventsTableQueryVariables>(FetchSensorEventsTableDocument, options);
      }
export function useFetchSensorEventsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSensorEventsTableQuery, FetchSensorEventsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSensorEventsTableQuery, FetchSensorEventsTableQueryVariables>(FetchSensorEventsTableDocument, options);
        }
export type FetchSensorEventsTableQueryHookResult = ReturnType<typeof useFetchSensorEventsTableQuery>;
export type FetchSensorEventsTableLazyQueryHookResult = ReturnType<typeof useFetchSensorEventsTableLazyQuery>;
export type FetchSensorEventsTableQueryResult = Apollo.QueryResult<FetchSensorEventsTableQuery, FetchSensorEventsTableQueryVariables>;