import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAverageHumidityGaugesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchAverageHumidityGaugesWidgetQuery = { __typename?: 'Query', widgetAverageHumidityGauges: number };


export const FetchAverageHumidityGaugesWidgetDocument = gql`
    query FetchAverageHumidityGaugesWidget($token: String!) {
  widgetAverageHumidityGauges(token: $token)
}
    `;

/**
 * __useFetchAverageHumidityGaugesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchAverageHumidityGaugesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAverageHumidityGaugesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAverageHumidityGaugesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchAverageHumidityGaugesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchAverageHumidityGaugesWidgetQuery, FetchAverageHumidityGaugesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAverageHumidityGaugesWidgetQuery, FetchAverageHumidityGaugesWidgetQueryVariables>(FetchAverageHumidityGaugesWidgetDocument, options);
      }
export function useFetchAverageHumidityGaugesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAverageHumidityGaugesWidgetQuery, FetchAverageHumidityGaugesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAverageHumidityGaugesWidgetQuery, FetchAverageHumidityGaugesWidgetQueryVariables>(FetchAverageHumidityGaugesWidgetDocument, options);
        }
export type FetchAverageHumidityGaugesWidgetQueryHookResult = ReturnType<typeof useFetchAverageHumidityGaugesWidgetQuery>;
export type FetchAverageHumidityGaugesWidgetLazyQueryHookResult = ReturnType<typeof useFetchAverageHumidityGaugesWidgetLazyQuery>;
export type FetchAverageHumidityGaugesWidgetQueryResult = Apollo.QueryResult<FetchAverageHumidityGaugesWidgetQuery, FetchAverageHumidityGaugesWidgetQueryVariables>;