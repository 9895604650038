import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchHumidityActivityLinesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchHumidityActivityLinesWidgetQuery = { __typename?: 'Query', widgetHumidityActivityLines: { __typename?: 'Lines', labels: Array<{ __typename?: 'LineLabel', from: unknown, to: unknown }>, datasets: Array<{ __typename?: 'LineDataset', label: string, data: Array<unknown> }> } };


export const FetchHumidityActivityLinesWidgetDocument = gql`
    query FetchHumidityActivityLinesWidget($token: String!) {
  widgetHumidityActivityLines(token: $token) {
    labels {
      from
      to
    }
    datasets {
      label
      data
    }
  }
}
    `;

/**
 * __useFetchHumidityActivityLinesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchHumidityActivityLinesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHumidityActivityLinesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHumidityActivityLinesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchHumidityActivityLinesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchHumidityActivityLinesWidgetQuery, FetchHumidityActivityLinesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchHumidityActivityLinesWidgetQuery, FetchHumidityActivityLinesWidgetQueryVariables>(FetchHumidityActivityLinesWidgetDocument, options);
      }
export function useFetchHumidityActivityLinesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchHumidityActivityLinesWidgetQuery, FetchHumidityActivityLinesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchHumidityActivityLinesWidgetQuery, FetchHumidityActivityLinesWidgetQueryVariables>(FetchHumidityActivityLinesWidgetDocument, options);
        }
export type FetchHumidityActivityLinesWidgetQueryHookResult = ReturnType<typeof useFetchHumidityActivityLinesWidgetQuery>;
export type FetchHumidityActivityLinesWidgetLazyQueryHookResult = ReturnType<typeof useFetchHumidityActivityLinesWidgetLazyQuery>;
export type FetchHumidityActivityLinesWidgetQueryResult = Apollo.QueryResult<FetchHumidityActivityLinesWidgetQuery, FetchHumidityActivityLinesWidgetQueryVariables>;