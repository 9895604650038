export const timeUnits = [
  {
    value: '1_hour',
    title: 'Last hour'
  },
  {
    value: '6_hours',
    title: 'Last 6 hours'
  },
  {
    value: '12_hours',
    title: 'Last 12 hours'
  },
  {
    value: '1_day',
    title: 'Last day'
  },
  {
    value: '3_days',
    title: 'Last 3 days'
  },
  {
    value: '7_days',
    title: 'Last week'
  },
  {
    value: '30_days',
    title: 'Last 30 days'
  },
  {
    value: '60_days',
    title: 'Last 60 days'
  },
  {
    value: '90_days',
    title: 'Last 90 days'
  },
  {
    value: '6_months',
    title: 'Last 6 months'
  },
  {
    value: '1_year',
    title: 'Last Year'
  },
  {
    value: '2_years',
    title: 'Last 2 Years'
  },
  {
    value: '3_years',
    title: 'Last 3 Years'
  }
]
