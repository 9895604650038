import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { Typography } from '@mui/material'

const OverlaySpinner = ({ open, messageLine1, messageLine2, messageLine3 }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      open={open}
    >
      <CircularProgress color='inherit' />
      {messageLine1 && (
        <Typography variant={'subtitle1'} sx={{ color: '#FFF', margin: 2 }}>
          {messageLine1}
        </Typography>
      )}
      {messageLine2 && (
        <Typography variant={'subtitle1'} sx={{ color: '#FFF', ml: 2, mr: 2 }}>
          {messageLine2}
        </Typography>
      )}
      {messageLine3 && (
        <Typography variant={'subtitle1'} sx={{ color: '#FFF', margin: 2 }}>
          {messageLine3}
        </Typography>
      )}
    </Backdrop>
  )
}

export default OverlaySpinner
