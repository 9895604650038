import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useFetchValvesQuery } from '../../pages/Valves/GraphQl/gql.generated'

const useValvesTable = ({ buildingId, page, setPage, rowsPerPage }) => {
  const [query, setQuery] = useState(null)

  useEffect(() => {
    setPage(0) // Reset page number if userType changed
  }, [query])

  const { data, loading } = useFetchValvesQuery({
    variables: {
      buildingId,
      query,
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    },
    fetchPolicy: 'network-only' // If new valve is added we want to refetch the query as the new one is not reflected in cache
  })

  const valves = get(data, ['fetchValves', 'collection'], [])
  const metadata = get(data, ['fetchValves', 'metadata'], [])

  return {
    loading,
    valves,
    metadata,
    query,
    setQuery
  }
}

export default useValvesTable
