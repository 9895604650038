import { useQueryParam, StringParam } from 'use-query-params'
import { useGetBuildingQuery } from './GraphQl/gql.generated'

const useAlertsPage = () => {
  const [buildingId] = useQueryParam('building_id', StringParam)

  const { data, loading } = useGetBuildingQuery({
    variables: { id: buildingId }
  })

  const building = data?.getBuilding
  return { building, loading }
}

export default useAlertsPage
