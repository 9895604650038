import React from 'react'
import { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogTitle, Button, Alert } from '@mui/material'

const DeleteGateway = ({ error, open, handleClose, handleDelete }) => {
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  useEffect(() => {
    if (error) {
      setErrorDialogOpen(true)
      handleClose()
    }
  }, [error])
  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to delete this gateway?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      {error && (
        <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
          <Alert severity='error'>{error.message}</Alert>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default DeleteGateway
