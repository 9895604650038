import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTemperatureActivitiesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchTemperatureActivitiesWidgetQuery = { __typename?: 'Query', widgetTemperatureActivities: { __typename?: 'Lines', labels: Array<{ __typename?: 'LineLabel', from: unknown, to: unknown }>, datasets: Array<{ __typename?: 'LineDataset', label: string, data: Array<unknown> }> } };


export const FetchTemperatureActivitiesWidgetDocument = gql`
    query FetchTemperatureActivitiesWidget($token: String!) {
  widgetTemperatureActivities(token: $token) {
    labels {
      from
      to
    }
    datasets {
      label
      data
    }
  }
}
    `;

/**
 * __useFetchTemperatureActivitiesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchTemperatureActivitiesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTemperatureActivitiesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTemperatureActivitiesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchTemperatureActivitiesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchTemperatureActivitiesWidgetQuery, FetchTemperatureActivitiesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchTemperatureActivitiesWidgetQuery, FetchTemperatureActivitiesWidgetQueryVariables>(FetchTemperatureActivitiesWidgetDocument, options);
      }
export function useFetchTemperatureActivitiesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchTemperatureActivitiesWidgetQuery, FetchTemperatureActivitiesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchTemperatureActivitiesWidgetQuery, FetchTemperatureActivitiesWidgetQueryVariables>(FetchTemperatureActivitiesWidgetDocument, options);
        }
export type FetchTemperatureActivitiesWidgetQueryHookResult = ReturnType<typeof useFetchTemperatureActivitiesWidgetQuery>;
export type FetchTemperatureActivitiesWidgetLazyQueryHookResult = ReturnType<typeof useFetchTemperatureActivitiesWidgetLazyQuery>;
export type FetchTemperatureActivitiesWidgetQueryResult = Apollo.QueryResult<FetchTemperatureActivitiesWidgetQuery, FetchTemperatureActivitiesWidgetQueryVariables>;