import { createTheme } from '@mui/material/styles'

import components from './components'

const createdTheme = createTheme({
  components,
  palette: {
    action: {
      hover: '#FAFAFA'
    },
    text: {
      secondary: '#365970'
    },
    primary: {
      main: '#365970'
    }
  }
})

export default createdTheme
