import React from 'react'
import { map, flatten, uniq, reject, isEmpty } from 'lodash'

import { SENSOR_NOT_ALERTING, SENSOR_NOT_IN_USE } from '../../../common/constants/sensors'
import { fullDateTimeFormat } from '../../../common/utils/dates'

import AlertIcon from '../../atoms/AlertIcons'

export const formatBuildingLocationValue = ({ address, city, stateProvince }) => {
  // city and stateProvince should be not null fields (DB restraints)
  if (!city || !stateProvince) return ''
  return address ? `${address}, ${city}, ${stateProvince}` : `${city}, ${stateProvince}`
}

export const formatUnitActiveAlertsValue = ({ activeAlerts = [] }) => {
  if (isEmpty(activeAlerts)) return 'None'
  const alertTypes = map(activeAlerts, 'alertType')
  return <AlertIcon alertTypes={alertTypes} reactKeyPrefix={'activeAlerts'} />
}

export const formatUnitLastAlertValue = ({ lastAlert = {} }) => {
  if (isEmpty(lastAlert)) return 'None'
  const alertCreatedAt = lastAlert.createdAt
  const alertType = lastAlert.alertType
  return (
    <>
      {fullDateTimeFormat(alertCreatedAt)} for&nbsp;
      <AlertIcon alertTypes={[alertType]} reactKeyPrefix={'lastAlert'} />
    </>
  )
}

export const formatUnitFactorsBeingMonitoredValue = ({ sensors = [] }) => {
  const monitoringTypes = uniq(flatten(map(sensors, 'monitoringTypes')))

  if (monitoringTypes == [SENSOR_NOT_ALERTING]) {
    return SENSOR_NOT_ALERTING
  }

  const activeMonitoringTypes = reject(monitoringTypes, (type) =>
    [SENSOR_NOT_ALERTING, SENSOR_NOT_IN_USE].includes(type)
  )
  return activeMonitoringTypes.join(', ')
}

export const formatBuildingAverageTemperatureValue = ({ averageTemperature }) =>
  averageTemperature ? `${averageTemperature} °C` : null
export const formatBuildingAverageHumidityValue = ({ averageHumidity }) =>
  averageHumidity ? `${averageHumidity}%` : null
