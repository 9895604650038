import React from 'react'
import { Line } from 'react-chartjs-2'
import { CircularProgress } from '@mui/material'
//src
import useRandomColour from 'common/hooks/useRandomColour'
import { humanizeLineLabels } from '../../utils'
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { nonData, WidgetNonData } from '../../WidgetNoData'
import { useFetchCo2ActivityLinesWidgetQuery } from './gql.generated'

export default function Co2ActivityLines({ token }) {
  const randomColor = useRandomColour()

  const { data, loading, error } = useFetchCo2ActivityLinesWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: { token }
  })

  const result: any = data?.widgetCo2ActivityLines || []

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />
  if (nonData(result.datasets)) return <WidgetNonData />

  return (
    <Line
      data={{
        labels: humanizeLineLabels(result.labels),
        datasets: result.datasets.map(({ label, data }, key) => ({
          label,
          data,
          borderColor: randomColor(key),
          tension: 0.1
        }))
      }}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 15,
              boxHeight: 15
            }
          }
        }
      }}
    />
  )
}
