import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchBuildingsQueryVariables = Types.Exact<{
  companyId?: Types.InputMaybe<Types.Scalars['ID']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchBuildingsQuery = { __typename?: 'Query', fetchBuildings: Array<{ __typename?: 'Building', id: string, name?: string | null, value: string }> };

export type FetchUnitsQueryVariables = Types.Exact<{
  companyId?: Types.InputMaybe<Types.Scalars['ID']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
  buildingIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchUnitsQuery = { __typename?: 'Query', fetchUnits: Array<{ __typename?: 'Unit', id: string, number?: string | null, floor: number, value: string, building?: { __typename?: 'Building', id: string, name?: string | null } | null }> };

export type FetchSensorsCollectionQueryVariables = Types.Exact<{
  companyId?: Types.InputMaybe<Types.Scalars['ID']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
  buildingIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  unitIds?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchSensorsCollectionQuery = { __typename?: 'Query', fetchSensorsCollection: { __typename?: 'SensorCollection', collection: Array<{ __typename?: 'Sensor', id: string, location?: string | null, value: string, building?: { __typename?: 'Building', id: string, name?: string | null } | null, unit?: { __typename?: 'Unit', id: string, number?: string | null, floor: number } | null }> } };

export type UpdateDashboardFiltersMutationVariables = Types.Exact<{
  input: Types.UpdateDashboardInput;
}>;


export type UpdateDashboardFiltersMutation = { __typename?: 'Mutation', updateDashboard: { __typename?: 'Dashboard', id: string, name: string, token: string, filters?: unknown | null } };


export const FetchBuildingsDocument = gql`
    query FetchBuildings($companyId: ID, $query: String, $ids: [ID!], $pagination: PaginationType) {
  fetchBuildings(
    companyId: $companyId
    ids: $ids
    query: $query
    pagination: $pagination
  ) {
    id
    value: id
    name
  }
}
    `;

/**
 * __useFetchBuildingsQuery__
 *
 * To run a query within a React component, call `useFetchBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBuildingsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      ids: // value for 'ids'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchBuildingsQuery(baseOptions?: Apollo.QueryHookOptions<FetchBuildingsQuery, FetchBuildingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchBuildingsQuery, FetchBuildingsQueryVariables>(FetchBuildingsDocument, options);
      }
export function useFetchBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchBuildingsQuery, FetchBuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchBuildingsQuery, FetchBuildingsQueryVariables>(FetchBuildingsDocument, options);
        }
export type FetchBuildingsQueryHookResult = ReturnType<typeof useFetchBuildingsQuery>;
export type FetchBuildingsLazyQueryHookResult = ReturnType<typeof useFetchBuildingsLazyQuery>;
export type FetchBuildingsQueryResult = Apollo.QueryResult<FetchBuildingsQuery, FetchBuildingsQueryVariables>;
export const FetchUnitsDocument = gql`
    query FetchUnits($companyId: ID, $query: String, $buildingIds: [ID!], $ids: [ID!], $pagination: PaginationType) {
  fetchUnits(
    companyId: $companyId
    query: $query
    buildingIds: $buildingIds
    ids: $ids
    pagination: $pagination
  ) {
    id
    value: id
    number
    floor
    building {
      id
      name
    }
  }
}
    `;

/**
 * __useFetchUnitsQuery__
 *
 * To run a query within a React component, call `useFetchUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUnitsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      buildingIds: // value for 'buildingIds'
 *      ids: // value for 'ids'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchUnitsQuery(baseOptions?: Apollo.QueryHookOptions<FetchUnitsQuery, FetchUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUnitsQuery, FetchUnitsQueryVariables>(FetchUnitsDocument, options);
      }
export function useFetchUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUnitsQuery, FetchUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUnitsQuery, FetchUnitsQueryVariables>(FetchUnitsDocument, options);
        }
export type FetchUnitsQueryHookResult = ReturnType<typeof useFetchUnitsQuery>;
export type FetchUnitsLazyQueryHookResult = ReturnType<typeof useFetchUnitsLazyQuery>;
export type FetchUnitsQueryResult = Apollo.QueryResult<FetchUnitsQuery, FetchUnitsQueryVariables>;
export const FetchSensorsCollectionDocument = gql`
    query FetchSensorsCollection($companyId: ID, $query: String, $buildingIds: [ID!], $unitIds: [ID!], $ids: [ID!], $pagination: PaginationType) {
  fetchSensorsCollection(
    companyId: $companyId
    query: $query
    buildingIds: $buildingIds
    unitIds: $unitIds
    ids: $ids
    pagination: $pagination
  ) {
    collection {
      id
      value: id
      location
      building {
        id
        name
      }
      unit {
        id
        number
        floor
      }
    }
  }
}
    `;

/**
 * __useFetchSensorsCollectionQuery__
 *
 * To run a query within a React component, call `useFetchSensorsCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSensorsCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSensorsCollectionQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      query: // value for 'query'
 *      buildingIds: // value for 'buildingIds'
 *      unitIds: // value for 'unitIds'
 *      ids: // value for 'ids'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchSensorsCollectionQuery(baseOptions?: Apollo.QueryHookOptions<FetchSensorsCollectionQuery, FetchSensorsCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSensorsCollectionQuery, FetchSensorsCollectionQueryVariables>(FetchSensorsCollectionDocument, options);
      }
export function useFetchSensorsCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSensorsCollectionQuery, FetchSensorsCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSensorsCollectionQuery, FetchSensorsCollectionQueryVariables>(FetchSensorsCollectionDocument, options);
        }
export type FetchSensorsCollectionQueryHookResult = ReturnType<typeof useFetchSensorsCollectionQuery>;
export type FetchSensorsCollectionLazyQueryHookResult = ReturnType<typeof useFetchSensorsCollectionLazyQuery>;
export type FetchSensorsCollectionQueryResult = Apollo.QueryResult<FetchSensorsCollectionQuery, FetchSensorsCollectionQueryVariables>;
export const UpdateDashboardFiltersDocument = gql`
    mutation UpdateDashboardFilters($input: UpdateDashboardInput!) {
  updateDashboard(input: $input) {
    id
    name
    token
    filters
  }
}
    `;
export type UpdateDashboardFiltersMutationFn = Apollo.MutationFunction<UpdateDashboardFiltersMutation, UpdateDashboardFiltersMutationVariables>;

/**
 * __useUpdateDashboardFiltersMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardFiltersMutation, { data, loading, error }] = useUpdateDashboardFiltersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardFiltersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDashboardFiltersMutation, UpdateDashboardFiltersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDashboardFiltersMutation, UpdateDashboardFiltersMutationVariables>(UpdateDashboardFiltersDocument, options);
      }
export type UpdateDashboardFiltersMutationHookResult = ReturnType<typeof useUpdateDashboardFiltersMutation>;
export type UpdateDashboardFiltersMutationResult = Apollo.MutationResult<UpdateDashboardFiltersMutation>;
export type UpdateDashboardFiltersMutationOptions = Apollo.BaseMutationOptions<UpdateDashboardFiltersMutation, UpdateDashboardFiltersMutationVariables>;