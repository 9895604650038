import React from 'react'
import { alpha, CircularProgress, useTheme } from '@mui/material'
import GaugeChart from 'react-gauge-chart'
// src
import { percentage } from 'common/utils/percentage'
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { WidgetNonData } from '../../WidgetNoData'
import { useFetchAverageHumidityGaugesWidgetQuery } from './gql.generated'

const maxHumidity = [0, 100]

export default function AverageHumidityGauges({ token }) {
  const { palette } = useTheme()

  const colors = [palette.grey.A400, palette.success.light, palette.error.main]

  const { data, loading, error } = useFetchAverageHumidityGaugesWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: { token }
  })

  const average = data?.widgetAverageHumidityGauges || 0

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />
  if (!average) return <WidgetNonData />

  const percent = percentage(average, maxHumidity)

  return (
    <GaugeChart
      id='average-humidity-gauges'
      textColor={palette.text.secondary}
      needleColor={alpha(palette.text.secondary, 0.5)}
      colors={colors}
      arcsLength={[0.2, 0.6, 0.2]}
      percent={percent}
    />
  )
}
