import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
  Typography,
  CircularProgress
} from '@mui/material'

import { isEmpty } from 'lodash'

import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'

import SearchBar from '../../atoms/SearchBar/SearchBar'
import ValveListRow from '../../molecules/ValveListRow/ValveListRow'

import useValvesTable from './useValvesTable'

const ValvesTable = ({ buildingId, userType, numRowsPerPage, showSearchBar = false }) => {
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage })

  const { loading, valves, metadata, query, setQuery } = useValvesTable({
    buildingId: buildingId,
    page,
    setPage,
    rowsPerPage
  })

  const numberOfColumns = 8 // Including column for options menu

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <TableContainer>
      <Grid container alignItems='center' spacing={3}>
        {showSearchBar && <SearchBar placeholder={'Search Valves'} query={query} setQuery={setQuery} />}
        <Grid item xs={8} />
      </Grid>
      <Table aria-label='collapsible table' className='dataTables_filter'>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell align='center'>Make</StyledTableCell>
            <StyledTableCell align='center'>Model</StyledTableCell>
            <StyledTableCell align='center'>Location</StyledTableCell>
            <StyledTableCell align='center'>Valve State</StyledTableCell>
            <StyledTableCell align='center'>Zone</StyledTableCell>
            <StyledTableCell align='center'>Hardware ID</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody className='semi-tight-table-row'>
          {isEmpty(valves) && (
            <TableRow hover>
              <StyledTableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={numberOfColumns}>
                {loading ? <CircularProgress /> : <Typography component={'h4'}>No Valves Found</Typography>}
              </StyledTableCell>
            </TableRow>
          )}
          {valves.map((valve) => (
            <Fragment key={valve.id}>
              <ValveListRow buildingId={buildingId} valve={valve} numberOfColumns={numberOfColumns} />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={4}
        rowsPerPageOptions={rowsPerPageOptions}
        component='div'
        count={metadata?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={isEmpty(metadata) ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default ValvesTable
