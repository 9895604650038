import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAverageCo2GaugesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchAverageCo2GaugesWidgetQuery = { __typename?: 'Query', widgetAverageCo2Gauges: number };


export const FetchAverageCo2GaugesWidgetDocument = gql`
    query FetchAverageCo2GaugesWidget($token: String!) {
  widgetAverageCo2Gauges(token: $token)
}
    `;

/**
 * __useFetchAverageCo2GaugesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchAverageCo2GaugesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAverageCo2GaugesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAverageCo2GaugesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchAverageCo2GaugesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchAverageCo2GaugesWidgetQuery, FetchAverageCo2GaugesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAverageCo2GaugesWidgetQuery, FetchAverageCo2GaugesWidgetQueryVariables>(FetchAverageCo2GaugesWidgetDocument, options);
      }
export function useFetchAverageCo2GaugesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAverageCo2GaugesWidgetQuery, FetchAverageCo2GaugesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAverageCo2GaugesWidgetQuery, FetchAverageCo2GaugesWidgetQueryVariables>(FetchAverageCo2GaugesWidgetDocument, options);
        }
export type FetchAverageCo2GaugesWidgetQueryHookResult = ReturnType<typeof useFetchAverageCo2GaugesWidgetQuery>;
export type FetchAverageCo2GaugesWidgetLazyQueryHookResult = ReturnType<typeof useFetchAverageCo2GaugesWidgetLazyQuery>;
export type FetchAverageCo2GaugesWidgetQueryResult = Apollo.QueryResult<FetchAverageCo2GaugesWidgetQuery, FetchAverageCo2GaugesWidgetQueryVariables>;