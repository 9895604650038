import React from 'react'
import ValveForm from '../../../molecules/ValveForm'
import { useParams } from 'react-router-dom'
import { useGetBuildingQuery } from '../../GatewaysPage/GraphQl/gql.generated'
import { useGetValveQuery } from '../GraphQl/gql.generated'

const CreateValvePage = () => {
  const { buildingId, valveId } = useParams()
  const { data: buildingData, loading: buildingLoading } = useGetBuildingQuery({
    variables: { id: buildingId }
  })

  let valveData, valveLoading
  if (valveId !== 'new') {
    ;({ data: valveData, loading: valveLoading } = useGetValveQuery({
      variables: { id: parseInt(valveId) }
    }))
  }

  const building = buildingData?.getBuilding
  const valve = valveLoading ? null : valveData?.getValve
  return !(buildingLoading || valveLoading) && <ValveForm building={building} valve={valve} />
}
export default CreateValvePage
