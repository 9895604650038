import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchValveUnlinkedSensorsQueryVariables = Types.Exact<{
  valveId: Types.Scalars['ID'];
  buildingId: Types.Scalars['ID'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchValveUnlinkedSensorsQuery = { __typename?: 'Query', fetchValveUnlinkedSensors: { __typename?: 'SensorCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Sensor', id: string, name?: string | null, location?: string | null, building?: { __typename?: 'Building', name?: string | null } | null, unit?: { __typename?: 'Unit', number?: string | null, floor: number } | null }> } };

export type LinkValveSensorsMutationVariables = Types.Exact<{
  input: Types.LinkValveSensorsInput;
}>;


export type LinkValveSensorsMutation = { __typename?: 'Mutation', linkValveSensors: { __typename?: 'Valve', id: string, buildingId: string, hardwareId: string, location: string, make: string, model: string, unit: { __typename?: 'Unit', id: string, number?: string | null, floor: number } } };

export type UnlinkValveSensorMutationVariables = Types.Exact<{
  input: Types.UnlinkValveSensorInput;
}>;


export type UnlinkValveSensorMutation = { __typename?: 'Mutation', unlinkValveSensor: { __typename?: 'Valve', id: string, buildingId: string, hardwareId: string, location: string, make: string, model: string, unit: { __typename?: 'Unit', id: string, number?: string | null, floor: number } } };


export const FetchValveUnlinkedSensorsDocument = gql`
    query FetchValveUnlinkedSensors($valveId: ID!, $buildingId: ID!, $pagination: PaginationType) {
  fetchValveUnlinkedSensors(
    valveId: $valveId
    buildingId: $buildingId
    pagination: $pagination
  ) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      id
      name
      location
      building {
        name
      }
      unit {
        number
        floor
      }
    }
  }
}
    `;

/**
 * __useFetchValveUnlinkedSensorsQuery__
 *
 * To run a query within a React component, call `useFetchValveUnlinkedSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchValveUnlinkedSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchValveUnlinkedSensorsQuery({
 *   variables: {
 *      valveId: // value for 'valveId'
 *      buildingId: // value for 'buildingId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchValveUnlinkedSensorsQuery(baseOptions: Apollo.QueryHookOptions<FetchValveUnlinkedSensorsQuery, FetchValveUnlinkedSensorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchValveUnlinkedSensorsQuery, FetchValveUnlinkedSensorsQueryVariables>(FetchValveUnlinkedSensorsDocument, options);
      }
export function useFetchValveUnlinkedSensorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchValveUnlinkedSensorsQuery, FetchValveUnlinkedSensorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchValveUnlinkedSensorsQuery, FetchValveUnlinkedSensorsQueryVariables>(FetchValveUnlinkedSensorsDocument, options);
        }
export type FetchValveUnlinkedSensorsQueryHookResult = ReturnType<typeof useFetchValveUnlinkedSensorsQuery>;
export type FetchValveUnlinkedSensorsLazyQueryHookResult = ReturnType<typeof useFetchValveUnlinkedSensorsLazyQuery>;
export type FetchValveUnlinkedSensorsQueryResult = Apollo.QueryResult<FetchValveUnlinkedSensorsQuery, FetchValveUnlinkedSensorsQueryVariables>;
export const LinkValveSensorsDocument = gql`
    mutation LinkValveSensors($input: LinkValveSensorsInput!) {
  linkValveSensors(input: $input) {
    id
    buildingId
    hardwareId
    location
    make
    model
    unit {
      id
      number
      floor
    }
  }
}
    `;
export type LinkValveSensorsMutationFn = Apollo.MutationFunction<LinkValveSensorsMutation, LinkValveSensorsMutationVariables>;

/**
 * __useLinkValveSensorsMutation__
 *
 * To run a mutation, you first call `useLinkValveSensorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkValveSensorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkValveSensorsMutation, { data, loading, error }] = useLinkValveSensorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkValveSensorsMutation(baseOptions?: Apollo.MutationHookOptions<LinkValveSensorsMutation, LinkValveSensorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkValveSensorsMutation, LinkValveSensorsMutationVariables>(LinkValveSensorsDocument, options);
      }
export type LinkValveSensorsMutationHookResult = ReturnType<typeof useLinkValveSensorsMutation>;
export type LinkValveSensorsMutationResult = Apollo.MutationResult<LinkValveSensorsMutation>;
export type LinkValveSensorsMutationOptions = Apollo.BaseMutationOptions<LinkValveSensorsMutation, LinkValveSensorsMutationVariables>;
export const UnlinkValveSensorDocument = gql`
    mutation UnlinkValveSensor($input: UnlinkValveSensorInput!) {
  unlinkValveSensor(input: $input) {
    id
    buildingId
    hardwareId
    location
    make
    model
    unit {
      id
      number
      floor
    }
  }
}
    `;
export type UnlinkValveSensorMutationFn = Apollo.MutationFunction<UnlinkValveSensorMutation, UnlinkValveSensorMutationVariables>;

/**
 * __useUnlinkValveSensorMutation__
 *
 * To run a mutation, you first call `useUnlinkValveSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkValveSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkValveSensorMutation, { data, loading, error }] = useUnlinkValveSensorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnlinkValveSensorMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkValveSensorMutation, UnlinkValveSensorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkValveSensorMutation, UnlinkValveSensorMutationVariables>(UnlinkValveSensorDocument, options);
      }
export type UnlinkValveSensorMutationHookResult = ReturnType<typeof useUnlinkValveSensorMutation>;
export type UnlinkValveSensorMutationResult = Apollo.MutationResult<UnlinkValveSensorMutation>;
export type UnlinkValveSensorMutationOptions = Apollo.BaseMutationOptions<UnlinkValveSensorMutation, UnlinkValveSensorMutationVariables>;