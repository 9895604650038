import React from 'react'
import { alpha, CircularProgress, useTheme } from '@mui/material'
import GaugeChart from 'react-gauge-chart'
// src
import { percentage } from 'common/utils/percentage'
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { WidgetNonData } from '../../WidgetNoData'
import { useFetchAverageTemperatureGaugesWidgetQuery } from './gql.generated'

const minMaxTemperatureRange = [-40, 60]

export default function AverageTemperatureGauges({ token }) {
  const { palette } = useTheme()

  const colors = [palette.info.light, palette.success.light, palette.error.main]
  const { data, loading, error } = useFetchAverageTemperatureGaugesWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: { token }
  })

  const average = data?.widgetAverageTemperatureGauges

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />
  if (average === null) return <WidgetNonData />

  const percent = percentage(average, minMaxTemperatureRange)

  return (
    <GaugeChart
      id='average-temperature-gauges'
      textColor={palette.text.secondary}
      needleColor={alpha(palette.text.secondary, 0.5)}
      percent={percent}
      formatTextValue={() => `${average}℃`}
      arcsLength={[0.4, 0.4, 0.2]}
      colors={colors}
    />
  )
}
