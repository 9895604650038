import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchGatewaysTableQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type FetchGatewaysTableQuery = { __typename?: 'Query', fetchGateways: { __typename?: 'GatewayCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Gateway', id: string, make?: string | null, model?: string | null, location?: string | null, inUse: boolean, serialNumber?: string | null, hardwareId?: string | null, buildingId: string, projectId?: string | null }> } };

export type FetchProjectListQueryVariables = Types.Exact<{
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type FetchProjectListQuery = { __typename?: 'Query', fetchProjects: { __typename?: 'ProjectCollection', collection: Array<{ __typename?: 'Project', id: string, name: string }> } };

export type CreateGatewayMutationVariables = Types.Exact<{
  input: Types.CreateGatewayInput;
}>;


export type CreateGatewayMutation = { __typename?: 'Mutation', createGateway: { __typename?: 'Gateway', id: string, buildingId: string, hardwareId?: string | null, location?: string | null, serialNumber?: string | null, make?: string | null, model?: string | null, inUse: boolean, projectId?: string | null } };

export type UpdateGatewayMutationVariables = Types.Exact<{
  input: Types.UpdateGatewayInput;
}>;


export type UpdateGatewayMutation = { __typename?: 'Mutation', updateGateway: { __typename?: 'Gateway', id: string, buildingId: string, hardwareId?: string | null, location?: string | null, serialNumber?: string | null, make?: string | null, model?: string | null, inUse: boolean, projectId?: string | null } };

export type DeleteGatewayMutationVariables = Types.Exact<{
  input: Types.DeleteGatewayInput;
}>;


export type DeleteGatewayMutation = { __typename?: 'Mutation', deleteGateway: { __typename?: 'Gateway', id: string, buildingId: string, hardwareId?: string | null, location?: string | null, serialNumber?: string | null, make?: string | null, model?: string | null, inUse: boolean, projectId?: string | null } };


export const FetchGatewaysTableDocument = gql`
    query FetchGatewaysTable($query: String, $pagination: PaginationType, $buildingId: ID) {
  fetchGateways(query: $query, pagination: $pagination, buildingId: $buildingId) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      id
      make
      model
      location
      inUse
      serialNumber
      hardwareId
      buildingId
      projectId
    }
  }
}
    `;

/**
 * __useFetchGatewaysTableQuery__
 *
 * To run a query within a React component, call `useFetchGatewaysTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGatewaysTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGatewaysTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useFetchGatewaysTableQuery(baseOptions?: Apollo.QueryHookOptions<FetchGatewaysTableQuery, FetchGatewaysTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchGatewaysTableQuery, FetchGatewaysTableQueryVariables>(FetchGatewaysTableDocument, options);
      }
export function useFetchGatewaysTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchGatewaysTableQuery, FetchGatewaysTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchGatewaysTableQuery, FetchGatewaysTableQueryVariables>(FetchGatewaysTableDocument, options);
        }
export type FetchGatewaysTableQueryHookResult = ReturnType<typeof useFetchGatewaysTableQuery>;
export type FetchGatewaysTableLazyQueryHookResult = ReturnType<typeof useFetchGatewaysTableLazyQuery>;
export type FetchGatewaysTableQueryResult = Apollo.QueryResult<FetchGatewaysTableQuery, FetchGatewaysTableQueryVariables>;
export const FetchProjectListDocument = gql`
    query FetchProjectList($buildingId: ID) {
  fetchProjects(buildingId: $buildingId) {
    collection {
      id
      name
    }
  }
}
    `;

/**
 * __useFetchProjectListQuery__
 *
 * To run a query within a React component, call `useFetchProjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectListQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useFetchProjectListQuery(baseOptions?: Apollo.QueryHookOptions<FetchProjectListQuery, FetchProjectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchProjectListQuery, FetchProjectListQueryVariables>(FetchProjectListDocument, options);
      }
export function useFetchProjectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchProjectListQuery, FetchProjectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchProjectListQuery, FetchProjectListQueryVariables>(FetchProjectListDocument, options);
        }
export type FetchProjectListQueryHookResult = ReturnType<typeof useFetchProjectListQuery>;
export type FetchProjectListLazyQueryHookResult = ReturnType<typeof useFetchProjectListLazyQuery>;
export type FetchProjectListQueryResult = Apollo.QueryResult<FetchProjectListQuery, FetchProjectListQueryVariables>;
export const CreateGatewayDocument = gql`
    mutation CreateGateway($input: CreateGatewayInput!) {
  createGateway(input: $input) {
    id
    buildingId
    hardwareId
    location
    serialNumber
    make
    model
    inUse
    projectId
  }
}
    `;
export type CreateGatewayMutationFn = Apollo.MutationFunction<CreateGatewayMutation, CreateGatewayMutationVariables>;

/**
 * __useCreateGatewayMutation__
 *
 * To run a mutation, you first call `useCreateGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGatewayMutation, { data, loading, error }] = useCreateGatewayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGatewayMutation(baseOptions?: Apollo.MutationHookOptions<CreateGatewayMutation, CreateGatewayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGatewayMutation, CreateGatewayMutationVariables>(CreateGatewayDocument, options);
      }
export type CreateGatewayMutationHookResult = ReturnType<typeof useCreateGatewayMutation>;
export type CreateGatewayMutationResult = Apollo.MutationResult<CreateGatewayMutation>;
export type CreateGatewayMutationOptions = Apollo.BaseMutationOptions<CreateGatewayMutation, CreateGatewayMutationVariables>;
export const UpdateGatewayDocument = gql`
    mutation UpdateGateway($input: UpdateGatewayInput!) {
  updateGateway(input: $input) {
    id
    buildingId
    hardwareId
    location
    serialNumber
    make
    model
    inUse
    projectId
  }
}
    `;
export type UpdateGatewayMutationFn = Apollo.MutationFunction<UpdateGatewayMutation, UpdateGatewayMutationVariables>;

/**
 * __useUpdateGatewayMutation__
 *
 * To run a mutation, you first call `useUpdateGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGatewayMutation, { data, loading, error }] = useUpdateGatewayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGatewayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGatewayMutation, UpdateGatewayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGatewayMutation, UpdateGatewayMutationVariables>(UpdateGatewayDocument, options);
      }
export type UpdateGatewayMutationHookResult = ReturnType<typeof useUpdateGatewayMutation>;
export type UpdateGatewayMutationResult = Apollo.MutationResult<UpdateGatewayMutation>;
export type UpdateGatewayMutationOptions = Apollo.BaseMutationOptions<UpdateGatewayMutation, UpdateGatewayMutationVariables>;
export const DeleteGatewayDocument = gql`
    mutation DeleteGateway($input: DeleteGatewayInput!) {
  deleteGateway(input: $input) {
    id
    buildingId
    hardwareId
    location
    serialNumber
    make
    model
    inUse
    projectId
  }
}
    `;
export type DeleteGatewayMutationFn = Apollo.MutationFunction<DeleteGatewayMutation, DeleteGatewayMutationVariables>;

/**
 * __useDeleteGatewayMutation__
 *
 * To run a mutation, you first call `useDeleteGatewayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGatewayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGatewayMutation, { data, loading, error }] = useDeleteGatewayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteGatewayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGatewayMutation, DeleteGatewayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGatewayMutation, DeleteGatewayMutationVariables>(DeleteGatewayDocument, options);
      }
export type DeleteGatewayMutationHookResult = ReturnType<typeof useDeleteGatewayMutation>;
export type DeleteGatewayMutationResult = Apollo.MutationResult<DeleteGatewayMutation>;
export type DeleteGatewayMutationOptions = Apollo.BaseMutationOptions<DeleteGatewayMutation, DeleteGatewayMutationVariables>;