import { ApolloClient, InMemoryCache, HttpLink, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
  uri: '/graphql'
})

const headersLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      timezone
    }
  }
})

export default new ApolloClient({
  // link: new HttpLink({ uri: "/graphql" }),
  link: headersLink.concat(httpLink),
  cache: new InMemoryCache()
})
