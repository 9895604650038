import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  TableSortLabel
} from '@mui/material'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import SearchBar from '../../atoms/SearchBar/SearchBar'
import { useEffect, useState } from 'react'
import { get, orderBy } from 'lodash'
import {
  useFetchPropertyManagersQuery,
  useUpdateUserBuildingMutation,
  useDeleteUserBuildingMutation
} from './GraphQl/gql.generated'
import PropertyManagerListRow from '../../molecules/PropertyManagerListRow/PropertyManagerListRow'
import { TYPE_PROPERTY_ADMIN, TYPE_SUPER_ADMIN } from '../../../common/constants/users'

const PropertyManagersTable = ({ building, currentUser }) => {
  const [query, setQuery] = useState(null) // Search query. Clients can search for property managers by name or last name

  const [propertyManagers, setPropertyManagers] = useState([]) // Will be used to populate rows of the table

  const [order, setOrder] = React.useState('asc') // Used to define whether we sort by ascending or descending order

  const buildingId = building.id

  // propertyManagers must always be sorted, so we use this function to ensure it
  const setSortedPropertyManagers = (propertyManagers, ord) => {
    setPropertyManagers(orderBy(propertyManagers, ['priority'], [ord ? ord : order]))
  }

  // Fetch all user_building records related to this property
  const { data, loading } = useFetchPropertyManagersQuery({
    variables: {
      buildingId,
      query
    }
  })

  // Mutation to update the priority of a PropertyManager
  const [updateUserBuilding] = useUpdateUserBuildingMutation()
  async function updatePriority(propertyManager, newPriority) {
    updateUserBuilding({
      variables: {
        input: {
          id: propertyManager.id,
          priority: newPriority
        }
      },
      onCompleted: (data) => {
        setSortedPropertyManagers(
          propertyManagers.map((pM) =>
            propertyManager.id === pM.id ? { ...propertyManager, priority: newPriority } : pM
          )
        )
      }
    })
  }

  // Mutation to delete a PropertyManager
  const [deleteUserBuilding] = useDeleteUserBuildingMutation()
  async function deletePropertyManager(id) {
    deleteUserBuilding({
      variables: {
        input: {
          id: id
        }
      },
      onCompleted: (data) => {
        setSortedPropertyManagers(propertyManagers.filter((pM) => id !== pM.id))
      }
    })
  }

  // These functions are based on the Sorted Table section of the MUI Table documentation
  // They allow us to sort PropertyManagers by priority if the arrow to sort is clicked
  const handleRequestSort = (event) => {
    let ord = order === 'asc' ? 'desc' : 'asc'
    setOrder(ord)
    setSortedPropertyManagers(propertyManagers, ord)
  }
  const createSortHandler = (event) => {
    handleRequestSort(event)
  }

  // Redirect to the page to a add a new property manager
  const handleClick = () => {
    window.location.href = `/admin/users/new?manager_admin_context=true`
  }

  useEffect(() => {
    setSortedPropertyManagers(get(data, ['fetchPropertyManagers', 'collection'], []))
  }, [query, data])

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <TableContainer sx={{}}>
      <Grid container alignItems='center' spacing={5}>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
          <SearchBar placeholder={'Search'} query={query} setQuery={setQuery} />
        </Grid>
        {/* Add Property Manager Button. Still working on aligning it to the right */}
        {/* <Grid item xs={5} sm={5} md={5} lg={5} xl={5} />
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            {(currentUser.type === TYPE_PROPERTY_ADMIN || currentUser.type === TYPE_SUPER_ADMIN) &&
              <Button variant='contained' onClick={handleClick}>
                Add Property Manager
              </Button>
            }
          </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} />
      </Grid>
      <Table aria-label='PropertyManagers-table' size='small' sx={{}}>
        <TableHead>
          <TableRow>
            <StyledTableCell>First Name</StyledTableCell>
            <StyledTableCell>Last Name</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Phone Number</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell sortDirection={order}>
              Alert Priority
              <TableSortLabel active={true} direction={order} onClick={createSortHandler} />
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {propertyManagers.map((propertyManager) => (
            <Fragment key={propertyManager.id}>
              <PropertyManagerListRow
                currentUser={currentUser}
                propertyManager={propertyManager}
                updatePriority={updatePriority}
                deletePropertyManager={deletePropertyManager}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PropertyManagersTable
