import React from 'react'
import TableCell from '@mui/material/TableCell'

import { fullDateTimeFormat } from '../../../common/utils/dates'
import { getNumberRounded } from '../../../common/utils/numbers'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'

import CheckmarkCell from '../../atoms/Cells/CheckmarkCell'
import SensorEventBatteryPercentageCell from '../../atoms/Cells/BatteryPercentageCell'
import MenuDropdown from '../MenuDropdown'

import useSensorListRow from './useSensorListRow'
import { MenuItem } from '@mui/material'

const SensorListRow = ({ sensor, availableDataColumns, isOccupant, displayColumns, menuOptions }) => {
  const { handleViewSensorMenuItemClick } = useSensorListRow()

  const sensorRecentEvent = sensor.sensorRecentEvent || {}

  return (
    <StyledTableRow hover>
      {displayColumns.includes('deviceName') && <TableCell align='left'>{sensor.deviceName}</TableCell>}
      {displayColumns.includes('location') && <TableCell align='left'>{sensor.location}</TableCell>}
      {displayColumns.includes('unit') && <TableCell align='left'>{sensor.unit.number}</TableCell>}
      {displayColumns.includes('monitoringFor') && (
        <TableCell
          className={sensor.monitoringTypesDisplay.match('Sensor not') ? 'warning-text' : ''}
          align='left'
        >
          {sensor.monitoringTypesDisplay}
        </TableCell>
      )}
      {displayColumns.includes('lastSeen') && (
        <TableCell align='left'>{fullDateTimeFormat(sensorRecentEvent.updatedAt)}</TableCell>
      )}
      {displayColumns.includes('temperature') && availableDataColumns.temperature && (
        <TableCell align='left'>{sensorRecentEvent.temperature}</TableCell>
      )}
      {displayColumns.includes('humidity') && availableDataColumns.humidity && (
        <TableCell align='left'>{sensorRecentEvent.humidity}</TableCell>
      )}
      {displayColumns.includes('co2') && availableDataColumns.co2 && (
        <TableCell align='left'>{sensorRecentEvent.co2}</TableCell>
      )}
      {displayColumns.includes('pressure') && availableDataColumns.pressure && (
        <TableCell align='left'>{getNumberRounded(sensorRecentEvent.pressure)}</TableCell>
      )}
      {displayColumns.includes('lightLevel') && availableDataColumns.lightLevel && (
        <TableCell align='left'>{sensorRecentEvent.lightLevel}</TableCell>
      )}
      {displayColumns.includes('tvocMgM3') && availableDataColumns.tvocMgM3 && (
        <TableCell align='left'>{sensorRecentEvent.tvocMgM3}</TableCell>
      )}
      {displayColumns.includes('pm10') && availableDataColumns.pm10 && (
        <TableCell align='left'>{sensorRecentEvent.pm10}</TableCell>
      )}
      {displayColumns.includes('pm25') && availableDataColumns.pm25 && (
        <TableCell align='left'>{sensorRecentEvent.pm25}</TableCell>
      )}
      {displayColumns.includes('isMuted') && availableDataColumns.isMuted && (
        <TableCell align='left'>{fullDateTimeFormat(sensor.muteUntil)}</TableCell>
      )}
      {displayColumns.includes('battery') && (
        <SensorEventBatteryPercentageCell sensorEvent={sensorRecentEvent} />
      )}
      {displayColumns.includes('inUse') && !isOccupant && <CheckmarkCell booleanValue={sensor.inUse} />}
      {displayColumns.includes('hardwareId') && !isOccupant && (
        <TableCell align='left'>{sensor.hardwareId}</TableCell>
      )}
      {displayColumns.includes('optionsMenu') && (
        <TableCell align='right' style={{ paddingBottom: 0, paddingTop: 0 }}>
          <MenuDropdown>
            {Object.keys(menuOptions).includes('viewSensor') && (
              <MenuItem onClick={() => handleViewSensorMenuItemClick(sensor)}>View Sensor</MenuItem>
            )}
            {Object.keys(menuOptions).map(
              (key) =>
                key != 'viewSensor' && (
                  <MenuItem onClick={() => menuOptions[key].onClick(sensor, ...menuOptions[key].onClickArgs)}>
                    {menuOptions[key].optionText}
                  </MenuItem>
                )
            )}
          </MenuDropdown>
        </TableCell>
      )}
    </StyledTableRow>
  )
}

export default SensorListRow
