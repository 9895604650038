import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Chart as chartJS, registerables } from 'chart.js'

// src
import apolloClient from 'common/clients/apollo'
import createdTheme from 'common/theme'
import Router from './Router'

chartJS.register(...registerables)

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <ThemeProvider theme={createdTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router />
            </LocalizationProvider>
          </ThemeProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
