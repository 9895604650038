import React from 'react'
import { pull, isEmpty, concat } from 'lodash'
import { Typography } from '@mui/material'

function nonData(result) {
  let array = []
  result.map(({ data }) => {
    array = concat(array, data)
  })
  const clearArray = pull(array, '0')
  return isEmpty(clearArray)
}

function WidgetNonData() {
  return <Typography variant='h6'>No events in the specified period</Typography>
}

export { WidgetNonData, nonData }
