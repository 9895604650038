import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material'

const PopupMessage = ({ popupName, message }) => {
  const [open, setOpen] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(false)

  useEffect(() => {
    const showPopup = localStorage.getItem(popupName)
    if (showPopup !== 'false') {
      setOpen(true)
    }
  }, [])

  const handleClose = () => {
    if (dontShowAgain) {
      localStorage.setItem(popupName, 'false')
    }
    setOpen(false)
  }

  const handleCheckboxChange = (event) => {
    setDontShowAgain(event.target.checked)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Notice</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <FormControlLabel
          sx={{ pt: 2 }}
          control={<Checkbox checked={dontShowAgain} onChange={handleCheckboxChange} />}
          label="Don't show me this again"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant='contained'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PopupMessage
