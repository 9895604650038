import React from 'react'

interface Props {
  buildingName: string
  waterEmergencyResponse: string
  PropMgmtData: any[]
}

const WaterTempResponse = ({ buildingName, waterEmergencyResponse, PropMgmtData }: Props) => {
  return (
    <>
      {PropMgmtData.length > 0 && (
        <>
          <p className='padding-container'>
            If you are the unit occupant and require assistance with flooding in your residence or building,
            emergency contact information is below. Note that if you have not acknowledged any alerts you may
            received, your property management or plumbing company will receive a notice automatically.
          </p>
          <h4 className='center-text'>List of Unit Occupants</h4>
          <table className='occupant-table-container'>
            <thead>
              <tr>
                <th className='ack-th'>Name</th>
                <th className='ack-th'>Phone</th>
                <th className='ack-th'>Email</th>
              </tr>
            </thead>
            <tbody>
              {PropMgmtData.map((occupant, index) => (
                <tr key={index}>
                  <td className='ack-td'>{occupant.name}</td>
                  <td className='ack-td'>{occupant.phone}</td>
                  <td className='ack-td'>{occupant.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <table style={{ marginTop: '-10px' }} className='padding-container'>
        <tbody>
          <tr>
            <td dangerouslySetInnerHTML={{ __html: waterEmergencyResponse }}></td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default WaterTempResponse
