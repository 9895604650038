import React from 'react'
import { CircularProgress } from '@mui/material'
import { PolarArea } from 'react-chartjs-2'
import { map } from 'lodash'

// src
import { colors } from 'common/hooks/useRandomColour'
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { nonData, WidgetNonData } from '../../WidgetNoData'
import { useFetchDoorActivityBarsWidgetQuery } from './gql.generated'

export default function DoorActivityPolars({ token }) {
  const { data, loading, error } = useFetchDoorActivityBarsWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: { token }
  })

  const result = data?.widgetDoorActivityBars

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />
  if (nonData(result.datasets)) return <WidgetNonData />

  return (
    <PolarArea
      data={{
        labels: map(result.datasets, 'label'),
        datasets: [
          {
            data: map(result.datasets, 'data'),
            backgroundColor: colors
          }
        ]
      }}
      options={{
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 15,
              boxHeight: 15
            }
          }
        }
      }}
    />
  )
}
