import { useState } from 'react'

import {
  DEFAULT_NUMBER_OF_ROWS_PER_PAGE,
  DEFAULT_INITIAL_PAGE,
  DEFAULT_NUMBER_OF_ROWS_PER_PAGE_OPTIONS
} from '../constants/pagination'

const usePagination = ({
  initialPage = DEFAULT_INITIAL_PAGE,
  numRowsPerPage = DEFAULT_NUMBER_OF_ROWS_PER_PAGE,
  rowsPerPageOptions = DEFAULT_NUMBER_OF_ROWS_PER_PAGE_OPTIONS
}) => {
  const [page, setPage] = useState(initialPage)
  const [rowsPerPage, setRowsPerPage] = useState(numRowsPerPage)

  const handleChangePage = (event, newPage) => setPage(newPage)

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(initialPage)
  }

  return {
    page,
    setPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPageOptions
  }
}

export default usePagination
