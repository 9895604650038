import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchCleaningRequestLinesWidgetsQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchCleaningRequestLinesWidgetsQuery = { __typename?: 'Query', widgetCleaningRequestLines: { __typename?: 'Lines', datasets: Array<{ __typename?: 'LineDataset', label: string, data: Array<unknown> }>, labels: Array<{ __typename?: 'LineLabel', to: unknown, from: unknown }> } };


export const FetchCleaningRequestLinesWidgetsDocument = gql`
    query FetchCleaningRequestLinesWidgets($token: String!) {
  widgetCleaningRequestLines(token: $token) {
    datasets {
      label
      data
    }
    labels {
      to
      from
    }
  }
}
    `;

/**
 * __useFetchCleaningRequestLinesWidgetsQuery__
 *
 * To run a query within a React component, call `useFetchCleaningRequestLinesWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCleaningRequestLinesWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCleaningRequestLinesWidgetsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchCleaningRequestLinesWidgetsQuery(baseOptions: Apollo.QueryHookOptions<FetchCleaningRequestLinesWidgetsQuery, FetchCleaningRequestLinesWidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCleaningRequestLinesWidgetsQuery, FetchCleaningRequestLinesWidgetsQueryVariables>(FetchCleaningRequestLinesWidgetsDocument, options);
      }
export function useFetchCleaningRequestLinesWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCleaningRequestLinesWidgetsQuery, FetchCleaningRequestLinesWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCleaningRequestLinesWidgetsQuery, FetchCleaningRequestLinesWidgetsQueryVariables>(FetchCleaningRequestLinesWidgetsDocument, options);
        }
export type FetchCleaningRequestLinesWidgetsQueryHookResult = ReturnType<typeof useFetchCleaningRequestLinesWidgetsQuery>;
export type FetchCleaningRequestLinesWidgetsLazyQueryHookResult = ReturnType<typeof useFetchCleaningRequestLinesWidgetsLazyQuery>;
export type FetchCleaningRequestLinesWidgetsQueryResult = Apollo.QueryResult<FetchCleaningRequestLinesWidgetsQuery, FetchCleaningRequestLinesWidgetsQueryVariables>;