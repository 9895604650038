import React from 'react'
import { Grid, TextField, InputAdornment, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import useSearchBar from './useSearchBar'

const SearchBar = ({ id = 'search-bar', placeholder = 'Search', minWidth = '270px', query, setQuery }) => {
  const { stateQuery, handleQueryChange, onSearchSubmit, handleSetQuery } = useSearchBar({ query, setQuery })

  return (
    <Grid item xs={2} style={{ minWidth }}>
      <TextField
        id={id}
        placeholder={placeholder}
        variant='standard'
        autoFocus
        fullWidth
        value={stateQuery}
        onChange={handleQueryChange}
        onKeyDown={onSearchSubmit}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton aria-label='search' onClick={handleSetQuery}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Grid>
  )
}

export default SearchBar
