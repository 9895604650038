import React, { Fragment, useState } from 'react'
import { useFetchSoundChartDataQuery } from '../GraphQl/gql.generated'
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import { Line } from 'react-chartjs-2'
import { humanizeLineLabels } from '../../../pages/dashboards/components/WidgetCard/utils'
import { useTheme } from '@mui/material/styles'
import { SOUND_LEVEL_LABEL } from '../../../../common/constants/reports'
import { fetchCriteria } from '../../../../common/utils/reports'

const SoundChartsCard = ({ filterCriteria, reportToken }) => {
  const theme = useTheme()

  const chartTypes = {
    spl_max_chart: SOUND_LEVEL_LABEL
  }
  const defaultChartDataset = {}
  Object.keys(chartTypes).forEach((value) => (defaultChartDataset[value] = { labels: [], datasets: [] }))

  const [chartResult, setChartResult] = useState(defaultChartDataset)
  const [chartEmpty, setChartEmpty] = useState(true)

  const { data: resultChartData, loading: chartsLoading } = useFetchSoundChartDataQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken
    },
    onCompleted: (data) => {
      const result = data.fetchSoundChartData
      setChartResult(data.fetchSoundChartData)
      for (let chartType of Object.keys(chartTypes)) {
        for (let { label, data: dataArray } of result[chartType].datasets) {
          if (dataArray.length) {
            setChartEmpty(false)
          }
        }
      }
    }
  })

  return (
    <>
      {chartsLoading && (
        <Paper sx={{ mt: 2, p: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '20vh'
            }}
          >
            <Typography variant='h5' component='h5'>
              Loading Charts...
            </Typography>
            <CircularProgress />
          </Box>
        </Paper>
      )}
      {!chartsLoading &&
        Object.keys(chartTypes).map((chartType) => (
          <Fragment key={chartType}>
            <Paper sx={{ mt: 2, p: 4 }}>
              <h5 align={'center'} style={{ paddingBottom: 10 }}>
                {chartTypes[chartType]}
              </h5>
              {!chartEmpty && (
                <Line
                  style={{ maxHeight: 600, paddingBottom: 60 }}
                  data={{
                    labels: humanizeLineLabels(chartResult[chartType].labels),
                    datasets: chartResult[chartType].datasets.map(({ label, data }, key) => ({
                      label,
                      data, // borderColor: randomColor(key),
                      tension: 0.1
                    }))
                  }}
                  options={{
                    plugins: {
                      legend: {
                        display: true,
                        labels: {
                          boxWidth: 15,
                          boxHeight: 15,
                          font: {
                            size: 14
                          }
                        }
                      }
                    }
                  }}
                />
              )}
              {chartEmpty && (
                <Typography component={'h3'} sx={{ textAlign: 'center' }}>
                  No Sensor Events Recorded
                </Typography>
              )}
            </Paper>
          </Fragment>
        ))}
    </>
  )
}

export default SoundChartsCard
