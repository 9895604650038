import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  MenuItem
} from '@mui/material'

import useCreateEditGateway from '../EditGateway/useCreateEditGateway'

const CreateGateway = ({ buildingId, userType }) => {
  const {
    control,
    handleSubmit,
    errors,
    open,
    projects,
    loading,
    onSubmit,
    handleClickOpen,
    handleClose,
    isSuperadmin
  } = useCreateEditGateway({
    buildingId: buildingId,
    context: 'create',
    userType: userType
  })
  if (loading) return <React.Fragment></React.Fragment>
  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h5'> Gateways List </Typography>
        {isSuperadmin && (
          <Button variant='contained' onClick={handleClickOpen}>
            Create Gateway
          </Button>
        )}
      </Box>

      <Dialog
        open={open}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        aria-describedby='form-dialog-description'
      >
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
          <DialogTitle>Create Gateway</DialogTitle>
          <DialogContent>
            <Controller
              name='make'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Gateway Name'
                  type='text'
                  fullWidth
                  error={!!errors.make}
                  helperText={errors.make?.message}
                />
              )}
            />
            <Controller
              name='model'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Model'
                  type='text'
                  fullWidth
                  error={!!errors.model}
                  helperText={errors.model?.message}
                />
              )}
            />
            <Controller
              name='location'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Location'
                  type='text'
                  fullWidth
                  error={!!errors.location}
                  helperText={errors.location?.message}
                />
              )}
            />
            <Controller
              name='serialNumber'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Serial Number'
                  type='text'
                  fullWidth
                  error={!!errors.serialNumber}
                  helperText={errors.serialNumber?.message}
                />
              )}
            />
            <Controller
              name='hardwareId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Hardware ID'
                  type='text'
                  fullWidth
                  error={!!errors.hardwareId}
                  helperText={errors.hardwareId?.message}
                />
              )}
            />
            <Controller
              name='projectId'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin='dense'
                  label='Project'
                  fullWidth
                  select
                  error={!!errors.projectId}
                  helperText={errors.projectId?.message}
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name='inUse'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  label='In Use'
                  labelPlacement='start'
                  control={<Checkbox {...field} defaultChecked />}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained' type='submit'>
              Create
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default CreateGateway
