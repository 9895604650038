import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { get } from 'lodash'
import { useCreateUnitMutation } from '../GraphQl/gql.generated'

const CreateUnitModal = ({ buildingId, showUnitModal, setShowUnitModal, handleAddUnit }) => {
  const schema = yup.object().shape({
    number: yup.string().required('Zone Name is required'),
    floor: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Floor Number is required')
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      number: '',
      floor: ''
    }
  })

  const [createUnit] = useCreateUnitMutation()

  const handleAddUnitSubmit = (input) => {
    console.log({ input: { ...input, ...{ buildingId: buildingId } } })
    createUnit({
      variables: {
        input: { ...input, ...{ buildingId: buildingId } }
      }
    }).then((response) => {
      const createdUnit = get(response, ['data', 'createUnit'], {})
      console.log('createdUnit')
      console.log(createdUnit)
      handleAddUnit(createdUnit)
      reset()
      setShowUnitModal(false)
    })
  }
  return (
    <Dialog open={showUnitModal} onClose={() => setShowUnitModal(false)} maxWidth='xs' fullWidth>
      <DialogTitle sx={{ mb: -2 }}>Add New Zone</DialogTitle>
      <Box component='form' onSubmit={handleSubmit(handleAddUnitSubmit)} noValidate sx={{ mt: 1 }}>
        <DialogContent>
          <Controller
            name='number'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ mb: 1 }}
                label='Zone Name'
                type='text'
                autoComplete='off'
                fullWidth
                error={!!errors.number}
                helperText={errors.number ? errors.number.message : ''}
              />
            )}
          />
          <Controller
            name='floor'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label='Floor Number'
                type='number'
                autoComplete='off'
                helperText={errors.floor ? errors.floor.message : ''}
                error={!!errors.floor}
                sx={{ w: '15ch' }}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', mb: 2, justifyContent: 'center' }}>
          <Button type='submit' variant='contained' color='primary'>
            Add Zone
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              reset()
              setShowUnitModal(false)
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default CreateUnitModal
