import React, { Fragment } from 'react'
import { useFetchPowerChartDataQuery } from '../GraphQl/gql.generated'
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
import { StyledTableRow } from '../../../../common/theme/components/tables/StyledTableRow'

import { Line } from 'react-chartjs-2'
import { humanizeLineLabels } from '../../../pages/dashboards/components/WidgetCard/utils'
import { useTheme } from '@mui/material/styles'
import { ACTIVE_ENERGY_LABEL } from '../../../../common/constants/reports'
import { fetchCriteria } from '../../../../common/utils/reports'

const PowerChartsCard = ({ filterCriteria, reportToken, groupingMethod }) => {
  const theme = useTheme()

  const { data: resultChartData, loading: chartsLoading } = useFetchPowerChartDataQuery({
    variables: {
      filters: fetchCriteria(filterCriteria),
      reportToken
    }
  })

  const chartTypes = {
    active_energy_kwh_chart: ACTIVE_ENERGY_LABEL
  }

  const defaultChartDataset = {}
  Object.keys(chartTypes).forEach((value) => (defaultChartDataset[value] = { labels: [], datasets: [] }))
  const chartResult = resultChartData?.fetchPowerChartData ?? defaultChartDataset

  return (
    <>
      {chartsLoading && (
        <Paper sx={{ mt: 2, p: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '20vh'
            }}
          >
            <Typography variant='h5' component='h5'>
              Loading Charts...
            </Typography>
            <CircularProgress />
          </Box>
        </Paper>
      )}
      {!chartsLoading &&
        Object.keys(chartTypes).map((chartType) => (
          <Fragment key={chartType}>
            <Paper sx={{ mt: 2, p: 4 }}>
              <h5 align={'center'} style={{ paddingBottom: 10 }}>
                {chartTypes[chartType]}
              </h5>
              <Line
                style={{ maxHeight: 600, paddingBottom: 60 }}
                data={{
                  labels: humanizeLineLabels(chartResult[chartType].labels),
                  datasets: chartResult[chartType].datasets.map(({ label, data }, key) => ({
                    label,
                    data, // borderColor: randomColor(key),
                    tension: 0.1
                  }))
                }}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      labels: {
                        boxWidth: 15,
                        boxHeight: 15,
                        font: {
                          size: 14
                        }
                      }
                    }
                  }
                }}
              />
              <TableContainer>
                <Table aria-label='collapsible table'>
                  <TableHead>
                    <TableRow key='header'>
                      <StyledTableCell align='left'>Sensor</StyledTableCell>
                      {humanizeLineLabels(chartResult[chartType].labels).map((label) => (
                        <StyledTableCell align='left' key={label}>
                          {/* If grouping by hour, only show first value */}
                          {groupingMethod !== 'hour' ? label : label.split('-')[0].trim()}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className='tight-table-row'>
                    {chartResult[chartType].datasets.map(({ label, data }, key) => (
                      <StyledTableRow key={key} hover>
                        <StyledTableCell
                          component='td'
                          scope='row'
                          sx={{ maxWidth: 200 }}
                          className='overflowText'
                        >
                          <Tooltip title={<Typography fontSize={11}>{label}</Typography>}>{label}</Tooltip>
                        </StyledTableCell>
                        {data.map((usage, key) => (
                          <StyledTableCell
                            key={key}
                            component='td'
                            scope='row'
                            style={{
                              paddingBottom: 8,
                              paddingTop: 8
                            }}
                          >
                            {usage !== null ? Math.round(usage * 10) / 10 : 'N/A'}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Fragment>
        ))}
    </>
  )
}

export default PowerChartsCard
