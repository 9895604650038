import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, LinearProgress } from '@mui/material'
// src
import WidgetsContainer from '../components/WidgetsContainer'
import DashboardInfo from '../components/DashboardInfo'
import DashboardFiltersInfo from '../components/DashboardFiltersInfo'
import { useGetCurrentUserQuery } from '../DashboardsList/gql.generated'
import { useGetPublicDashboardQuery } from './gql.generated'
import { TYPE_OCCUPANT } from '../../../../common/constants/users'

export default function PublicDashboard() {
  const { token } = useParams()

  const navigate = useNavigate()

  const { data: userData } = useGetCurrentUserQuery()

  const userType = userData?.getCurrentUser.type || TYPE_OCCUPANT

  const { data: dashboardData, loading: dashboardLoading } = useGetPublicDashboardQuery({
    variables: { token }
  })

  const moveToEditDashboard = () => {
    navigate(`/dashboards/${token}/edit`)
  }

  if (dashboardLoading) return <LinearProgress />

  const dashboard = dashboardData.getDashboard

  return (
    <Box>
      <DashboardInfo dashboard={dashboard} isPublic />
      {userType !== 'Occupant' && (
        <Button variant='contained' onClick={moveToEditDashboard}>
          Edit Dashboard
        </Button>
      )}
      <DashboardFiltersInfo dashboard={dashboard} />
      <WidgetsContainer isPublic />
    </Box>
  )
}
