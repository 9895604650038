import { TableCell } from '@mui/material'
import React from 'react'

import { getSensorEventBatteryPercentage, getBatteryIcon } from '../../../common/utils/sensors'

const SensorEventBatteryPercentageCell = ({ sensorEvent }) => {
  const batteryPercentage = getSensorEventBatteryPercentage(sensorEvent)
  const batteryIcon = getBatteryIcon(batteryPercentage)

  return (
    <TableCell className='battery-cell' align='center'>
      {batteryPercentage}
      %&nbsp;&nbsp;
      <i className={`fa-solid fa-lg ${batteryIcon}`}></i>
    </TableCell>
  )
}

export default SensorEventBatteryPercentageCell
