import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDashboardWidgetMutationVariables = Types.Exact<{
  input: Types.DeleteDashboardWidgetInput;
}>;


export type DeleteDashboardWidgetMutation = { __typename?: 'Mutation', deleteDashboardWidget: { __typename?: 'DashboardWidget', id: string, type: Types.DashboardWidgetType, position: number, createdAt: unknown } };

export type UpdatePositionDashboardWidgetMutationVariables = Types.Exact<{
  input: Types.UpdateDashboardWidgetPositionInput;
}>;


export type UpdatePositionDashboardWidgetMutation = { __typename?: 'Mutation', updateDashboardWidgetPosition: Array<{ __typename?: 'DashboardWidget', id: string, type: Types.DashboardWidgetType, position: number, createdAt: unknown }> };


export const DeleteDashboardWidgetDocument = gql`
    mutation DeleteDashboardWidget($input: DeleteDashboardWidgetInput!) {
  deleteDashboardWidget(input: $input) {
    id
    type
    position
    createdAt
  }
}
    `;
export type DeleteDashboardWidgetMutationFn = Apollo.MutationFunction<DeleteDashboardWidgetMutation, DeleteDashboardWidgetMutationVariables>;

/**
 * __useDeleteDashboardWidgetMutation__
 *
 * To run a mutation, you first call `useDeleteDashboardWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDashboardWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDashboardWidgetMutation, { data, loading, error }] = useDeleteDashboardWidgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDashboardWidgetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDashboardWidgetMutation, DeleteDashboardWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDashboardWidgetMutation, DeleteDashboardWidgetMutationVariables>(DeleteDashboardWidgetDocument, options);
      }
export type DeleteDashboardWidgetMutationHookResult = ReturnType<typeof useDeleteDashboardWidgetMutation>;
export type DeleteDashboardWidgetMutationResult = Apollo.MutationResult<DeleteDashboardWidgetMutation>;
export type DeleteDashboardWidgetMutationOptions = Apollo.BaseMutationOptions<DeleteDashboardWidgetMutation, DeleteDashboardWidgetMutationVariables>;
export const UpdatePositionDashboardWidgetDocument = gql`
    mutation UpdatePositionDashboardWidget($input: UpdateDashboardWidgetPositionInput!) {
  updateDashboardWidgetPosition(input: $input) {
    id
    type
    position
    createdAt
  }
}
    `;
export type UpdatePositionDashboardWidgetMutationFn = Apollo.MutationFunction<UpdatePositionDashboardWidgetMutation, UpdatePositionDashboardWidgetMutationVariables>;

/**
 * __useUpdatePositionDashboardWidgetMutation__
 *
 * To run a mutation, you first call `useUpdatePositionDashboardWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionDashboardWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionDashboardWidgetMutation, { data, loading, error }] = useUpdatePositionDashboardWidgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePositionDashboardWidgetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePositionDashboardWidgetMutation, UpdatePositionDashboardWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePositionDashboardWidgetMutation, UpdatePositionDashboardWidgetMutationVariables>(UpdatePositionDashboardWidgetDocument, options);
      }
export type UpdatePositionDashboardWidgetMutationHookResult = ReturnType<typeof useUpdatePositionDashboardWidgetMutation>;
export type UpdatePositionDashboardWidgetMutationResult = Apollo.MutationResult<UpdatePositionDashboardWidgetMutation>;
export type UpdatePositionDashboardWidgetMutationOptions = Apollo.BaseMutationOptions<UpdatePositionDashboardWidgetMutation, UpdatePositionDashboardWidgetMutationVariables>;