import React from 'react'
import { Container, Grid } from '@mui/material'
import CardHeader from '../../atoms/CardHeader/CardHeader'

const Card = ({
  maxWidth = 'lg',
  hasCardHeader = true,
  title,
  className = 'card-container',
  children,
  containerSx = {},
  gridSx = {}
}) => {
  return (
    <Container maxWidth={maxWidth} className={className} sx={containerSx}>
      {hasCardHeader && <CardHeader title={title} />}
      <Grid container sx={gridSx}>
        {children}
      </Grid>
    </Container>
  )
}

export default Card
