import React from 'react'
import GaugeChart from 'react-gauge-chart'
import { CircularProgress, useTheme, alpha } from '@mui/material'
// src
import { percentage } from 'common/utils/percentage'
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { WidgetNonData } from '../../WidgetNoData'
import { useFetchAverageCo2GaugesWidgetQuery } from './gql.generated'

const minMaxCO2Range = [400, 2000]
export default function AverageCo2Gauges({ token }) {
  const { palette } = useTheme()

  const { data, loading, error } = useFetchAverageCo2GaugesWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: { token }
  })

  const colors = [palette.grey.A400, palette.success.light, palette.error.main]
  const average = data?.widgetAverageCo2Gauges || 0

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />
  if (!average) return <WidgetNonData />

  const percent = percentage(average, minMaxCO2Range)

  return (
    <GaugeChart
      id='average-co2-gauge'
      textColor={palette.text.secondary}
      needleColor={alpha(palette.text.secondary, 0.5)}
      percent={percent}
      arcsLength={[0.2, 0.6, 0.2]}
      colors={colors}
      formatTextValue={() => average + ' ppm'}
    />
  )
}
