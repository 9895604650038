import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { UserReportModelFragmentDoc } from '../../../pages/UserReportsPage/GraphQl/gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchUserReportsQueryVariables = Types.Exact<{
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchUserReportsQuery = { __typename?: 'Query', fetchUserReports: { __typename?: 'UserReportCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'UserReport', id: string, name: string, criteria?: unknown | null, reportInterval: Types.ReportInterval, startDate: unknown, active: boolean, user: { __typename?: 'User', companyId?: string | null }, reports?: Array<{ __typename?: 'Report', id: string, token: string, criteria?: unknown | null, selectedBuildings: Array<string>, selectedUnits: Array<string>, selectedSensors: Array<string>, sentTo?: Array<string> | null, createdAt: unknown, userReport: { __typename?: 'UserReport', name: string, user: { __typename?: 'User', companyId?: string | null } } }> | null }> } };

export type FetchUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchUsersQuery = { __typename?: 'Query', fetchUsers: Array<{ __typename?: 'User', email: string }> };


export const FetchUserReportsDocument = gql`
    query FetchUserReports($pagination: PaginationType) {
  fetchUserReports(pagination: $pagination) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      ...UserReportModel
    }
  }
}
    ${UserReportModelFragmentDoc}`;

/**
 * __useFetchUserReportsQuery__
 *
 * To run a query within a React component, call `useFetchUserReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserReportsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchUserReportsQuery(baseOptions?: Apollo.QueryHookOptions<FetchUserReportsQuery, FetchUserReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserReportsQuery, FetchUserReportsQueryVariables>(FetchUserReportsDocument, options);
      }
export function useFetchUserReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserReportsQuery, FetchUserReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserReportsQuery, FetchUserReportsQueryVariables>(FetchUserReportsDocument, options);
        }
export type FetchUserReportsQueryHookResult = ReturnType<typeof useFetchUserReportsQuery>;
export type FetchUserReportsLazyQueryHookResult = ReturnType<typeof useFetchUserReportsLazyQuery>;
export type FetchUserReportsQueryResult = Apollo.QueryResult<FetchUserReportsQuery, FetchUserReportsQueryVariables>;
export const FetchUsersDocument = gql`
    query FetchUsers {
  fetchUsers {
    email
  }
}
    `;

/**
 * __useFetchUsersQuery__
 *
 * To run a query within a React component, call `useFetchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUsersQuery(baseOptions?: Apollo.QueryHookOptions<FetchUsersQuery, FetchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUsersQuery, FetchUsersQueryVariables>(FetchUsersDocument, options);
      }
export function useFetchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUsersQuery, FetchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUsersQuery, FetchUsersQueryVariables>(FetchUsersDocument, options);
        }
export type FetchUsersQueryHookResult = ReturnType<typeof useFetchUsersQuery>;
export type FetchUsersLazyQueryHookResult = ReturnType<typeof useFetchUsersLazyQuery>;
export type FetchUsersQueryResult = Apollo.QueryResult<FetchUsersQuery, FetchUsersQueryVariables>;