import React from 'react'
import { IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const MenuDropdownIconButton = ({ open, handleClick }) => {
  return (
    <IconButton
      aria-label='more'
      id='long-button'
      aria-controls={open ? 'menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup='true'
      onClick={handleClick}
    >
      <MoreVertIcon />
    </IconButton>
  )
}

export default MenuDropdownIconButton
