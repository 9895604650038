import React from 'react'
import { FormControlLabel, ThemeProvider } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'

import { MuiFormControlLabelTheme } from '../../../common/theme/MuiFormControlLabel'

const FormCheckbox = ({
  label,
  checked = false,
  onChange,
  disabled,
  formControlLabelSx = { m: 0 },
  size,
  checkBoxSx = {}
}) => {
  return (
    <ThemeProvider theme={MuiFormControlLabelTheme}>
      <FormControlLabel
        label={label}
        sx={formControlLabelSx}
        control={
          <Checkbox checked={checked} onChange={onChange} disabled={disabled} size={size} sx={checkBoxSx} />
        }
      />
    </ThemeProvider>
  )
}

export default FormCheckbox
