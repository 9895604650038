import React, { useState } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import useOuterClick from 'common/hooks/useOuterClick'

export default function ClipboardCopy({ text }: { text: string }) {
  const [copied, setCopied] = useState(false)

  const innerRef = useOuterClick(() => setCopied(false))
  const handleCopy = () => setCopied(true)

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <Tooltip title={copied ? 'Copied' : 'Copy'} placement='top'>
        <IconButton ref={innerRef}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  )
}
