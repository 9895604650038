import { get } from 'lodash'
import { useGetBuildingEmergencyResponseDetailsQuery } from './GraphQl/gql.generated'

const useBuildingEmergencyResponseDetailsCard = ({ buildingId }) => {
  const { data } = useGetBuildingEmergencyResponseDetailsQuery({
    variables: {
      id: buildingId
    }
  })

  const waterAlertMessage = get(data, ['getBuilding', 'waterAlertMessage'])

  return {
    waterAlertMessage
  }
}

export default useBuildingEmergencyResponseDetailsCard
