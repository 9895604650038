import React from 'react'
import { startCase, uniq } from 'lodash'
import { lookupAlertIcon } from '../../../common/utils/alerts'

// use reactKeyPrefix if you are going to render this component multiple times on the same page
// *alertType is uniq per render of AlertIcon but reactKeyPrefix will need to be uniq for each individual render of AlertIcon
const AlertIcons = ({ alertTypes, displaySize, reactKeyPrefix }) => {
  const eventTypeDisplay = (alertType) => startCase(alertType)
  const uniqAlertTypes = uniq(alertTypes)

  return (
    <>
      {uniqAlertTypes.map((alertType) => (
        <React.Fragment key={reactKeyPrefix ? `${reactKeyPrefix}-${alertType}` : alertType}>
          {eventTypeDisplay(alertType)}
          <i
            className={`fa ${lookupAlertIcon(alertType)} ${displaySize ? displaySize : 'fa-xl'} alert-icon`}
            title={eventTypeDisplay(alertType)}
          ></i>
        </React.Fragment>
      ))}
    </>
  )
}

export default AlertIcons
