import React, { useEffect, useState } from 'react'
import {
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Autocomplete,
  CircularProgress
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import FormCheckbox from '../../atoms/CheckmarkCell/FormCheckbox'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import BuildingFilters from '../../pages/dashboards/components/DashboardFiltersPanel/BuildingFilters'
import { useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery } from '../../pages/dashboards/DashboardsList/gql.generated'
import { get, isEmpty, map, omit } from 'lodash'
import {
  ALERT_REPORT_DISPLAY,
  TEMPERATURE_REPORT_DISPLAY,
  OCCUPANCY_REPORT_DISPLAY,
  IAQ_REPORT_DISPLAY,
  POWER_REPORT_DISPLAY,
  SOUND_REPORT_DISPLAY,
  REPORT_INTERVAL_SELECT,
  REPORT_DISPLAY_TYPES,
  WEEK
} from '../../../common/constants/reports'
import { initialFiltersState, tomorrow } from '../../../common/utils/reports'
import { useCreateUserReportMutation } from '../../pages/UserReportsPage/GraphQl/gql.generated'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useFetchUsersQuery } from './GraphQl/gql.generated'

const CreateUserReportDialog = ({ loading, previewContext = true, saveFilters = {} }) => {
  if (!isEmpty(saveFilters)) {
    saveFilters['reportInterval'] = WEEK
    saveFilters['reportingStartDate'] = tomorrow
  }

  const [filters, setFilters] = useState(() => (isEmpty(saveFilters) ? initialFiltersState : saveFilters))
  const [open, setOpen] = useState(false)
  const [reportSaved, setReportSaved] = useState(false)
  const [criteriaDisplay, setCriteriaDisplay] = useState({})
  const [recipients, setRecipients] = useState([])
  const [showStartDate, setShowStartDate] = useState(true)
  const navigate = useNavigate()
  const { data: userData } = useGetCurrentUserQuery()
  const user = userData?.getCurrentUser

  const handleFilterChange = ({ filter, value, updateCriteriaDisplay = null }) => {
    setFilters((filters) => {
      return { ...filters, [filter]: value }
    })
    if (updateCriteriaDisplay) setCriteriaDisplay({ ...criteriaDisplay, ...updateCriteriaDisplay })

    // If users want emails quarterly, monthly, or annually, then we assume the first day of the new year/month/quarter
    // Otherwise, they can choose the date they want the weekly report to start on
    if (filter === 'reportInterval') setShowStartDate(value === WEEK)
  }

  const schema = yup
    .object({
      name: yup.string().required('Name is required'),
      startDate: yup
        .date()
        .typeError('Start Date must be a valid date')
        .required('Start Date is a required field'),
      endDate: yup
        .date()
        .typeError('End Date must be a valid date')
        .required('End Date is a required field')
        .min(yup.ref('startDate'), 'End date cannot be before start date')
        .max(new Date(), 'End date can not be later than today'),
      alertsDisplay: yup.bool().nullable(),
      reportingStartDate: yup
        .date()
        .typeError('Automatic Send Start Date must be a valid date')
        .min(tomorrow, 'Automatic Send Start Date cannot be in the past'),
      _checkboxes: yup
        .string()
        .test('at-least-one-checkbox', 'You must select at least one metric to report on', function () {
          if (typeof this !== 'undefined') {
            // This logic is here because checkboxes are disabled when we are in the "save context"
            // eg. previewContext == false, so we defer to the save filters which were passed in to
            // the dialog
            const valuesToValidate = previewContext ? this.parent : saveFilters
            return Object.keys(valuesToValidate)
              .filter((key) => REPORT_DISPLAY_TYPES.includes(key))
              .some((key) => valuesToValidate[key] === true)
          }
          return true
        })
    })
    .required()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialFiltersState
  })

  const [createUserReport] = useCreateUserReportMutation()
  const { data: usersData, loading: usersLoading } = useFetchUsersQuery({ skip: !open || previewContext })
  const userEmails = get(usersData, ['fetchUsers'], [])

  const onSubmit = (input) => {
    if (previewContext) {
      navigate('/reports/preview', { state: { data: filters, criteriaDisplay: criteriaDisplay } })
    } else {
      const writeFilters = !previewContext ? saveFilters : filters
      createUserReport({
        variables: {
          input: {
            name: input.name,
            startDate: input.reportingStartDate,
            criteria: omit(writeFilters, [
              'reportingStartDate',
              'reportInterval',
              'name',
              'startDate',
              'endDate'
            ]),
            reportInterval: writeFilters['reportInterval'],
            recipients: recipients
          }
        }
      }).then(() => {
        setReportSaved(true)
        handleClose()
      })
    }
  }

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (previewContext) {
      reset(initialFiltersState)
      setFilters(initialFiltersState)
    }
  }

  return (
    <>
      {previewContext ? (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'> My Reports </Typography>
          <Button variant='contained' onClick={handleClickOpen}>
            New Report
          </Button>
        </Box>
      ) : (
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'> Report Preview </Typography>
          {reportSaved ? (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <CheckCircleOutlineIcon color='primary' fontSize='large' sx={{ pr: 1 }} />
              <Typography variant='body1' sx={{ pr: 2 }}>
                {' '}
                Report Saved Successfully
              </Typography>
              <Button variant='contained' onClick={() => navigate('/user_reports')}>
                Back to My Reports
              </Button>
            </Box>
          ) : (
            <Button variant='contained' onClick={handleClickOpen}>
              Save & Autosend Report
            </Button>
          )}
        </Box>
      )}

      <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box component='form' onSubmit={handleSubmit(onSubmit)} noValidate sx={{ m: 1 }}>
          <DialogTitle>Report Criteria</DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={3} style={{ paddingTop: 5 }}>
              <BuildingFilters
                handleFilterChange={handleFilterChange}
                companyId={user?.companyId}
                filters={!previewContext ? saveFilters : filters}
                loading={loading}
                columnDisplay={true}
                disabled={!previewContext}
              />
            </Grid>
            {previewContext && (
              <Grid container direction='row' spacing={2} style={{ paddingTop: 28 }}>
                <Grid item xs={3} style={{ minWidth: '270px' }}>
                  <Controller
                    name='startDate'
                    control={control}
                    render={({ field: { onChange, value, ref, ...fieldProps } }) => (
                      <FormControl>
                        <DesktopDatePicker
                          {...fieldProps}
                          inputRef={ref}
                          label='Start Date'
                          inputFormat='yyyy/MM/dd'
                          value={value}
                          onChange={(date) => {
                            onChange(date)
                            handleFilterChange({ filter: 'startDate', value: date })
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              helperText={errors.startDate ? String(errors.startDate.message) : ''}
                              error={!!errors.startDate}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={2} style={{ minWidth: '270px' }} alignItems='flex-start'>
                  <Controller
                    name='endDate'
                    control={control}
                    render={({ field: { onChange, value, ref, ...fieldProps } }) => (
                      <FormControl>
                        <DesktopDatePicker
                          {...fieldProps}
                          inputRef={ref}
                          label='End Date'
                          inputFormat='yyyy/MM/dd'
                          value={value}
                          onChange={(date) => {
                            onChange(date)
                            handleFilterChange({ filter: 'endDate', value: date })
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              helperText={errors.endDate ? String(errors.endDate.message) : ''}
                              error={!!errors.endDate}
                            />
                          )}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item style={{ paddingTop: 15 }}>
              <Grid item>
                <Typography variant='subtitle1'>Select Insights Metrics</Typography>
                {errors['_checkboxes'] && (
                  <Typography variant='body2' color='error'>
                    {errors['_checkboxes'].message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name={ALERT_REPORT_DISPLAY}
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      label='Alerts'
                      disabled={!previewContext}
                      checked={previewContext ? field.value : saveFilters[ALERT_REPORT_DISPLAY]}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        handleFilterChange({ filter: ALERT_REPORT_DISPLAY, value: e.target.checked })
                      }}
                    />
                  )}
                />
                <Controller
                  name={TEMPERATURE_REPORT_DISPLAY}
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      label='Temperature'
                      /* enable once temperature widgets are ready */
                      disabled={true || !previewContext}
                      checked={previewContext ? field.value : saveFilters[TEMPERATURE_REPORT_DISPLAY]}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        handleFilterChange({ filter: TEMPERATURE_REPORT_DISPLAY, value: e.target.checked })
                      }}
                    />
                  )}
                />
                <Controller
                  name={OCCUPANCY_REPORT_DISPLAY}
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      label='Occupancy'
                      disabled={!previewContext}
                      checked={previewContext ? field.value : saveFilters[OCCUPANCY_REPORT_DISPLAY]}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        handleFilterChange({ filter: OCCUPANCY_REPORT_DISPLAY, value: e.target.checked })
                      }}
                    />
                  )}
                />
                <Controller
                  name={IAQ_REPORT_DISPLAY}
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      label='IAQ'
                      disabled={!previewContext}
                      checked={previewContext ? field.value : saveFilters[IAQ_REPORT_DISPLAY]}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        handleFilterChange({ filter: IAQ_REPORT_DISPLAY, value: e.target.checked })
                      }}
                    />
                  )}
                />
                <Controller
                  name={POWER_REPORT_DISPLAY}
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      label='Power'
                      disabled={!previewContext}
                      checked={previewContext ? field.value : saveFilters[POWER_REPORT_DISPLAY]}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        handleFilterChange({ filter: POWER_REPORT_DISPLAY, value: e.target.checked })
                      }}
                    />
                  )}
                />
                <Controller
                  name={SOUND_REPORT_DISPLAY}
                  control={control}
                  render={({ field }) => (
                    <FormCheckbox
                      label='Sound'
                      disabled={!previewContext}
                      checked={previewContext ? field.value : saveFilters[SOUND_REPORT_DISPLAY]}
                      onChange={(e) => {
                        field.onChange(e.target.checked)
                        handleFilterChange({ filter: SOUND_REPORT_DISPLAY, value: e.target.checked })
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {!previewContext && (
              <>
                <Divider sx={{ pt: 2 }} />
                <Grid item sx={{ pt: 3 }}>
                  <Grid item>
                    <Typography variant='subtitle1' sx={{ pb: 1 }}>
                      Schedule Report
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          fullWidth
                          margin='normal'
                          label='Report Name'
                          // defaultValue='My Report'
                          autoFocus
                          error={Boolean(errors.name)}
                          helperText={errors.name?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sx={{ pt: 3 }}>
                    <FormControl fullWidth>
                      <InputLabel>Send Report Frequency:</InputLabel>
                      <Select
                        name='reportInterval'
                        disabled={loading}
                        size='small'
                        value={filters['reportInterval']}
                        label='Send Report Frequency:'
                        onChange={(event) =>
                          handleFilterChange({ filter: 'reportInterval', value: event.target.value })
                        }
                      >
                        {REPORT_INTERVAL_SELECT.map(({ value, key }) => (
                          <MenuItem value={key} key={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {showStartDate && (
                  <Grid container direction='row' spacing={2} style={{ paddingTop: 28 }}>
                    <Grid item xs={3} style={{ minWidth: '270px' }}>
                      <Controller
                        name='reportingStartDate'
                        control={control}
                        render={({ field: { onChange, value, ref, ...fieldProps } }) => (
                          <FormControl>
                            <DesktopDatePicker
                              {...fieldProps}
                              inputRef={ref}
                              label='Automatic Sending Start Date'
                              inputFormat='yyyy/MM/dd'
                              minDate={tomorrow}
                              value={value}
                              onChange={(date) => {
                                onChange(date)
                                handleFilterChange({ filter: 'reportingStartDate', value: date })
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  helperText={
                                    errors.reportingStartDate ? String(errors.reportingStartDate.message) : ''
                                  }
                                  error={!!errors.reportingStartDate}
                                />
                              )}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={3} style={{ minWidth: '270px', paddingTop: 20 }}>
                  <Autocomplete
                    disabled={usersLoading}
                    multiple
                    freeSolo
                    size='small'
                    value={recipients}
                    onChange={(event, newValue) => setRecipients(newValue)}
                    options={map(userEmails, 'email')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={'Additional Recipients'}
                        autoComplete='off'
                        helperText='Press Enter after typing in each email address'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {usersLoading ? <CircularProgress color='inherit' size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='contained' type='submit'>
              {previewContext ? 'Run Report' : 'Save & Autosend Report'}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

export default CreateUserReportDialog
