import { useState } from 'react'

const useSearchBar = ({ query, setQuery }) => {
  const [stateQuery, setStateQuery] = useState(query || '')

  const handleQueryChange = (event) => {
    setStateQuery(event.target.value)
  }

  const handleSetQuery = () => setQuery(stateQuery)

  const onSearchSubmit = (event) => (event.keyCode === 13 ? handleSetQuery() : null)

  return {
    stateQuery,
    handleQueryChange,
    onSearchSubmit,
    handleSetQuery
  }
}

export default useSearchBar
