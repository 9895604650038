import React, { Fragment, useState } from 'react'
import { fullDateTimeFormat } from 'common/utils/dates'
import { Box, Collapse, IconButton, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material'
import { min, startCase, orderBy, capitalize } from 'lodash'
import { collectContactTypes, lookupAlertIcon } from '../../../common/utils/alerts'
import { StyledTableRow } from '../../../common/theme/components/tables/StyledTableRow'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'
import MouseOverPopover from '../MouseOverPopover'

const AlertListRow = ({
  alert,
  hasTemperature,
  hasHumidity,
  hasSound,
  hideUnitColumn,
  hasBuilding,
  isPrinting
}: {
  alert: any
  hasTemperature: boolean
  hasHumidity: boolean
  hasSound: boolean
  hideUnitColumn: boolean
  hasBuilding: boolean
  isPrinting: boolean
}) => {
  const [open, setOpen] = useState(false)

  const minSentAt = min(alert.userAlerts.map((item) => item.sentAt))
  const minAcknowledgedAt = min(alert.userAlerts.map((item) => item.acknowledgedAt))

  return (
    <Fragment>
      <StyledTableRow hover>
        <TableCell style={{ padding: 0, paddingLeft: 5 }}>
          {alert.userAlerts.length > 1 && (
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open || isPrinting ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        {hasBuilding && (
          <TableCell component='td' scope='row'>
            {alert.sensorEvent.sensor.building.name}
          </TableCell>
        )}
        {!hideUnitColumn && (
          <TableCell component='td' scope='row'>
            {alert.sensorEvent.sensor.unit.number}
          </TableCell>
        )}
        <TableCell align='left'>
          {alert.sensorEvent.sensor.name || alert.sensorEvent.sensor.deviceName}
        </TableCell>
        <TableCell align='left'>{alert.sensorEvent.sensor.location}</TableCell>
        <TableCell align='center' sx={{ width: 170 }}>
          {startCase(alert.alertType)}
          <i
            className={`fa ${lookupAlertIcon(alert.alertType)} fa-xl alert-icon`}
            title={startCase(alert.alertType)}
          />
        </TableCell>
        <TableCell align='left'>
          {alert.severity ? (
            <MouseOverPopover baseText={capitalize(alert.severity)}>
              <Box
                sx={{
                  border: '2px solid #365970',
                  padding: '10px'
                }}
              >
                <Typography
                  variant='body2'
                  sx={{ fontWeight: 'bold', color: '#365970', pb: 0.5, maxWidth: 500 }}
                >
                  Alert Details: <span style={{ fontWeight: 'normal', color: '#000' }}>{alert.feedback}</span>
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 'bold', color: '#365970' }}>
                  Recorded By:{' '}
                  <span style={{ fontWeight: 'normal', color: '#000' }}>
                    {alert.acknowledgedUser?.firstName} {alert.acknowledgedUser?.lastName}
                  </span>
                </Typography>
              </Box>
            </MouseOverPopover>
          ) : (
            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
              Uncategorized
            </Typography>
          )}
        </TableCell>
        {hasTemperature && <TableCell align='left'>{alert.sensorEvent.temperature}</TableCell>}
        {hasHumidity && <TableCell align='left'>{alert.sensorEvent.humidity}</TableCell>}
        {hasSound && <TableCell align='left'>{alert.sensorEvent.splMax}</TableCell>}
        <TableCell align='left'>
          {alert.userAlerts.length > 1
            ? `${alert.userAlerts.length} users`
            : [alert.userAlerts[0]?.user.firstName, alert.userAlerts[0]?.user.lastName].join(' ')}
        </TableCell>
        <TableCell align='left'>
          {fullDateTimeFormat(minSentAt) || fullDateTimeFormat(alert.createdAt)}
        </TableCell>
        <TableCell align='left'>{fullDateTimeFormat(minAcknowledgedAt)}</TableCell>
        <TableCell align='left'>{collectContactTypes(alert.userAlerts)}</TableCell>
      </StyledTableRow>
      {alert.userAlerts.length > 1 && (
        <>
          <StyledTableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
              <Collapse in={open || isPrinting} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant='subtitle1'>Notified Users</Typography>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Sent</StyledTableCell>
                        <StyledTableCell>Acknowledged</StyledTableCell>
                        <StyledTableCell align='right'>Sent Via</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderBy(alert.userAlerts, 'sentAt', 'desc').map((userAlertRow) => (
                        <StyledTableRow key={userAlertRow.sentAt}>
                          <TableCell component='td' scope='row'>
                            {userAlertRow.user.firstName} {userAlertRow.user.lastName}
                          </TableCell>
                          <TableCell>{fullDateTimeFormat(userAlertRow.sentAt)}</TableCell>
                          <TableCell>{fullDateTimeFormat(userAlertRow.acknowledgedAt)}</TableCell>
                          <TableCell align='right'>{collectContactTypes([userAlertRow])}</TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </StyledTableRow>
          <StyledTableRow />
        </>
      )}
    </Fragment>
  )
}

export default AlertListRow
