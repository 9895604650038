import React, { ChangeEvent } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box
} from '@mui/material'
import TableCell from '@mui/material/TableCell'
import { useQueryParam, NumberParam, withDefault } from 'use-query-params'
import { styled } from '@mui/material/styles'

import DashboardListRow from './components/DashboardListRow'
import CreateDashboardDialog from './components/CreateDashboardDialog'
import { useFetchDashboardsQuery, useGetCurrentUserQuery } from './gql.generated'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.secondary
}))

const DashboardsList = () => {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))
  const [rowsPerPage, setRowsPerPage] = useQueryParam('pageSize', withDefault(NumberParam, 10))

  const { data: userData, loading: userLoading } = useGetCurrentUserQuery()

  const { data: dashboardData, loading: dashboardLoading } = useFetchDashboardsQuery({
    variables: {
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    }
  })

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (dashboardLoading || userLoading) return <></>

  const dashboards = dashboardData?.fetchDashboards.collection || []
  const metadata = dashboardData?.fetchDashboards.metadata
  const user = userData?.getCurrentUser

  // TODO: RBAC permissions with @casl/react
  const isUserSuperAdmin = user.type === 'Superadmin'

  return (
    <Box>
      <CreateDashboardDialog />
      <Paper sx={{ p: 2, mt: 2 }}>
        <TableContainer>
          <Table aria-label='collapsible table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                {isUserSuperAdmin && <StyledTableCell align='left'>Company</StyledTableCell>}
                <StyledTableCell align='left'>Link</StyledTableCell>
                <StyledTableCell align='center'>QR Code</StyledTableCell>
                <StyledTableCell align='center'>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboards.map((dashboard) => (
                <DashboardListRow
                  key={dashboard.id}
                  dashboard={dashboard}
                  isUserSuperAdmin={isUserSuperAdmin}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            colSpan={4}
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={metadata?.totalCount || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default DashboardsList
