import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDashboardWidgetMutationVariables = Types.Exact<{
  input: Types.CreateDashboardWidgetInput;
}>;


export type CreateDashboardWidgetMutation = { __typename?: 'Mutation', createDashboardWidget: { __typename?: 'DashboardWidget', id: string, type: Types.DashboardWidgetType, position: number, createdAt: unknown } };


export const CreateDashboardWidgetDocument = gql`
    mutation CreateDashboardWidget($input: CreateDashboardWidgetInput!) {
  createDashboardWidget(input: $input) {
    id
    type
    position
    createdAt
  }
}
    `;
export type CreateDashboardWidgetMutationFn = Apollo.MutationFunction<CreateDashboardWidgetMutation, CreateDashboardWidgetMutationVariables>;

/**
 * __useCreateDashboardWidgetMutation__
 *
 * To run a mutation, you first call `useCreateDashboardWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardWidgetMutation, { data, loading, error }] = useCreateDashboardWidgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDashboardWidgetMutation(baseOptions?: Apollo.MutationHookOptions<CreateDashboardWidgetMutation, CreateDashboardWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDashboardWidgetMutation, CreateDashboardWidgetMutationVariables>(CreateDashboardWidgetDocument, options);
      }
export type CreateDashboardWidgetMutationHookResult = ReturnType<typeof useCreateDashboardWidgetMutation>;
export type CreateDashboardWidgetMutationResult = Apollo.MutationResult<CreateDashboardWidgetMutation>;
export type CreateDashboardWidgetMutationOptions = Apollo.BaseMutationOptions<CreateDashboardWidgetMutation, CreateDashboardWidgetMutationVariables>;