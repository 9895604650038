// import React, { Fragment, useEffect, useState } from 'react'
// import {
//   Box,
//   CircularProgress,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow
// } from '@mui/material'
// import { useFetchAlertDataQuery } from '../GraphQl/gql.generated'
//
// import EventTypeFilter from '../../../atoms/EventTypeFilter'
// import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'
// import { Bar } from 'react-chartjs-2'

const TemperatureSummaryCard = ({ filters }) => {
  // const [alertsSummary, setAlertsSummary] = useState({})
  // const [eventFilter, setEventFilter] = useState(null)
  //
  // const { data: resultData, loading } = useFetchAlertDataQuery({ variables: { filters, eventFilter } })
  //
  // const chartOptions = {
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //       stacked: true,
  //       stepSize: 1
  //     },
  //     x: {
  //       stacked: true
  //     }
  //   }
  // }
  //
  // useEffect(() => {
  //   if (!loading) {
  //     setAlertsSummary(resultData.fetchAlertData)
  //   }
  // }, [resultData])
  //
  // return (
  //   <Paper sx={{ mt: 2, p: 4 }}>
  //     <h4 align={'center'}>Alerts Summary</h4>
  //
  //     <Box sx={{ width: '10%' }}>
  //       <EventTypeFilter eventFilter={eventFilter} setEventFilter={setEventFilter} />
  //     </Box>
  //     <TableContainer>
  //       <Table aria-label='collapsible table'>
  //         <TableHead>
  //           <TableRow>
  //             <StyledTableCell align='left'>Alerting Sensors</StyledTableCell>
  //             <StyledTableCell align='left'>Alert Events</StyledTableCell>
  //             <StyledTableCell align='left'>Alerts Sent</StyledTableCell>
  //             <StyledTableCell align='left'>Alerts Acknowledged</StyledTableCell>
  //             <StyledTableCell align='left'>Avg. Response Time</StyledTableCell>
  //             <StyledTableCell align='left'>Water Alerts</StyledTableCell>
  //             <StyledTableCell align='left'>Temperature Alerts</StyledTableCell>
  //             <StyledTableCell align='left'>Door Alerts</StyledTableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {!loading && (
  //             <>
  //               <TableRow hover>
  //                 <TableCell component='th' scope='row'>
  //                   {alertsSummary.alerting_sensors_count}
  //                 </TableCell>
  //                 <TableCell component='th' scope='row'>
  //                   {alertsSummary.alert_events}
  //                 </TableCell>
  //                 <TableCell component='th' scope='row'>
  //                   {alertsSummary.alerts_sent_count}
  //                 </TableCell>
  //                 <TableCell component='th' scope='row'>
  //                   {alertsSummary.alerts_ack_count}
  //                 </TableCell>
  //                 <TableCell component='th' scope='row'>
  //                   {alertsSummary.average_response_time}
  //                 </TableCell>
  //                 <TableCell component='th' scope='row'>
  //                   {alertsSummary.alerts_type_counts?.water || 0}
  //                 </TableCell>
  //                 <TableCell component='th' scope='row'>
  //                   {(alertsSummary.alerts_type_counts?.low_temperature || 0) +
  //                     (alertsSummary.alerts_type_counts?.high_temperature || 0)}
  //                 </TableCell>
  //                 <TableCell component='th' scope='row'>
  //                   {alertsSummary.alerts_type_counts?.door_open || 0}
  //                 </TableCell>
  //               </TableRow>
  //             </>
  //           )}
  //           {loading && (
  //             <TableRow hover>
  //               <TableCell align='center' colSpan={8} scope='row'>
  //                 <CircularProgress />
  //               </TableCell>
  //             </TableRow>
  //           )}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //     <br />
  //     <br />
  //     {!loading && alertsSummary.alert_events > 0 && (
  //       <>
  //         <Bar
  //           data={{
  //             labels: alertsSummary.chart_dataset?.labels,
  //             datasets: alertsSummary.chart_dataset?.datasets.map((ds) => ({
  //               label: ds.label,
  //               data: Object.values(ds.data)
  //             }))
  //           }}
  //           options={chartOptions}
  //           height={'60'}
  //         />
  //       </>
  //     )}
  //   </Paper>
  // )
}

export default TemperatureSummaryCard
