import { useEffect } from 'react'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'

import {
  useGetCurrentUserForSensorsPageQuery,
  useGetSensorsPageLocationPhotoLazyQuery,
  useGetSensorsPageQuery
} from './GraphQl/gql.generated'

const useSensorPage = () => {
  const { id } = useParams()

  // Get current user for their type (conditionally show columns if occupant/superadmin etc)
  const { data: currentUserData } = useGetCurrentUserForSensorsPageQuery()
  const currentUser = get(currentUserData, ['getCurrentUser'], {})

  // Get Sensor Data
  const { data: sensorData, loading } = useGetSensorsPageQuery({ variables: { id } })
  const sensor = get(sensorData, ['getSensor'], {})
  const building = get(sensorData, ['getSensor', 'building'], {})
  const unit = get(sensorData, ['getSensor', 'unit'], {})

  // Get Sensor Location Photo
  // Loading the image can be relatively slow, so we use lazy query to not block the rest of the data from loading
  const [getSensorLocationPhoto, { data: locationPhotoData }] = useGetSensorsPageLocationPhotoLazyQuery({
    variables: { id }
  })
  const sensorLocationPhoto = get(locationPhotoData, ['getSensor', 'sensorLocationPhotoFull'], null)
  useEffect(() => {
    getSensorLocationPhoto({ variables: { id } })
  }, [])

  return {
    loading,
    userType: get(currentUser, ['type']),
    sensor,
    building,
    unit,
    sensorLocationPhoto
  }
}

export default useSensorPage
