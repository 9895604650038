export const SENSOR_NOT_ALERTING = 'Sensor not currently alerting'
export const SENSOR_NOT_IN_USE = 'Sensor not in use'
export const SENSOR_NOT_MUTED = 'Not Muted'

export const WATER_SENSITIVITY_SLIDER_MARKS = [
  {
    value: 0,
    label: '0 Min Wait'
  },
  {
    value: 30,
    label: 'Sensitivity'
  },
  {
    value: 60,
    label: '60 Min Wait'
  }
]

export const TEMPERATURE_SLIDER_MARKS = [
  {
    value: -50,
    label: '-50°C'
  },
  {
    value: 0,
    label: '0°C'
  },
  {
    value: 100,
    label: '100°C'
  }
]

export const HUMIDITY_SLIDER_MARKS = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 50,
    label: '50%'
  },
  {
    value: 100,
    label: '100%'
  }
]
