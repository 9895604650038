import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchOccupantUnitInformationQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID'];
  filters?: Types.InputMaybe<Types.Filters>;
}>;


export type FetchOccupantUnitInformationQuery = { __typename?: 'Query', fetchUserBuildings: Array<{ __typename?: 'UserBuilding', id: string, userId: number, building: { __typename?: 'Building', id: string, name?: string | null, address?: string | null, city: string, stateProvince: string, temperatureAverage?: number | null, humidityAverage?: number | null }, unit?: { __typename?: 'Unit', id: string, number?: string | null, sensorsCount: number, activeAlerts?: Array<{ __typename?: 'Alert', id: string, alertType: Types.AlertType }> | null, lastAlert?: { __typename?: 'Alert', id: string, alertType: Types.AlertType, createdAt: unknown } | null, sensors?: Array<{ __typename?: 'Sensor', id: string, monitoringTypes?: Array<string> | null }> | null } | null }> };


export const FetchOccupantUnitInformationDocument = gql`
    query FetchOccupantUnitInformation($userId: ID!, $filters: Filters) {
  fetchUserBuildings(userId: $userId, filters: $filters) {
    id
    userId
    building {
      id
      name
      address
      city
      stateProvince
      temperatureAverage
      humidityAverage
    }
    unit {
      id
      number
      sensorsCount
      activeAlerts {
        id
        alertType
      }
      lastAlert {
        id
        alertType
        createdAt
      }
      sensors {
        id
        monitoringTypes
      }
    }
  }
}
    `;

/**
 * __useFetchOccupantUnitInformationQuery__
 *
 * To run a query within a React component, call `useFetchOccupantUnitInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOccupantUnitInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOccupantUnitInformationQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFetchOccupantUnitInformationQuery(baseOptions: Apollo.QueryHookOptions<FetchOccupantUnitInformationQuery, FetchOccupantUnitInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOccupantUnitInformationQuery, FetchOccupantUnitInformationQueryVariables>(FetchOccupantUnitInformationDocument, options);
      }
export function useFetchOccupantUnitInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOccupantUnitInformationQuery, FetchOccupantUnitInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOccupantUnitInformationQuery, FetchOccupantUnitInformationQueryVariables>(FetchOccupantUnitInformationDocument, options);
        }
export type FetchOccupantUnitInformationQueryHookResult = ReturnType<typeof useFetchOccupantUnitInformationQuery>;
export type FetchOccupantUnitInformationLazyQueryHookResult = ReturnType<typeof useFetchOccupantUnitInformationLazyQuery>;
export type FetchOccupantUnitInformationQueryResult = Apollo.QueryResult<FetchOccupantUnitInformationQuery, FetchOccupantUnitInformationQueryVariables>;