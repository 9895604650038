import React, { useState } from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { CircularProgress } from '@mui/material'
import { format } from 'date-fns'

// src
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { useFetchWaterAlertsWidgetQuery } from './gql.generated'

const columns: GridColDef[] = [
  {
    field: 'sensor_events.event_time',
    headerName: 'Time',
    width: 120,
    valueGetter: (params: GridValueGetterParams) =>
      format(new Date(params.row.eventTime), 'd MMM, HH:mm') || ''
  },
  {
    field: 'units.number',
    headerName: 'Zone',
    width: 430,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.sensor.location +
        ': ' +
        params.row.sensor.building.name +
        ' - ' +
        params.row.sensor.unit.number +
        ' (' +
        params.row.sensor.unit.floor +
        ')' || ''
  }
]

const initialField = 'sensor_events.humidity'
const initialSort = 'desc'

export default function WaterAlerts({ token }) {
  const [page, setPage] = useState(0)
  const [field, setField] = useState(initialField)
  const [sort, setSort] = useState(initialSort)
  const [pageSize, setPageSize] = useState(100)

  const { data, loading, error } = useFetchWaterAlertsWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: {
      token,
      pagination: {
        page: page + 1,
        per: pageSize
      },
      sorting: {
        field,
        sort
      }
    }
  })

  const rows = data?.widgetWaterAlerts?.collection || []
  const rowsCount = data?.widgetWaterAlerts?.metadata.totalCount || 0

  const handlePageChange = (page) => setPage(page)

  const handleChangeSort = (sortModel) => {
    const sortPair = sortModel[0]
    if (sortPair) {
      const { field, sort } = sortModel[0]
      setField(field)
      setSort(sort)
    } else {
      setSort(initialSort)
    }
  }

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      page={page}
      hideFooterSelectedRowCount
      pageSize={pageSize}
      rowCount={rowsCount}
      onSortModelChange={handleChangeSort}
      sortingMode='server'
      paginationMode='server'
      onPageChange={handlePageChange}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowHeight={30}
      headerHeight={30}
      pagination
      sx={{
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: ({ palette }) => palette.grey[200]
        }
      }}
    />
  )
}
