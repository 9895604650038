import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Typography
} from '@mui/material'

import { unitLabel } from '../../../common/utils/buildings'
import usePagination from '../../../common/hooks/usePagination'
import { StyledTableCell } from '../../../common/theme/components/tables/StyledTableCell'

import useSensorsTable from './useSensorsTable'
import SensorListRow from '../../molecules/SensorListRow/SensorListRow'
import { HUMIDITY_LABEL, TEMPERATURE_LABEL } from '../../../common/constants/reports'

import { isEmpty } from 'lodash'

const DEFAULT_COLUMNS = [
  'deviceName',
  'location',
  'unit',
  'monitoringFor',
  'lastSeen',
  'temperature',
  'humidity',
  'co2',
  'pressure',
  'lightLevel',
  'tvocMgM3',
  'pm10',
  'pm25',
  'isMuted',
  'battery',
  'inUse',
  'hardwareId',
  'optionsMenu'
]

// key: option name
// value: an object containing anything needed for the option
const DEFAULT_MENU_OPTIONS = {
  viewSensor: null
}

const SensorsTable = ({
  building,
  unitId,
  valveId,
  userType,
  numRowsPerPage,
  displayColumns = DEFAULT_COLUMNS,
  menuOptions = DEFAULT_MENU_OPTIONS,
  emptyMessage,
  addPagination = true
}) => {
  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage, rowsPerPageOptions } =
    usePagination({ numRowsPerPage })

  const { loading, sensors, metadata, availableDataColumns, isOccupant } = useSensorsTable({
    buildingId: building?.id,
    unitId,
    valveId,
    page,
    setPage,
    rowsPerPage,
    userType
  })

  if (loading) return <React.Fragment></React.Fragment>
  return (
    <TableContainer>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            {displayColumns.includes('deviceName') && <StyledTableCell>Device Name</StyledTableCell>}
            {displayColumns.includes('location') && <StyledTableCell>Location</StyledTableCell>}
            {displayColumns.includes('unit') && <StyledTableCell>{unitLabel(building)}</StyledTableCell>}
            {displayColumns.includes('monitoringFor') && <StyledTableCell>Monitoring For</StyledTableCell>}
            {displayColumns.includes('lastSeen') && <StyledTableCell>Last Seen</StyledTableCell>}
            {displayColumns.includes('temperature') && availableDataColumns.temperature && (
              <StyledTableCell>{TEMPERATURE_LABEL}</StyledTableCell>
            )}
            {displayColumns.includes('humidity') && availableDataColumns.humidity && (
              <StyledTableCell>{HUMIDITY_LABEL}</StyledTableCell>
            )}
            {displayColumns.includes('co2') && availableDataColumns.co2 && (
              <StyledTableCell>
                CO<sub>2</sub> (ppm)
              </StyledTableCell>
            )}
            {displayColumns.includes('pressure') && availableDataColumns.pressure && (
              <StyledTableCell>Pressure (hPa)</StyledTableCell>
            )}
            {displayColumns.includes('lightLevel') && availableDataColumns.lightLevel && (
              <StyledTableCell>Light Level</StyledTableCell>
            )}
            {displayColumns.includes('tvocMgM3') && availableDataColumns.tvocMgM3 && (
              <StyledTableCell>
                TVOCs (mg/m<sup>3</sup>)
              </StyledTableCell>
            )}
            {displayColumns.includes('pm10') && availableDataColumns.pm10 && (
              <StyledTableCell>PM2.5</StyledTableCell>
            )}
            {displayColumns.includes('pm25') && availableDataColumns.pm25 && (
              <StyledTableCell>PM10</StyledTableCell>
            )}
            {displayColumns.includes('isMuted') && availableDataColumns.isMuted && (
              <StyledTableCell>Muted Until</StyledTableCell>
            )}
            {displayColumns.includes('battery') && <StyledTableCell>Battery </StyledTableCell>}
            {displayColumns.includes('inUse') && !isOccupant && <StyledTableCell>In Use?</StyledTableCell>}
            {displayColumns.includes('hardwareId') && !isOccupant && (
              <StyledTableCell>Hardware ID</StyledTableCell>
            )}
            {displayColumns.includes('optionsMenu') && <StyledTableCell />}
          </TableRow>
        </TableHead>
        <TableBody className='tight-table-row'>
          {isEmpty(sensors) && (
            <TableRow hover>
              <StyledTableCell align='center' sx={{ verticalAlign: 'top' }} colSpan={displayColumns.length}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography component={'h4'}>{emptyMessage ? emptyMessage : 'No Sensors Found'}</Typography>
                )}
              </StyledTableCell>
            </TableRow>
          )}
          {sensors.map((sensor) => (
            <Fragment key={sensor.id}>
              <SensorListRow
                sensor={sensor}
                availableDataColumns={availableDataColumns}
                isOccupant={isOccupant}
                displayColumns={displayColumns}
                menuOptions={menuOptions}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      {addPagination && (
        <TablePagination
          colSpan={4}
          rowsPerPageOptions={rowsPerPageOptions}
          component='div'
          count={metadata?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  )
}

export default SensorsTable
