import { useTheme, useMediaQuery } from '@mui/material'
export const sensorDisplay = (sensor) => {
  const sensor_unit_number = sensor.unit ? `- ${sensor.unit.number}` : ''
  const sensor_unit_floor = sensor.unit ? `(${sensor.unit.floor})` : ''
  return `${sensor.location}: ${sensor.building.name} ${sensor_unit_number} ${sensor_unit_floor}`
}
export const unitDisplay = (unit) => `${unit.building?.name} - ${unit.number} (${unit.floor})`

export const buildingDisplay = (building) => building.name

export const getOrdinal = (n) => {
  let ord = 'th'

  if (n % 10 === 1 && n % 100 !== 11) {
    ord = 'st'
  } else if (n % 10 === 2 && n % 100 !== 12) {
    ord = 'nd'
  } else if (n % 10 === 3 && n % 100 !== 13) {
    ord = 'rd'
  }

  return ord
}

// Check to see if we are on a mobile device
export const isMobile = () => useMediaQuery(useTheme().breakpoints.down('sm'))
