import React from 'react'
import { Box, Paper } from '@mui/material'

import CreateUserReportDialog from '../../organisms/UserReports/CreateUserReportDialog'
import UserReportsTable from '../../organisms/UserReports/UserReportsTable'

const UserReportsPage = () => {
  return (
    <Box sx={{ mt: 1 }}>
      <CreateUserReportDialog loading={false} />
      <Paper sx={{ mt: 2, p: 4 }}>
        <UserReportsTable numRowsPerPage={25} />
      </Paper>
    </Box>
  )
}

export default UserReportsPage
