import { useNavigate } from 'react-router-dom'

const useSensorListRow = () => {
  const navigate = useNavigate()

  const handleViewSensorMenuItemClick = (sensor) => {
    const sensorShowPath = `/sensors/${sensor.id}`
    navigate(sensorShowPath)
    // Using scrollTo for now to reset the page's scroll. We ideally should use <ScrollRestoration /> (first link) but that
    // requires picking a router (second link)
    // https://reactrouter.com/en/main/components/scroll-restoration
    // https://reactrouter.com/en/main/routers/picking-a-router
    window.scrollTo(0, 0)
  }

  return {
    handleViewSensorMenuItemClick
  }
}

export default useSensorListRow
