import { useQueryParam, StringParam } from 'use-query-params'
import { useGetBuildingQuery, useGetCurrentUserForGatewaysPageQuery } from './GraphQl/gql.generated'
import { get } from 'lodash'

const useGatewaysPage = () => {
  const [buildingId] = useQueryParam('building_id', StringParam)

  const { data: currentUserData } = useGetCurrentUserForGatewaysPageQuery()
  const currentUser = get(currentUserData, ['getCurrentUser'], {})

  const { data, loading } = useGetBuildingQuery({
    variables: { id: buildingId }
  })

  const building = data?.getBuilding
  return { building, loading, userType: get(currentUser, ['type']) }
}

export default useGatewaysPage
