import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchActiveSensorsWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
  sorting?: Types.InputMaybe<Types.SortingType>;
}>;


export type FetchActiveSensorsWidgetQuery = { __typename?: 'Query', widgetActiveSensors: { __typename?: 'BuildingCollection', collection: Array<{ __typename?: 'Building', id: string, name?: string | null, activeSensorsCount: number }>, metadata: { __typename?: 'CollectionMetadata', totalCount: number } } };


export const FetchActiveSensorsWidgetDocument = gql`
    query FetchActiveSensorsWidget($token: String!, $pagination: PaginationType, $sorting: SortingType) {
  widgetActiveSensors(token: $token, pagination: $pagination, sorting: $sorting) {
    collection {
      id
      name
      activeSensorsCount
    }
    metadata {
      totalCount
    }
  }
}
    `;

/**
 * __useFetchActiveSensorsWidgetQuery__
 *
 * To run a query within a React component, call `useFetchActiveSensorsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchActiveSensorsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchActiveSensorsWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchActiveSensorsWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchActiveSensorsWidgetQuery, FetchActiveSensorsWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchActiveSensorsWidgetQuery, FetchActiveSensorsWidgetQueryVariables>(FetchActiveSensorsWidgetDocument, options);
      }
export function useFetchActiveSensorsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchActiveSensorsWidgetQuery, FetchActiveSensorsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchActiveSensorsWidgetQuery, FetchActiveSensorsWidgetQueryVariables>(FetchActiveSensorsWidgetDocument, options);
        }
export type FetchActiveSensorsWidgetQueryHookResult = ReturnType<typeof useFetchActiveSensorsWidgetQuery>;
export type FetchActiveSensorsWidgetLazyQueryHookResult = ReturnType<typeof useFetchActiveSensorsWidgetLazyQuery>;
export type FetchActiveSensorsWidgetQueryResult = Apollo.QueryResult<FetchActiveSensorsWidgetQuery, FetchActiveSensorsWidgetQueryVariables>;