import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserAlertQueryVariables = Types.Exact<{
  shortcode: Types.Scalars['String'];
}>;


export type GetUserAlertQuery = { __typename?: 'Query', getUserAlert: { __typename?: 'UserAlert', userId: string, alert: { __typename?: 'Alert', id: string, alertType: Types.AlertType, severity?: Types.Severity | null, feedback?: string | null, acknowledgedUser?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, unit: { __typename?: 'Unit', building?: { __typename?: 'Building', name?: string | null, waterAlertMessage?: string | null, doorAlertMessage?: string | null } | null, users?: Array<{ __typename?: 'User', email: string, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null }> | null } } } };

export type UpdateAlertMutationVariables = Types.Exact<{
  input: Types.UpdateAlertInput;
}>;


export type UpdateAlertMutation = { __typename?: 'Mutation', updateAlert: { __typename?: 'Alert', id: string, severity?: Types.Severity | null, acknowledgedUserId?: string | null, feedback?: string | null } };


export const GetUserAlertDocument = gql`
    query GetUserAlert($shortcode: String!) {
  getUserAlert(shortcode: $shortcode) {
    alert {
      id
      alertType
      severity
      acknowledgedUser {
        firstName
        lastName
      }
      feedback
      unit {
        building {
          name
          waterAlertMessage
          doorAlertMessage
        }
        users {
          email
          firstName
          lastName
          phoneNumber
        }
      }
    }
    userId
  }
}
    `;

/**
 * __useGetUserAlertQuery__
 *
 * To run a query within a React component, call `useGetUserAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAlertQuery({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useGetUserAlertQuery(baseOptions: Apollo.QueryHookOptions<GetUserAlertQuery, GetUserAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAlertQuery, GetUserAlertQueryVariables>(GetUserAlertDocument, options);
      }
export function useGetUserAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAlertQuery, GetUserAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAlertQuery, GetUserAlertQueryVariables>(GetUserAlertDocument, options);
        }
export type GetUserAlertQueryHookResult = ReturnType<typeof useGetUserAlertQuery>;
export type GetUserAlertLazyQueryHookResult = ReturnType<typeof useGetUserAlertLazyQuery>;
export type GetUserAlertQueryResult = Apollo.QueryResult<GetUserAlertQuery, GetUserAlertQueryVariables>;
export const UpdateAlertDocument = gql`
    mutation UpdateAlert($input: UpdateAlertInput!) {
  updateAlert(input: $input) {
    id
    severity
    acknowledgedUserId
    feedback
  }
}
    `;
export type UpdateAlertMutationFn = Apollo.MutationFunction<UpdateAlertMutation, UpdateAlertMutationVariables>;

/**
 * __useUpdateAlertMutation__
 *
 * To run a mutation, you first call `useUpdateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertMutation, { data, loading, error }] = useUpdateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAlertMutation, UpdateAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAlertMutation, UpdateAlertMutationVariables>(UpdateAlertDocument, options);
      }
export type UpdateAlertMutationHookResult = ReturnType<typeof useUpdateAlertMutation>;
export type UpdateAlertMutationResult = Apollo.MutationResult<UpdateAlertMutation>;
export type UpdateAlertMutationOptions = Apollo.BaseMutationOptions<UpdateAlertMutation, UpdateAlertMutationVariables>;