import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDoorActivitiesWidgetQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.PaginationType>;
  sorting?: Types.InputMaybe<Types.SortingType>;
}>;


export type FetchDoorActivitiesWidgetQuery = { __typename?: 'Query', widgetDoorActivities: { __typename?: 'SensorEventCollection', collection: Array<{ __typename?: 'SensorEvent', id: string, eventTime: unknown, sensor: { __typename?: 'Sensor', id: string, location?: string | null, unit?: { __typename?: 'Unit', id: string, number?: string | null, floor: number } | null, building?: { __typename?: 'Building', name?: string | null } | null } }>, metadata: { __typename?: 'CollectionMetadata', totalCount: number } } };


export const FetchDoorActivitiesWidgetDocument = gql`
    query FetchDoorActivitiesWidget($token: String!, $pagination: PaginationType, $sorting: SortingType) {
  widgetDoorActivities(token: $token, pagination: $pagination, sorting: $sorting) {
    collection {
      id
      eventTime
      sensor {
        id
        location
        unit {
          id
          number
          floor
        }
        building {
          name
        }
      }
    }
    metadata {
      totalCount
    }
  }
}
    `;

/**
 * __useFetchDoorActivitiesWidgetQuery__
 *
 * To run a query within a React component, call `useFetchDoorActivitiesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDoorActivitiesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDoorActivitiesWidgetQuery({
 *   variables: {
 *      token: // value for 'token'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useFetchDoorActivitiesWidgetQuery(baseOptions: Apollo.QueryHookOptions<FetchDoorActivitiesWidgetQuery, FetchDoorActivitiesWidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDoorActivitiesWidgetQuery, FetchDoorActivitiesWidgetQueryVariables>(FetchDoorActivitiesWidgetDocument, options);
      }
export function useFetchDoorActivitiesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDoorActivitiesWidgetQuery, FetchDoorActivitiesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDoorActivitiesWidgetQuery, FetchDoorActivitiesWidgetQueryVariables>(FetchDoorActivitiesWidgetDocument, options);
        }
export type FetchDoorActivitiesWidgetQueryHookResult = ReturnType<typeof useFetchDoorActivitiesWidgetQuery>;
export type FetchDoorActivitiesWidgetLazyQueryHookResult = ReturnType<typeof useFetchDoorActivitiesWidgetLazyQuery>;
export type FetchDoorActivitiesWidgetQueryResult = Apollo.QueryResult<FetchDoorActivitiesWidgetQuery, FetchDoorActivitiesWidgetQueryVariables>;