import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { ReportModelFragmentDoc } from '../../ReportPage/GraphQl/gql.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserReportMutationVariables = Types.Exact<{
  input: Types.CreateUserReportInput;
}>;


export type CreateUserReportMutation = { __typename?: 'Mutation', createUserReport: { __typename?: 'UserReport', name: string, criteria?: unknown | null, reportInterval: Types.ReportInterval, startDate: unknown } };

export type UserReportModelFragment = { __typename?: 'UserReport', id: string, name: string, criteria?: unknown | null, reportInterval: Types.ReportInterval, startDate: unknown, active: boolean, user: { __typename?: 'User', companyId?: string | null }, reports?: Array<{ __typename?: 'Report', id: string, token: string, criteria?: unknown | null, selectedBuildings: Array<string>, selectedUnits: Array<string>, selectedSensors: Array<string>, sentTo?: Array<string> | null, createdAt: unknown, userReport: { __typename?: 'UserReport', name: string, user: { __typename?: 'User', companyId?: string | null } } }> | null };

export type UpdateUserReportMutationVariables = Types.Exact<{
  input: Types.UpdateUserReportInput;
}>;


export type UpdateUserReportMutation = { __typename?: 'Mutation', updateUserReport: { __typename?: 'UserReport', id: string, active: boolean } };

export type DeleteUserReportMutationVariables = Types.Exact<{
  input: Types.DeleteUserReportInput;
}>;


export type DeleteUserReportMutation = { __typename?: 'Mutation', deleteUserReport: { __typename?: 'UserReport', id: string } };

export const UserReportModelFragmentDoc = gql`
    fragment UserReportModel on UserReport {
  id
  user {
    companyId
  }
  name
  criteria
  reportInterval
  startDate
  active
  reports {
    ...ReportModel
  }
}
    ${ReportModelFragmentDoc}`;
export const CreateUserReportDocument = gql`
    mutation CreateUserReport($input: CreateUserReportInput!) {
  createUserReport(input: $input) {
    name
    criteria
    reportInterval
    startDate
  }
}
    `;
export type CreateUserReportMutationFn = Apollo.MutationFunction<CreateUserReportMutation, CreateUserReportMutationVariables>;

/**
 * __useCreateUserReportMutation__
 *
 * To run a mutation, you first call `useCreateUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserReportMutation, { data, loading, error }] = useCreateUserReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserReportMutation, CreateUserReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserReportMutation, CreateUserReportMutationVariables>(CreateUserReportDocument, options);
      }
export type CreateUserReportMutationHookResult = ReturnType<typeof useCreateUserReportMutation>;
export type CreateUserReportMutationResult = Apollo.MutationResult<CreateUserReportMutation>;
export type CreateUserReportMutationOptions = Apollo.BaseMutationOptions<CreateUserReportMutation, CreateUserReportMutationVariables>;
export const UpdateUserReportDocument = gql`
    mutation UpdateUserReport($input: UpdateUserReportInput!) {
  updateUserReport(input: $input) {
    id
    active
  }
}
    `;
export type UpdateUserReportMutationFn = Apollo.MutationFunction<UpdateUserReportMutation, UpdateUserReportMutationVariables>;

/**
 * __useUpdateUserReportMutation__
 *
 * To run a mutation, you first call `useUpdateUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserReportMutation, { data, loading, error }] = useUpdateUserReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserReportMutation, UpdateUserReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserReportMutation, UpdateUserReportMutationVariables>(UpdateUserReportDocument, options);
      }
export type UpdateUserReportMutationHookResult = ReturnType<typeof useUpdateUserReportMutation>;
export type UpdateUserReportMutationResult = Apollo.MutationResult<UpdateUserReportMutation>;
export type UpdateUserReportMutationOptions = Apollo.BaseMutationOptions<UpdateUserReportMutation, UpdateUserReportMutationVariables>;
export const DeleteUserReportDocument = gql`
    mutation DeleteUserReport($input: DeleteUserReportInput!) {
  deleteUserReport(input: $input) {
    id
  }
}
    `;
export type DeleteUserReportMutationFn = Apollo.MutationFunction<DeleteUserReportMutation, DeleteUserReportMutationVariables>;

/**
 * __useDeleteUserReportMutation__
 *
 * To run a mutation, you first call `useDeleteUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserReportMutation, { data, loading, error }] = useDeleteUserReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserReportMutation, DeleteUserReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserReportMutation, DeleteUserReportMutationVariables>(DeleteUserReportDocument, options);
      }
export type DeleteUserReportMutationHookResult = ReturnType<typeof useDeleteUserReportMutation>;
export type DeleteUserReportMutationResult = Apollo.MutationResult<DeleteUserReportMutation>;
export type DeleteUserReportMutationOptions = Apollo.BaseMutationOptions<DeleteUserReportMutation, DeleteUserReportMutationVariables>;