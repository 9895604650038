import React, { useState } from 'react'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { CircularProgress } from '@mui/material'
// src
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { useFetchExteriorTemperaturesWidgetQuery } from './gql.generated'

const columns: GridColDef[] = [
  {
    field: 'buildings.name',
    headerName: 'Building',
    width: 350,
    valueGetter: (params: GridValueGetterParams) => params.row.name || ''
  },
  {
    field: 'exterior_temperature',
    headerName: 'Temperature',
    width: 200,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      params.row.location.actualWeather.temperature
        ? Math.round(params.row.location.actualWeather.temperature) + '°C'
        : 'No Data'
  }
]

const initialField = 'buildings.name'
const initialSort = 'desc'

export default function ExteriorTemperatureBuildings({ token }) {
  const [page, setPage] = useState(0)
  const [field, setField] = useState(initialField)
  const [sort, setSort] = useState(initialSort)
  const [pageSize, setPageSize] = useState(100)

  const { data, loading, error } = useFetchExteriorTemperaturesWidgetQuery({
    // fetchPolicy: 'no-cache',
    variables: {
      token,
      pagination: {
        page: page + 1,
        per: pageSize
      },
      sorting: {
        field,
        sort
      }
    }
  })

  const rows = data?.widgetExteriorTemperatures?.collection || []
  const rowsCount = data?.widgetExteriorTemperatures?.metadata.totalCount || 0

  const handlePageChange = (page) => setPage(page)

  const handleChangeSort = (sortModel) => {
    const sortPair = sortModel[0]
    if (sortPair) {
      const { field, sort } = sortModel[0]
      setField(field)
      setSort(sort)
    } else {
      setSort(initialSort)
    }
  }

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      page={page}
      hideFooterSelectedRowCount
      pageSize={pageSize}
      rowCount={rowsCount}
      onSortModelChange={handleChangeSort}
      sortingMode='server'
      paginationMode='server'
      onPageChange={handlePageChange}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowHeight={30}
      headerHeight={30}
      pagination
      sx={{
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: ({ palette }) => palette.grey[200]
        }
      }}
    />
  )
}
