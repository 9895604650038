import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBuildingEmergencyResponseDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBuildingEmergencyResponseDetailsQuery = { __typename?: 'Query', getBuilding: { __typename?: 'Building', id: string, waterAlertMessage?: string | null } };


export const GetBuildingEmergencyResponseDetailsDocument = gql`
    query GetBuildingEmergencyResponseDetails($id: ID!) {
  getBuilding(id: $id) {
    id
    waterAlertMessage
  }
}
    `;

/**
 * __useGetBuildingEmergencyResponseDetailsQuery__
 *
 * To run a query within a React component, call `useGetBuildingEmergencyResponseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingEmergencyResponseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingEmergencyResponseDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingEmergencyResponseDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingEmergencyResponseDetailsQuery, GetBuildingEmergencyResponseDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingEmergencyResponseDetailsQuery, GetBuildingEmergencyResponseDetailsQueryVariables>(GetBuildingEmergencyResponseDetailsDocument, options);
      }
export function useGetBuildingEmergencyResponseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingEmergencyResponseDetailsQuery, GetBuildingEmergencyResponseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingEmergencyResponseDetailsQuery, GetBuildingEmergencyResponseDetailsQueryVariables>(GetBuildingEmergencyResponseDetailsDocument, options);
        }
export type GetBuildingEmergencyResponseDetailsQueryHookResult = ReturnType<typeof useGetBuildingEmergencyResponseDetailsQuery>;
export type GetBuildingEmergencyResponseDetailsLazyQueryHookResult = ReturnType<typeof useGetBuildingEmergencyResponseDetailsLazyQuery>;
export type GetBuildingEmergencyResponseDetailsQueryResult = Apollo.QueryResult<GetBuildingEmergencyResponseDetailsQuery, GetBuildingEmergencyResponseDetailsQueryVariables>;