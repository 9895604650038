import { first } from 'lodash'

export const colors = [
  '#365970',
  '#3f90c6',
  '#3fbac6',
  '#1c464a',
  '#05d0e3',
  '#5d3daa',
  '#56304d',
  '#0b1c32',
  '#4ef2e4',
  '#667a78'
]
export default function useRandomColour(key = 0) {
  return function (key) {
    return colors[key] || first(colors)
  }
}
