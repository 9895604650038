import React from 'react'
import { Menu } from '@mui/material'

import MenuDropdownIconButton from '../../atoms/MenuDropdownIconButton'
import useMenuDropdown from './useMenuDropdown'

const MenuDropdown = ({ children, closeMenuOnClick = true }) => {
  const { anchorEl, open, handleClick, handleClose } = useMenuDropdown()

  return (
    <>
      <MenuDropdownIconButton open={open} handleClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        id='menu'
        open={open}
        onClose={handleClose}
        onClick={closeMenuOnClick ? handleClose : null}
      >
        {children}
      </Menu>
    </>
  )
}

export default MenuDropdown
