import MouseOverPopover from '../../../molecules/MouseOverPopover'
import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { getWeekdayFromNumber } from '../../../../common/utils/dates'
import React from 'react'
import { StyledTableCell } from '../../../../common/theme/components/tables/StyledTableCell'

const DayBreakDownPopover = ({ baseText, weekdaysValues }) => (
  <MouseOverPopover baseText={baseText}>
    <Box sx={{ border: '2px solid #365970', pt: 1, textAlign: 'center' }}>
      Weekday Breakdown
      <TableContainer>
        <Table aria-label='table' sx={{ pl: -4 }}>
          <TableBody className='tight-table-row'>
            {weekdaysValues.weekday_count.map((weekdayRow) => (
              <TableRow key={weekdayRow.weekday + weekdayRow.door_open_count}>
                <StyledTableCell sx={{ border: 0 }}>
                  {getWeekdayFromNumber(weekdayRow.weekday)}
                </StyledTableCell>
                <TableCell sx={{ border: 0 }}>{weekdayRow.door_open_count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </MouseOverPopover>
)

export default DayBreakDownPopover
