import { uniq } from 'lodash'

import {
  ALERT_MONITOR_TYPE_CLEANING_REQUEST,
  ALERT_MONITOR_TYPE_DOOR_CLOSED,
  ALERT_MONITOR_TYPE_DOOR_OPEN,
  ALERT_MONITOR_TYPE_POWER,
  ALERT_MONITOR_TYPE_SUPERVISORY,
  ALERT_MONITOR_TYPE_TEMPERATURE,
  ALERT_MONITOR_TYPE_HUMIDITY,
  ALERT_MONITOR_TYPE_WATER,
  ALERT_MONITOR_TYPE_WATER_CLEARED,
  ALERT_MONITOR_TYPE_SOUND,
  EVENT_TYPE_CLEANING_REQUEST,
  EVENT_TYPE_DOOR_CLOSED,
  EVENT_TYPE_DOOR_OPEN,
  EVENT_TYPE_HIGH_TEMPERATURE,
  EVENT_TYPE_LOW_TEMPERATURE,
  EVENT_TYPE_HIGH_HUMIDITY,
  EVENT_TYPE_LOW_HUMIDITY,
  EVENT_TYPE_POWER,
  EVENT_TYPE_SOUND,
  EVENT_TYPE_SUPERVISORY,
  EVENT_TYPE_WATER
} from '../constants/alerts'

export const collectContactTypes = (userAlerts) => {
  const types = []
  userAlerts.forEach(({ sentSms, sentEmail, sentMobile, notSentOutsideHours }) => {
    if (sentSms) types.push('SMS')
    if (sentEmail) types.push('Email')
    if (sentMobile) types.push('Mobile')
    if (notSentOutsideHours && userAlerts.length < 2) types.push('Not sent (outside working hrs)')
  })
  return types.length ? uniq(types).join(', ') : 'Not sent (all users have alerts disabled for this type)'
}

export const lookupAlertIcon = (type) => {
  switch (type) {
    case EVENT_TYPE_WATER:
      return 'fa-droplet'
    case EVENT_TYPE_DOOR_OPEN:
      return 'fa-door-open'
    case EVENT_TYPE_LOW_TEMPERATURE:
      return 'fa-temperature-half'
    case EVENT_TYPE_HIGH_TEMPERATURE:
      return 'fa-temperature-half'
    case EVENT_TYPE_LOW_HUMIDITY:
      return 'fa-water'
    case EVENT_TYPE_HIGH_HUMIDITY:
      return 'fa-water'
    case EVENT_TYPE_POWER:
      return 'fa-plug-circle-exclamation'
    case EVENT_TYPE_CLEANING_REQUEST:
      return 'fa-soap'
    case EVENT_TYPE_SOUND:
      return 'fa-volume-high'
    default:
      return 'fa-exclamation'
  }
}

export const alertTypes = [
  {
    value: 'all',
    title: 'All Types'
  },
  {
    value: EVENT_TYPE_WATER,
    title: ALERT_MONITOR_TYPE_WATER
  },
  {
    value: EVENT_TYPE_DOOR_OPEN,
    title: ALERT_MONITOR_TYPE_DOOR_OPEN
  },
  {
    value: EVENT_TYPE_LOW_TEMPERATURE,
    title: 'Low ' + ALERT_MONITOR_TYPE_TEMPERATURE
  },
  {
    value: EVENT_TYPE_HIGH_TEMPERATURE,
    title: 'High ' + ALERT_MONITOR_TYPE_TEMPERATURE
  },
  {
    value: EVENT_TYPE_LOW_HUMIDITY,
    title: 'Low ' + ALERT_MONITOR_TYPE_HUMIDITY
  },
  {
    value: EVENT_TYPE_HIGH_HUMIDITY,
    title: 'High ' + ALERT_MONITOR_TYPE_HUMIDITY
  },
  {
    value: EVENT_TYPE_POWER,
    title: ALERT_MONITOR_TYPE_POWER
  },
  {
    value: EVENT_TYPE_SOUND,
    title: ALERT_MONITOR_TYPE_SOUND
  }
]
