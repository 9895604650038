import React from 'react'
import { CircularProgress } from '@mui/material'
import { Line } from 'react-chartjs-2'

// src
import useRandomColour from 'common/hooks/useRandomColour'
import { humanizeLineLabels } from '../../utils'
import WidgetErrorFallback from '../../WidgetErrorFallback'
import { WidgetNonData, nonData } from '../../WidgetNoData'
import { useFetchCleaningRequestLinesWidgetsQuery } from './gql.generated'

export default function CleaningRequestLines({ token }) {
  const randomColor = useRandomColour()

  const { data, loading, error } = useFetchCleaningRequestLinesWidgetsQuery({
    // fetchPolicy: 'no-cache',
    variables: { token }
  })

  const result = data?.widgetCleaningRequestLines ?? { labels: [], datasets: [] }

  if (loading) return <CircularProgress />
  if (error) return <WidgetErrorFallback />
  if (nonData(result.datasets)) return <WidgetNonData />

  return (
    <Line
      data={{
        labels: humanizeLineLabels(result.labels),
        datasets: result.datasets.map(({ label, data }, key) => ({
          label,
          data,
          borderColor: randomColor(key),
          tension: 0.1
        }))
      }}
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            labels: {
              boxWidth: 15,
              boxHeight: 15
            }
          }
        }
      }}
    />
  )
}
