import { format, fromUnixTime } from 'date-fns'
import { first, last, toInteger } from 'lodash'
import { LineLabel } from 'types.generated'

export const humanizeLineLabels = (labels: LineLabel[] | []) => {
  if (labels.length === 0) return []
  const labelFormat = isShortFormat(labels) ? 'dd MMM' : 'MMM dd h:mm a'
  return labels.map((label) => {
    return `${format(new Date(label.from * 1_000), labelFormat)} - ${format(
      new Date(label.to * 1_000),
      labelFormat
    )}`
  })
}

export const isShortFormat = (labels: LineLabel[]) => {
  if (labels.length === 0) return true
  const firstItem: LineLabel = first(labels)
  const lastItem: LineLabel = last(labels)

  return toInteger(lastItem.to) - toInteger(firstItem.from) > 604_800 // 1 week in seconds
}
