import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchAlertDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  eventFilter?: Types.InputMaybe<Types.Scalars['String']>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchAlertDataQuery = { __typename?: 'Query', fetchAlertData: unknown };

export type FetchIaqDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
  orderBy?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchIaqDataQuery = { __typename?: 'Query', fetchIaqData: unknown };

export type FetchIaqChartDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchIaqChartDataQuery = { __typename?: 'Query', fetchIaqChartData: unknown };

export type FetchIaqOutliersQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
  iaqAttribute: Types.Scalars['String'];
  lowHigh: Types.Scalars['String'];
}>;


export type FetchIaqOutliersQuery = { __typename?: 'Query', fetchIaqOutliers: unknown };

export type FetchOccupancyDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchOccupancyDataQuery = { __typename?: 'Query', fetchOccupancyData: unknown };

export type FetchPowerDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
  orderBy?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchPowerDataQuery = { __typename?: 'Query', fetchPowerData: unknown };

export type FetchPowerChartDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchPowerChartDataQuery = { __typename?: 'Query', fetchPowerChartData: unknown };

export type FetchPowerOutliersQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchPowerOutliersQuery = { __typename?: 'Query', fetchPowerOutliers: unknown };

export type FetchSoundDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
  orderBy?: Types.InputMaybe<Types.Scalars['String']>;
  order?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
}>;


export type FetchSoundDataQuery = { __typename?: 'Query', fetchSoundData: unknown };

export type FetchSoundChartDataQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchSoundChartDataQuery = { __typename?: 'Query', fetchSoundChartData: unknown };

export type FetchSoundOutliersQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.WidgetFiltersType>;
  reportToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchSoundOutliersQuery = { __typename?: 'Query', fetchSoundOutliers: unknown };


export const FetchAlertDataDocument = gql`
    query fetchAlertData($filters: WidgetFiltersType, $eventFilter: String, $reportToken: String) {
  fetchAlertData(
    filters: $filters
    eventFilter: $eventFilter
    reportToken: $reportToken
  )
}
    `;

/**
 * __useFetchAlertDataQuery__
 *
 * To run a query within a React component, call `useFetchAlertDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAlertDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAlertDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      eventFilter: // value for 'eventFilter'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchAlertDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchAlertDataQuery, FetchAlertDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAlertDataQuery, FetchAlertDataQueryVariables>(FetchAlertDataDocument, options);
      }
export function useFetchAlertDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAlertDataQuery, FetchAlertDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAlertDataQuery, FetchAlertDataQueryVariables>(FetchAlertDataDocument, options);
        }
export type FetchAlertDataQueryHookResult = ReturnType<typeof useFetchAlertDataQuery>;
export type FetchAlertDataLazyQueryHookResult = ReturnType<typeof useFetchAlertDataLazyQuery>;
export type FetchAlertDataQueryResult = Apollo.QueryResult<FetchAlertDataQuery, FetchAlertDataQueryVariables>;
export const FetchIaqDataDocument = gql`
    query fetchIaqData($filters: WidgetFiltersType, $reportToken: String, $orderBy: String, $order: String, $pagination: PaginationType) {
  fetchIaqData(
    filters: $filters
    reportToken: $reportToken
    orderBy: $orderBy
    order: $order
    pagination: $pagination
  )
}
    `;

/**
 * __useFetchIaqDataQuery__
 *
 * To run a query within a React component, call `useFetchIaqDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIaqDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIaqDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchIaqDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchIaqDataQuery, FetchIaqDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIaqDataQuery, FetchIaqDataQueryVariables>(FetchIaqDataDocument, options);
      }
export function useFetchIaqDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIaqDataQuery, FetchIaqDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIaqDataQuery, FetchIaqDataQueryVariables>(FetchIaqDataDocument, options);
        }
export type FetchIaqDataQueryHookResult = ReturnType<typeof useFetchIaqDataQuery>;
export type FetchIaqDataLazyQueryHookResult = ReturnType<typeof useFetchIaqDataLazyQuery>;
export type FetchIaqDataQueryResult = Apollo.QueryResult<FetchIaqDataQuery, FetchIaqDataQueryVariables>;
export const FetchIaqChartDataDocument = gql`
    query fetchIaqChartData($filters: WidgetFiltersType, $reportToken: String) {
  fetchIaqChartData(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchIaqChartDataQuery__
 *
 * To run a query within a React component, call `useFetchIaqChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIaqChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIaqChartDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchIaqChartDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>(FetchIaqChartDataDocument, options);
      }
export function useFetchIaqChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>(FetchIaqChartDataDocument, options);
        }
export type FetchIaqChartDataQueryHookResult = ReturnType<typeof useFetchIaqChartDataQuery>;
export type FetchIaqChartDataLazyQueryHookResult = ReturnType<typeof useFetchIaqChartDataLazyQuery>;
export type FetchIaqChartDataQueryResult = Apollo.QueryResult<FetchIaqChartDataQuery, FetchIaqChartDataQueryVariables>;
export const FetchIaqOutliersDocument = gql`
    query fetchIaqOutliers($filters: WidgetFiltersType, $reportToken: String, $iaqAttribute: String!, $lowHigh: String!) {
  fetchIaqOutliers(
    filters: $filters
    reportToken: $reportToken
    iaqAttribute: $iaqAttribute
    lowHigh: $lowHigh
  )
}
    `;

/**
 * __useFetchIaqOutliersQuery__
 *
 * To run a query within a React component, call `useFetchIaqOutliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIaqOutliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIaqOutliersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *      iaqAttribute: // value for 'iaqAttribute'
 *      lowHigh: // value for 'lowHigh'
 *   },
 * });
 */
export function useFetchIaqOutliersQuery(baseOptions: Apollo.QueryHookOptions<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>(FetchIaqOutliersDocument, options);
      }
export function useFetchIaqOutliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>(FetchIaqOutliersDocument, options);
        }
export type FetchIaqOutliersQueryHookResult = ReturnType<typeof useFetchIaqOutliersQuery>;
export type FetchIaqOutliersLazyQueryHookResult = ReturnType<typeof useFetchIaqOutliersLazyQuery>;
export type FetchIaqOutliersQueryResult = Apollo.QueryResult<FetchIaqOutliersQuery, FetchIaqOutliersQueryVariables>;
export const FetchOccupancyDataDocument = gql`
    query fetchOccupancyData($filters: WidgetFiltersType, $reportToken: String) {
  fetchOccupancyData(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchOccupancyDataQuery__
 *
 * To run a query within a React component, call `useFetchOccupancyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOccupancyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOccupancyDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchOccupancyDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>(FetchOccupancyDataDocument, options);
      }
export function useFetchOccupancyDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>(FetchOccupancyDataDocument, options);
        }
export type FetchOccupancyDataQueryHookResult = ReturnType<typeof useFetchOccupancyDataQuery>;
export type FetchOccupancyDataLazyQueryHookResult = ReturnType<typeof useFetchOccupancyDataLazyQuery>;
export type FetchOccupancyDataQueryResult = Apollo.QueryResult<FetchOccupancyDataQuery, FetchOccupancyDataQueryVariables>;
export const FetchPowerDataDocument = gql`
    query fetchPowerData($filters: WidgetFiltersType, $reportToken: String, $orderBy: String, $order: String, $pagination: PaginationType) {
  fetchPowerData(
    filters: $filters
    reportToken: $reportToken
    orderBy: $orderBy
    order: $order
    pagination: $pagination
  )
}
    `;

/**
 * __useFetchPowerDataQuery__
 *
 * To run a query within a React component, call `useFetchPowerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPowerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPowerDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchPowerDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchPowerDataQuery, FetchPowerDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPowerDataQuery, FetchPowerDataQueryVariables>(FetchPowerDataDocument, options);
      }
export function useFetchPowerDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPowerDataQuery, FetchPowerDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPowerDataQuery, FetchPowerDataQueryVariables>(FetchPowerDataDocument, options);
        }
export type FetchPowerDataQueryHookResult = ReturnType<typeof useFetchPowerDataQuery>;
export type FetchPowerDataLazyQueryHookResult = ReturnType<typeof useFetchPowerDataLazyQuery>;
export type FetchPowerDataQueryResult = Apollo.QueryResult<FetchPowerDataQuery, FetchPowerDataQueryVariables>;
export const FetchPowerChartDataDocument = gql`
    query fetchPowerChartData($filters: WidgetFiltersType, $reportToken: String) {
  fetchPowerChartData(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchPowerChartDataQuery__
 *
 * To run a query within a React component, call `useFetchPowerChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPowerChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPowerChartDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchPowerChartDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchPowerChartDataQuery, FetchPowerChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPowerChartDataQuery, FetchPowerChartDataQueryVariables>(FetchPowerChartDataDocument, options);
      }
export function useFetchPowerChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPowerChartDataQuery, FetchPowerChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPowerChartDataQuery, FetchPowerChartDataQueryVariables>(FetchPowerChartDataDocument, options);
        }
export type FetchPowerChartDataQueryHookResult = ReturnType<typeof useFetchPowerChartDataQuery>;
export type FetchPowerChartDataLazyQueryHookResult = ReturnType<typeof useFetchPowerChartDataLazyQuery>;
export type FetchPowerChartDataQueryResult = Apollo.QueryResult<FetchPowerChartDataQuery, FetchPowerChartDataQueryVariables>;
export const FetchPowerOutliersDocument = gql`
    query fetchPowerOutliers($filters: WidgetFiltersType, $reportToken: String) {
  fetchPowerOutliers(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchPowerOutliersQuery__
 *
 * To run a query within a React component, call `useFetchPowerOutliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPowerOutliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPowerOutliersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchPowerOutliersQuery(baseOptions?: Apollo.QueryHookOptions<FetchPowerOutliersQuery, FetchPowerOutliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchPowerOutliersQuery, FetchPowerOutliersQueryVariables>(FetchPowerOutliersDocument, options);
      }
export function useFetchPowerOutliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPowerOutliersQuery, FetchPowerOutliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchPowerOutliersQuery, FetchPowerOutliersQueryVariables>(FetchPowerOutliersDocument, options);
        }
export type FetchPowerOutliersQueryHookResult = ReturnType<typeof useFetchPowerOutliersQuery>;
export type FetchPowerOutliersLazyQueryHookResult = ReturnType<typeof useFetchPowerOutliersLazyQuery>;
export type FetchPowerOutliersQueryResult = Apollo.QueryResult<FetchPowerOutliersQuery, FetchPowerOutliersQueryVariables>;
export const FetchSoundDataDocument = gql`
    query fetchSoundData($filters: WidgetFiltersType, $reportToken: String, $orderBy: String, $order: String, $pagination: PaginationType) {
  fetchSoundData(
    filters: $filters
    reportToken: $reportToken
    orderBy: $orderBy
    order: $order
    pagination: $pagination
  )
}
    `;

/**
 * __useFetchSoundDataQuery__
 *
 * To run a query within a React component, call `useFetchSoundDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSoundDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSoundDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFetchSoundDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchSoundDataQuery, FetchSoundDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSoundDataQuery, FetchSoundDataQueryVariables>(FetchSoundDataDocument, options);
      }
export function useFetchSoundDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSoundDataQuery, FetchSoundDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSoundDataQuery, FetchSoundDataQueryVariables>(FetchSoundDataDocument, options);
        }
export type FetchSoundDataQueryHookResult = ReturnType<typeof useFetchSoundDataQuery>;
export type FetchSoundDataLazyQueryHookResult = ReturnType<typeof useFetchSoundDataLazyQuery>;
export type FetchSoundDataQueryResult = Apollo.QueryResult<FetchSoundDataQuery, FetchSoundDataQueryVariables>;
export const FetchSoundChartDataDocument = gql`
    query fetchSoundChartData($filters: WidgetFiltersType, $reportToken: String) {
  fetchSoundChartData(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchSoundChartDataQuery__
 *
 * To run a query within a React component, call `useFetchSoundChartDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSoundChartDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSoundChartDataQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchSoundChartDataQuery(baseOptions?: Apollo.QueryHookOptions<FetchSoundChartDataQuery, FetchSoundChartDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSoundChartDataQuery, FetchSoundChartDataQueryVariables>(FetchSoundChartDataDocument, options);
      }
export function useFetchSoundChartDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSoundChartDataQuery, FetchSoundChartDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSoundChartDataQuery, FetchSoundChartDataQueryVariables>(FetchSoundChartDataDocument, options);
        }
export type FetchSoundChartDataQueryHookResult = ReturnType<typeof useFetchSoundChartDataQuery>;
export type FetchSoundChartDataLazyQueryHookResult = ReturnType<typeof useFetchSoundChartDataLazyQuery>;
export type FetchSoundChartDataQueryResult = Apollo.QueryResult<FetchSoundChartDataQuery, FetchSoundChartDataQueryVariables>;
export const FetchSoundOutliersDocument = gql`
    query fetchSoundOutliers($filters: WidgetFiltersType, $reportToken: String) {
  fetchSoundOutliers(filters: $filters, reportToken: $reportToken)
}
    `;

/**
 * __useFetchSoundOutliersQuery__
 *
 * To run a query within a React component, call `useFetchSoundOutliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSoundOutliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSoundOutliersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      reportToken: // value for 'reportToken'
 *   },
 * });
 */
export function useFetchSoundOutliersQuery(baseOptions?: Apollo.QueryHookOptions<FetchSoundOutliersQuery, FetchSoundOutliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSoundOutliersQuery, FetchSoundOutliersQueryVariables>(FetchSoundOutliersDocument, options);
      }
export function useFetchSoundOutliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSoundOutliersQuery, FetchSoundOutliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSoundOutliersQuery, FetchSoundOutliersQueryVariables>(FetchSoundOutliersDocument, options);
        }
export type FetchSoundOutliersQueryHookResult = ReturnType<typeof useFetchSoundOutliersQuery>;
export type FetchSoundOutliersLazyQueryHookResult = ReturnType<typeof useFetchSoundOutliersLazyQuery>;
export type FetchSoundOutliersQueryResult = Apollo.QueryResult<FetchSoundOutliersQuery, FetchSoundOutliersQueryVariables>;