import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const FeedbackDialog = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby='feedback-dialog-title'
      aria-describedby='feedback-dialog-description'
    >
      <DialogTitle id='feedback-dialog-title'>Thanks for your feedback!</DialogTitle>
      <DialogContent>
        <DialogContentText id='feedback-dialog-description'>
          Your feedback is valuable to us.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackDialog
