import React from 'react'
import { Grid } from '@mui/material'

import useOccupantUnitInformationCard from './useOccupantUnitInformationCard'
import Card from '../../molecules/Card'

const OccupantUnitInformationCard = ({ occupantId, unitId }) => {
  const { buildingName, occupantUnitInformationMapping } = useOccupantUnitInformationCard({
    occupantId,
    unitId
  })

  return (
    <Card title={buildingName}>
      <Grid container sx={{ pl: '10%' }}>
        {occupantUnitInformationMapping.map((unitInformationMapping, index) => (
          <Grid item xs={12} md={6} key={index} style={{ paddingTop: '5px' }}>
            <label className='text-label'>{unitInformationMapping.label}:</label>
            <span className='text-value'>{unitInformationMapping.value}</span>
          </Grid>
        ))}
      </Grid>
    </Card>
  )
}

export default OccupantUnitInformationCard
