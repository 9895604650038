import { get } from 'lodash'

import { useFetchGatewaysTableQuery } from './GraphQl/gql.generated'
import { isTypeOccupant, isTypeSuperadmin, isTypePropertyAdmin } from '../../../common/utils/users'
import { useEffect, useState } from 'react'

const useGatewaysTable = ({ buildingId, page, setPage, rowsPerPage, userType }) => {
  const [query, setQuery] = useState(null)

  useEffect(() => {
    setPage(0) // Reset page number if userType changed
  }, [query])

  const isOccupant = isTypeOccupant(userType)
  const isSuperadmin = isTypeSuperadmin(userType)
  const isPropertyAdmin = isTypePropertyAdmin(userType)

  const { data, loading } = useFetchGatewaysTableQuery({
    variables: {
      buildingId,
      query,
      pagination: {
        page: page + 1, // TablePagination starts count pages from zero / 0
        per: rowsPerPage
      }
    }
  })

  const gateways = get(data, ['fetchGateways', 'collection'], [])
  const metadata = get(data, ['fetchGateways', 'metadata'], [])

  return {
    isOccupant,
    isSuperadmin,
    isPropertyAdmin,
    loading,
    gateways,
    metadata,
    query,
    setQuery
  }
}

export default useGatewaysTable
