import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentOccupantUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentOccupantUserQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', id: string, type: string, userBuildings?: Array<{ __typename?: 'UserBuilding', unitId?: number | null, building: { __typename?: 'Building', id: string, buildingType: string } }> | null } };


export const GetCurrentOccupantUserDocument = gql`
    query GetCurrentOccupantUser {
  getCurrentUser {
    id
    type
    userBuildings {
      unitId
      building {
        id
        buildingType
      }
    }
  }
}
    `;

/**
 * __useGetCurrentOccupantUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentOccupantUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentOccupantUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentOccupantUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentOccupantUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentOccupantUserQuery, GetCurrentOccupantUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentOccupantUserQuery, GetCurrentOccupantUserQueryVariables>(GetCurrentOccupantUserDocument, options);
      }
export function useGetCurrentOccupantUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentOccupantUserQuery, GetCurrentOccupantUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentOccupantUserQuery, GetCurrentOccupantUserQueryVariables>(GetCurrentOccupantUserDocument, options);
        }
export type GetCurrentOccupantUserQueryHookResult = ReturnType<typeof useGetCurrentOccupantUserQuery>;
export type GetCurrentOccupantUserLazyQueryHookResult = ReturnType<typeof useGetCurrentOccupantUserLazyQuery>;
export type GetCurrentOccupantUserQueryResult = Apollo.QueryResult<GetCurrentOccupantUserQuery, GetCurrentOccupantUserQueryVariables>;