import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBuildingQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetBuildingQuery = { __typename?: 'Query', getBuilding: { __typename?: 'Building', id: string, buildingType: string } };

export type FetchGatewaysQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']>;
  pagination?: Types.InputMaybe<Types.PaginationType>;
  buildingId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type FetchGatewaysQuery = { __typename?: 'Query', fetchGateways: { __typename?: 'GatewayCollection', metadata: { __typename?: 'CollectionMetadata', currentPage: number, limitValue: number, totalCount: number, totalPages: number }, collection: Array<{ __typename?: 'Gateway', id: string, make?: string | null, model?: string | null, location?: string | null, inUse: boolean, serialNumber?: string | null, hardwareId?: string | null }> } };

export type GetCurrentUserForGatewaysPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentUserForGatewaysPageQuery = { __typename?: 'Query', getCurrentUser: { __typename?: 'User', id: string, type: string } };


export const GetBuildingDocument = gql`
    query GetBuilding($id: ID!) {
  getBuilding(id: $id) {
    id
    buildingType
  }
}
    `;

/**
 * __useGetBuildingQuery__
 *
 * To run a query within a React component, call `useGetBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuildingQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingQuery, GetBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingQuery, GetBuildingQueryVariables>(GetBuildingDocument, options);
      }
export function useGetBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingQuery, GetBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingQuery, GetBuildingQueryVariables>(GetBuildingDocument, options);
        }
export type GetBuildingQueryHookResult = ReturnType<typeof useGetBuildingQuery>;
export type GetBuildingLazyQueryHookResult = ReturnType<typeof useGetBuildingLazyQuery>;
export type GetBuildingQueryResult = Apollo.QueryResult<GetBuildingQuery, GetBuildingQueryVariables>;
export const FetchGatewaysDocument = gql`
    query FetchGateways($query: String, $pagination: PaginationType, $buildingId: ID) {
  fetchGateways(query: $query, pagination: $pagination, buildingId: $buildingId) {
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    collection {
      id
      make
      model
      location
      inUse
      serialNumber
      hardwareId
    }
  }
}
    `;

/**
 * __useFetchGatewaysQuery__
 *
 * To run a query within a React component, call `useFetchGatewaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGatewaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGatewaysQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useFetchGatewaysQuery(baseOptions?: Apollo.QueryHookOptions<FetchGatewaysQuery, FetchGatewaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchGatewaysQuery, FetchGatewaysQueryVariables>(FetchGatewaysDocument, options);
      }
export function useFetchGatewaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchGatewaysQuery, FetchGatewaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchGatewaysQuery, FetchGatewaysQueryVariables>(FetchGatewaysDocument, options);
        }
export type FetchGatewaysQueryHookResult = ReturnType<typeof useFetchGatewaysQuery>;
export type FetchGatewaysLazyQueryHookResult = ReturnType<typeof useFetchGatewaysLazyQuery>;
export type FetchGatewaysQueryResult = Apollo.QueryResult<FetchGatewaysQuery, FetchGatewaysQueryVariables>;
export const GetCurrentUserForGatewaysPageDocument = gql`
    query GetCurrentUserForGatewaysPage {
  getCurrentUser {
    id
    type
  }
}
    `;

/**
 * __useGetCurrentUserForGatewaysPageQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserForGatewaysPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserForGatewaysPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserForGatewaysPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserForGatewaysPageQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserForGatewaysPageQuery, GetCurrentUserForGatewaysPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserForGatewaysPageQuery, GetCurrentUserForGatewaysPageQueryVariables>(GetCurrentUserForGatewaysPageDocument, options);
      }
export function useGetCurrentUserForGatewaysPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserForGatewaysPageQuery, GetCurrentUserForGatewaysPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserForGatewaysPageQuery, GetCurrentUserForGatewaysPageQueryVariables>(GetCurrentUserForGatewaysPageDocument, options);
        }
export type GetCurrentUserForGatewaysPageQueryHookResult = ReturnType<typeof useGetCurrentUserForGatewaysPageQuery>;
export type GetCurrentUserForGatewaysPageLazyQueryHookResult = ReturnType<typeof useGetCurrentUserForGatewaysPageLazyQuery>;
export type GetCurrentUserForGatewaysPageQueryResult = Apollo.QueryResult<GetCurrentUserForGatewaysPageQuery, GetCurrentUserForGatewaysPageQueryVariables>;