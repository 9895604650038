import React, { useState } from 'react'
import { Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, Box } from '@mui/material'
import { DashboardWidgetType } from 'types.generated'

import { useCreateDashboardWidgetMutation } from './gql.generated'
import WidgetCard from '../WidgetCard'
import { WidgetContentBuilder } from '../WidgetCard/widget-content-builder'

interface Props {
  token: string
  dashboardId: string
}

export default function AddWidgetDialog({ token, dashboardId }: Props) {
  const [open, setOpen] = useState(false)
  const [widgetType, setWidgetType] = useState(null)

  const [addWidget] = useCreateDashboardWidgetMutation()

  const selectWidget = (type) => () => {
    setWidgetType(type)
  }

  const createWidget = () => {
    addWidget({
      variables: {
        input: {
          type: widgetType,
          dashboardId
        }
      },
      refetchQueries: ['FetchDashboardWidgets']
    }).then(handleClose)
  }

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <>
      <Button variant='contained' size='small' onClick={handleClickOpen}>
        Add Widget
      </Button>
      <Dialog
        open={open}
        maxWidth='lg'
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>Select Type</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid
              item
              container
              xs={4}
              height={500}
              sx={({ palette }) => ({ borderRight: `1px solid ${palette.grey[400]}` })}
              style={{ overflowY: 'scroll' }}
            >
              {Object.values(DashboardWidgetType).map((type) => {
                const builder = WidgetContentBuilder[type]
                return (
                  <Grid item xs={12} key={type}>
                    <Button variant={widgetType === type ? 'contained' : 'text'} onClick={selectWidget(type)}>
                      {builder.title}
                    </Button>
                  </Grid>
                )
              })}
            </Grid>
            <Grid item xs={8} width={600} pl={2}>
              {widgetType && (
                <WidgetCard isPublic={false} token={token} widget={{ type: widgetType, id: null }} />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Close
          </Button>
          <Button variant='contained' onClick={createWidget}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
