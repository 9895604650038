import React from 'react'
import { DashboardWidgetType } from 'types.generated'
import {
  Chalet as ChaletIcon,
  TextRotateVertical as TextRotateVerticalIcon,
  MeetingRoom as MeetingRoomIcon,
  CleaningServices as CleaningServicesIcon,
  Sensors as SensorsIcon,
  Water as WaterIcon,
  Thermostat as ThermostatIcon,
  Thunderstorm as ThunderstormIcon,
  Co2 as Co2Icon,
  PowerInput as PowerInputIcon
} from '@mui/icons-material'

import DoorActivities from './types/DoorActivities'
import CleaningRequests from './types/CleaningRequests'
import Co2ActivityLines from './types/Co2ActivityLines'
import TemperatureActivities from './types/TemperatureAcivities'
import PowerStatus from './types/PowerStatus'
import WaterAlerts from './types/WaterAlerts'
import ExteriorTemperatureBuildings from './types/ExteriorTemperatures'
import NumberActiveSensors from './types/ActiveSensors'
import DoorActivityBars from './types/DoorActivityBars'
import HumidityActivityLines from './types/HumidityActivityLines'
import PressureActivityLines from './types/PressureActivityLines'
import Co2ActivityPolars from './types/Co2ActivitiesPolars'
import TemperaturePolars from './types/TemperaturePolars'
import CleaningRequestPolars from './types/CleaningRequestPolars'
import CleaningRequestLines from './types/CleaningRequestLines'
import DoorActivityPolars from './types/DoorActivityPolars'
import AverageCo2Gauges from './types/AverageCo2Gauges'
import AveragePressureGauges from './types/AveragePressureGauges'
import AverageHumidityGauges from './types/AverageHumidityGauges'
import AverageTemperatureGauges from './types/AverageTemperatureGauges'

export const WidgetContentBuilder = {
  [DashboardWidgetType.DoorActivities]: {
    title: 'Door Activity List',
    icon: MeetingRoomIcon,
    content: DoorActivities
  },
  [DashboardWidgetType.CleaningRequests]: {
    title: 'Cleaning Request List',
    icon: CleaningServicesIcon,
    content: CleaningRequests
  },
  [DashboardWidgetType.Co2ActivityLines]: {
    title: (
      <>
        CO<sub>2</sub>&nbsp;Trends
      </>
    ),
    icon: Co2Icon,
    content: Co2ActivityLines
  },
  [DashboardWidgetType.TemperatureActivities]: {
    title: 'Temperature Trends',
    icon: ThermostatIcon,
    content: TemperatureActivities
  },
  [DashboardWidgetType.PowerStatuses]: {
    title: 'Power Statuses',
    icon: PowerInputIcon,
    content: PowerStatus
  },
  [DashboardWidgetType.WaterAlerts]: {
    title: 'Water Alerts',
    icon: WaterIcon,
    content: WaterAlerts
  },
  [DashboardWidgetType.ExteriorTemperatures]: {
    title: 'Current Exterior Temperatures',
    icon: ChaletIcon,
    content: ExteriorTemperatureBuildings
  },
  [DashboardWidgetType.ActiveSensors]: {
    title: 'Active Sensors',
    icon: SensorsIcon,
    content: NumberActiveSensors
  },
  [DashboardWidgetType.DoorActivityBars]: {
    title: 'Door Activity Trends',
    icon: SensorsIcon,
    content: DoorActivityBars
  },
  [DashboardWidgetType.HumidityActivityLines]: {
    title: 'Humidity Trends',
    icon: ThunderstormIcon,
    content: HumidityActivityLines
  },
  [DashboardWidgetType.PressureActivityLines]: {
    title: 'Pressure Trends',
    icon: TextRotateVerticalIcon,
    content: PressureActivityLines
  },
  [DashboardWidgetType.Co2ActivityPolars]: {
    title: (
      <>
        CO<sub>2</sub>&nbsp;Polar
      </>
    ),
    icon: Co2Icon,
    content: Co2ActivityPolars
  },
  [DashboardWidgetType.TemperaturePolars]: {
    title: 'Temperature Polar',
    icon: ThermostatIcon,
    content: TemperaturePolars
  },
  [DashboardWidgetType.CleaningRequestPolars]: {
    title: 'Cleaning Request Polar',
    icon: CleaningServicesIcon,
    content: CleaningRequestPolars
  },
  [DashboardWidgetType.CleaningRequestLines]: {
    title: 'Cleaning Request Trends',
    icon: CleaningServicesIcon,
    content: CleaningRequestLines
  },
  [DashboardWidgetType.DoorActivityPolars]: {
    title: 'Door Activity Polar',
    icon: SensorsIcon,
    content: DoorActivityPolars
  },
  [DashboardWidgetType.AverageCo2Gauges]: {
    title: (
      <>
        Average&nbsp;CO<sub>2</sub>
      </>
    ),
    icon: Co2Icon,
    content: AverageCo2Gauges
  },
  [DashboardWidgetType.AveragePressureGauges]: {
    title: 'Average Pressure',
    icon: TextRotateVerticalIcon,
    content: AveragePressureGauges
  },
  [DashboardWidgetType.AverageHumidityGauges]: {
    title: 'Average Humidity',
    icon: ThunderstormIcon,
    content: AverageHumidityGauges
  },
  [DashboardWidgetType.AverageTemperatureGauges]: {
    title: 'Average Temperature',
    icon: ThermostatIcon,
    content: AverageTemperatureGauges
  }
}
