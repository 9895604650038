import { get } from 'lodash'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useUpdateGatewayMutation } from '../GraphQl/gql.generated'
import { useCreateGatewayMutation } from '../GraphQl/gql.generated'
import { useFetchProjectListQuery } from '../GraphQl/gql.generated'
import { isTypeSuperadmin, isTypePropertyAdmin } from '../../../../common/utils/users'

const schema = yup
  .object({
    make: yup.string().required('Name is required'),
    model: yup.string().required('Model is required'),
    location: yup.string().required('Location is required'),
    serialNumber: yup.string().nullable(),
    hardwareId: yup.string().required('Hardware ID is required'),
    buildingId: yup.string(),
    projectId: yup.string().nullable(),
    inUse: yup.boolean().default(true)
  })
  .required()

const useCreateEditGateway = ({ gateway, buildingId, context, userType }) => {
  const [localGateway, setLocalGateway] = useState(gateway)

  useEffect(() => {
    setLocalGateway(gateway)
  }, [gateway])

  const [open, setOpen] = useState(false)
  const { data, loading } = useFetchProjectListQuery({
    variables: {
      buildingId: context === 'create' ? buildingId : localGateway.buildingId
    }
  })

  const defaultValues =
    context === 'edit'
      ? {
          make: localGateway.make,
          model: localGateway.model,
          location: localGateway.location,
          serialNumber: localGateway.serialNumber,
          hardwareId: localGateway.hardwareId,
          buildingId: localGateway.buildingId,
          inUse: localGateway.inUse,
          projectId: localGateway.projectId
        }
      : {
          serialNumber: null,
          projectId: null,
          buildingId: buildingId,
          inUse: true
        }

  const projects = get(data, ['fetchProjects', 'collection'], [])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues
  })

  const [editGateway] = useUpdateGatewayMutation()
  const [createGateway] = useCreateGatewayMutation()
  const onSubmit = (input) => {
    if (context === 'edit') {
      editGateway({
        variables: {
          input: {
            ...input,
            id: localGateway.id
          }
        },
        refetchQueries: ['FetchGatewaysTable'],
        onCompleted: (data) => {
          const updatedGateway = data.updateGateway
          setLocalGateway(updatedGateway)
          reset({
            make: updatedGateway.make,
            model: updatedGateway.model,
            location: updatedGateway.location,
            serialNumber: updatedGateway.serialNumber,
            hardwareId: updatedGateway.hardwareId,
            buildingId: updatedGateway.buildingId,
            inUse: updatedGateway.inUse,
            projectId: updatedGateway.projectId
          })
          setOpen(false)
        }
      })
    } else {
      createGateway({
        variables: {
          input
        },
        refetchQueries: ['FetchGatewaysTable']
      }).then(() => {
        handleClose()
      })
      setOpen(false)
      reset()
    }
  }

  const resetValues =
    context === 'edit'
      ? {
          make: localGateway.make,
          model: localGateway.model,
          location: localGateway.location,
          serialNumber: localGateway.serialNumber,
          hardwareId: localGateway.hardwareId,
          buildingId: localGateway.buildingId,
          inUse: localGateway.inUse,
          projectId: localGateway.projectId
        }
      : {
          make: null,
          model: null,
          location: null,
          serialNumber: null,
          hardwareId: null,
          projectId: null,
          buildingId: buildingId,
          inUse: true
        }

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    reset(resetValues)
  }

  const isSuperadmin = isTypeSuperadmin(userType)
  const isPropertyAdmin = isTypePropertyAdmin(userType)

  return {
    isSuperadmin,
    isPropertyAdmin,
    control,
    handleSubmit,
    errors,
    projects,
    loading,
    open,
    onSubmit,
    handleClickOpen,
    handleClose
  }
}

export default useCreateEditGateway
