import { useState } from 'react'
import { useDeleteGatewayMutation } from '../GraphQl/gql.generated'

const useDeleteGateway = ({ gateway }) => {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null) // Add error state

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [deleteGateway] = useDeleteGatewayMutation()
  const handleDelete = async () => {
    try {
      await deleteGateway({
        variables: {
          input: {
            id: gateway.id
          }
        },
        refetchQueries: ['FetchGatewaysTable']
      })
      setOpen(false)
    } catch (error) {
      setError(error)
    }
  }

  return { open, handleClickOpen, handleClose, handleDelete, error }
}

export default useDeleteGateway
