import React, { useEffect, useState } from 'react'
import { subDays } from 'date-fns'
import {
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  FormControl,
  MenuItem,
  InputLabel,
  Radio,
  Select,
  // Stack,
  LinearProgress
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { includes } from 'lodash'

// src
import { useUpdateDashboardFiltersMutation } from './gql.generated'

import { timeUnits } from './constants'
import { useApolloClient } from '@apollo/client'
import BuildingFilters from './BuildingFilters'

interface Props {
  dashboard: any
}

const initialFiltersState = {
  buildingIds: [],
  unitIds: [],
  sensorIds: [],
  dateFilterType: 'date_range',
  timeValue: 7,
  timeUnit: 'days',
  startDate: subDays(new Date(), 7),
  endDate: new Date()
}

const OBSERVABLE_QUERIES = [
  'FetchActiveSensorsWidget',
  'FetchCleaningRequestPolarsWidgets',
  'FetchCleaningRequestLinesWidgets',
  'FetchCleaningRequestsWidget',
  'FetchCo2ActivityLinesWidget',
  'FetchCo2ActivityPolarsWidgets',
  'FetchDoorActivitiesWidget',
  'FetchDoorActivityBarsWidget',
  'FetchExteriorTemperaturesWidget',
  'FetchHumidityActivityLinesWidget',
  'FetchPowerStatusesWidget',
  'FetchPressureActivityLinesWidget',
  'FetchTemperatureActivitiesWidget',
  'FetchTemperaturePolarsWidgets',
  'FetchAverageCo2GaugesWidget',
  'FetchAverageTemperatureGaugesWidget',
  'FetchAveragePressureGaugesWidget',
  'FetchAverageHumidityGaugesWidget',
  'FetchWaterAlertsWidget'
]

export default function DashboardFiltersPanel({ dashboard }: Props) {
  const { filters: dashboardFilters, companyId } = dashboard
  const [filters, setFilters] = useState(() => dashboardFilters || initialFiltersState)

  const client = useApolloClient()
  const [saveFilters, { loading }] = useUpdateDashboardFiltersMutation()

  const getAllWidgetQueries = () => {
    const queryNames = []
    // @ts-ignore
    client.queryManager.queries.forEach(({ observableQuery }) => {
      if (includes(OBSERVABLE_QUERIES, observableQuery.queryName)) {
        queryNames.push(observableQuery.queryName)
      }
    })
    return queryNames
  }

  const handleFilterChange = ({ filter, value }) => {
    const refetchQueries = getAllWidgetQueries()
    setFilters((filters) => {
      const updatedFilters = { ...filters, [filter]: value }
      saveFilters({
        variables: {
          input: {
            id: dashboard.id,
            filters: updatedFilters
          }
        },
        refetchQueries,
        awaitRefetchQueries: true
      })
      return updatedFilters
    })
  }

  const handleStartDate = (value) => {
    handleFilterChange({ filter: 'startDate', value })
  }
  const handleEndDate = (value) => handleFilterChange({ filter: 'endDate', value })

  return (
    <Grid container direction='column' mt={2}>
      <Grid
        item
        container
        spacing={1}
        justifyContent='space-evenly'
        direction={{ xs: 'column', lg: 'row' }}
        alignItems='center'
      >
        <Grid item container justifyContent='center' xs={2}>
          Asset Filters
        </Grid>
        <BuildingFilters
          handleFilterChange={handleFilterChange}
          companyId={companyId}
          filters={filters}
          loading={loading}
        />
      </Grid>
      <Grid
        item
        container
        mt={1}
        justifyContent='space-evenly'
        direction={{ xs: 'column', lg: 'row' }}
        alignItems='center'
        spacing={1}
      >
        <Grid item container xs={2} justifyContent='center'>
          Date & Time Filters
        </Grid>

        <Grid item xs={3} style={{ minWidth: '270px' }}>
          <RadioGroup
            row
            value={filters.dateFilterType}
            onChange={(event) => {
              const { value } = event.target
              handleFilterChange({ filter: 'dateFilterType', value })
            }}
          >
            <FormControlLabel
              disabled={loading}
              control={<Radio size='small' />}
              value='since_date'
              label='Events Since'
            />
            <FormControlLabel
              disabled={loading}
              control={<Radio size='small' />}
              value='date_range'
              label='Date Range'
            />
          </RadioGroup>
        </Grid>
        {filters.dateFilterType === 'since_date' && (
          <>
            <Grid item xs={3} style={{ minWidth: '270px' }}>
              <FormControl fullWidth>
                <InputLabel id='since-label'>Time Unit</InputLabel>
                <Select
                  disabled={loading}
                  size='small'
                  labelId='since-label'
                  value={`${filters.timeValue}_${filters.timeUnit}`}
                  label='Time Unit'
                  onChange={(event) => {
                    handleFilterChange({ filter: 'timeValue', value: event.target.value.split('_')[0] })
                    handleFilterChange({ filter: 'timeUnit', value: event.target.value.split('_')[1] })
                  }}
                >
                  {timeUnits.map(({ value, title }, key) => (
                    <MenuItem value={value} key={key}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} />
          </>
        )}

        {filters.dateFilterType === 'date_range' && (
          <>
            <Grid item xs={3} style={{ minWidth: '270px' }}>
              <FormControl fullWidth>
                <DesktopDatePicker
                  disabled={loading}
                  label='Start'
                  inputFormat='MM/dd/yyyy'
                  value={filters.startDate}
                  onChange={handleStartDate}
                  renderInput={(params) => <TextField size='small' {...params} />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} style={{ minWidth: '270px' }}>
              <FormControl fullWidth>
                <DesktopDatePicker
                  disabled={loading}
                  label='End'
                  inputFormat='MM/dd/yyyy'
                  value={filters.endDate}
                  onChange={handleEndDate}
                  renderInput={(params) => <TextField size='small' {...params} />}
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      {loading && <LinearProgress sx={{ mt: 1 }} />}
    </Grid>
  )
}
