import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetReportQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type GetReportQuery = { __typename?: 'Query', getReport: { __typename?: 'Report', id: string, token: string, criteria?: unknown | null, selectedBuildings: Array<string>, selectedUnits: Array<string>, selectedSensors: Array<string>, sentTo?: Array<string> | null, createdAt: unknown, userReport: { __typename?: 'UserReport', name: string, user: { __typename?: 'User', companyId?: string | null } } } };

export type ReportModelFragment = { __typename?: 'Report', id: string, token: string, criteria?: unknown | null, selectedBuildings: Array<string>, selectedUnits: Array<string>, selectedSensors: Array<string>, sentTo?: Array<string> | null, createdAt: unknown, userReport: { __typename?: 'UserReport', name: string, user: { __typename?: 'User', companyId?: string | null } } };

export const ReportModelFragmentDoc = gql`
    fragment ReportModel on Report {
  id
  userReport {
    name
    user {
      companyId
    }
  }
  token
  criteria
  selectedBuildings
  selectedUnits
  selectedSensors
  sentTo
  createdAt
}
    `;
export const GetReportDocument = gql`
    query GetReport($token: String!) {
  getReport(token: $token) {
    ...ReportModel
  }
}
    ${ReportModelFragmentDoc}`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;