import * as Types from '../../../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchCleaningRequestPolarsWidgetsQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchCleaningRequestPolarsWidgetsQuery = { __typename?: 'Query', widgetCleaningRequestPolars?: { __typename?: 'Bar', datasets: Array<{ __typename?: 'BarDataset', label: string, data: unknown }> } | null };


export const FetchCleaningRequestPolarsWidgetsDocument = gql`
    query FetchCleaningRequestPolarsWidgets($token: String!) {
  widgetCleaningRequestPolars(token: $token) {
    datasets {
      label
      data
    }
  }
}
    `;

/**
 * __useFetchCleaningRequestPolarsWidgetsQuery__
 *
 * To run a query within a React component, call `useFetchCleaningRequestPolarsWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCleaningRequestPolarsWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCleaningRequestPolarsWidgetsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchCleaningRequestPolarsWidgetsQuery(baseOptions: Apollo.QueryHookOptions<FetchCleaningRequestPolarsWidgetsQuery, FetchCleaningRequestPolarsWidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCleaningRequestPolarsWidgetsQuery, FetchCleaningRequestPolarsWidgetsQueryVariables>(FetchCleaningRequestPolarsWidgetsDocument, options);
      }
export function useFetchCleaningRequestPolarsWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCleaningRequestPolarsWidgetsQuery, FetchCleaningRequestPolarsWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCleaningRequestPolarsWidgetsQuery, FetchCleaningRequestPolarsWidgetsQueryVariables>(FetchCleaningRequestPolarsWidgetsDocument, options);
        }
export type FetchCleaningRequestPolarsWidgetsQueryHookResult = ReturnType<typeof useFetchCleaningRequestPolarsWidgetsQuery>;
export type FetchCleaningRequestPolarsWidgetsLazyQueryHookResult = ReturnType<typeof useFetchCleaningRequestPolarsWidgetsLazyQuery>;
export type FetchCleaningRequestPolarsWidgetsQueryResult = Apollo.QueryResult<FetchCleaningRequestPolarsWidgetsQuery, FetchCleaningRequestPolarsWidgetsQueryVariables>;