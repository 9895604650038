import React from 'react'
import { Box, Typography, Link, Grid } from '@mui/material'
// src
import ClipboardCopy from 'components/molecules/ClipboardCopy'
import QrCodeDialog from 'components/molecules/QrCodeDialog'
import { getDashboardUrl } from 'common/utils/dashboards'

export default function DashboardInfo({ dashboard, isPublic = false }) {
  const link = getDashboardUrl(dashboard.token)

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid item container xs={7}>
          <Typography variant='h6'>Dashboard: {dashboard.name}</Typography>
        </Grid>
        {!isPublic && (
          <Grid item container xs={5} alignItems='center' justifyContent='flex-end'>
            Public Link: &nbsp;
            <Grid
              item
              sx={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Link href={link} target='_blank'>
                {link}
              </Link>
            </Grid>
            <ClipboardCopy text={link} />
            <Grid item>
              QR Code:
              <QrCodeDialog link={link} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}
