import { createTheme } from '@mui/material'

export const disabledFormControlLabelFontColor = 'rgba(0, 0, 0, 0.75)' // default MUI disabled color is rgba(0, 0, 0, 0.38)

export const MuiFormControlLabelTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: disabledFormControlLabelFontColor
          }
        }
      }
    }
  }
})
