import {
  useFetchBuildingsLazyQuery,
  useFetchSensorsCollectionLazyQuery,
  useFetchUnitsLazyQuery
} from './gql.generated'
import React, { useEffect } from 'react'
import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material'
import { sensorDisplay, unitDisplay, buildingDisplay } from '../../../../../common/utils/display'

const BuildingFilters = ({
  companyId,
  handleFilterChange,
  filters,
  loading,
  disabled = false,
  columnDisplay = false
}) => {
  const handleBuildingsSelection = (event: any, buildings: any[]) => {
    handleFilterChange({
      filter: 'buildingIds',
      value: buildings.map((item) => item.id)
    })
    handleFilterChange({ filter: 'unitIds', value: [] })
    handleFilterChange({
      filter: 'sensorIds',
      value: [],
      updateCriteriaDisplay: { buildings, units: [], sensors: [] }
    })
  }
  const handleUnitsSelection = (event: any, units: any[]) => {
    handleFilterChange({
      filter: 'unitIds',
      value: units.map((item) => item.id)
    })
    handleFilterChange({ filter: 'sensorIds', value: [], updateCriteriaDisplay: { units, sensors: [] } })
  }
  const handleSensorsSelection = (event: any, sensors: any[]) => {
    handleFilterChange({
      filter: 'sensorIds',
      value: sensors.map((item) => item.id),
      updateCriteriaDisplay: { sensors: sensors }
    })
  }

  const buildingIds = filters.buildingIds || []
  const unitIds = filters.unitIds || []
  const sensorIds = filters.sensorIds || []

  const [getBuildings, { data: buildingsData, loading: buildingsLoading }] = useFetchBuildingsLazyQuery({
    variables: {
      companyId,
      pagination: {
        per: 300
      }
    }
  })

  const [getUnits, { data: unitsData, loading: unitsLoading }] = useFetchUnitsLazyQuery({
    variables: {
      companyId,
      buildingIds,
      pagination: {
        per: 300
      }
    }
  })

  const [getSensors, { data: sensorsData, loading: sensorsLoading }] = useFetchSensorsCollectionLazyQuery({
    variables: {
      companyId,
      buildingIds,
      unitIds,
      pagination: {
        per: 500
      }
    }
  })

  useEffect(() => {
    //   // Fetch buildings, units and sensors initially
    if (buildingIds.length) getBuildings()
    if (unitIds.length) getUnits()
    if (sensorIds.length) getSensors()
  }, [])

  const buildings = buildingsData?.fetchBuildings || []
  const units = unitsData?.fetchUnits || []
  const sensors = sensorsData?.fetchSensorsCollection?.collection || []

  const selectedBuildings = buildings.filter((building) => buildingIds.includes(building.id))

  const selectedUnits = units.filter((unit) => unitIds.includes(unit.id))
  const selectedSensors = sensors.filter((sensor) => sensorIds.includes(sensor.id))

  return (
    <>
      <Grid item xs={3} style={{ minWidth: '270px' }}>
        <Autocomplete
          disabled={loading || disabled}
          multiple
          // autoComplete
          limitTags={columnDisplay ? 5 : 1}
          size='small'
          value={selectedBuildings}
          onChange={handleBuildingsSelection}
          options={buildings}
          // onInputChange={(event, query) => getBuildings({ variables: { query } })}
          onFocus={() => getBuildings()}
          getOptionLabel={(option) => buildingDisplay(option)}
          loading={buildingsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={buildingIds.length ? 'Buildings' : ''}
              placeholder={buildingIds.length ? '' : 'All Buildings'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {buildingsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} style={{ minWidth: '270px' }}>
        <Autocomplete
          disabled={loading || disabled}
          multiple
          // autoComplete
          limitTags={columnDisplay ? 5 : 1}
          size='small'
          value={selectedUnits}
          onChange={handleUnitsSelection}
          options={units}
          // onInputChange={(event, query) => getUnits({ variables: { query } })}
          onFocus={() => getUnits()}
          getOptionLabel={(option) => unitDisplay(option)}
          loading={unitsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={unitIds.length ? 'Zones' : ''}
              placeholder={unitIds.length ? '' : 'All Zones'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {unitsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={3} style={{ minWidth: '270px' }}>
        <Autocomplete
          disabled={loading || disabled}
          multiple
          // autoComplete
          limitTags={columnDisplay ? 5 : 1}
          size='small'
          value={selectedSensors}
          onChange={handleSensorsSelection}
          options={sensors}
          // onInputChange={(event, query) => getSensors({ variables: { query } })}
          onFocus={() => getSensors()}
          getOptionLabel={(option) => sensorDisplay(option)}
          loading={sensorsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={sensorIds.length ? 'Sensors' : ''}
              placeholder={sensorIds.length ? '' : 'All Sensors'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {sensorsLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Grid>
    </>
  )
}

export default BuildingFilters
